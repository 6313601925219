import { useState } from "react"

type AppPaginationParams = {
	page?: number
	rowsCount?: number
}

const useAppPagination = ({
	page = 1,
	rowsCount = 10,
}: AppPaginationParams | undefined = {}) => {
	const [pageRowsCount, setPageRowsCount] = useState(rowsCount)
	const [currentPage, setCurrentPage] = useState(page)

	const handlePageCountChange = (count: number) => {
		setPageRowsCount(count)
	}

	const handlePageChange = (page: number) => {
		setCurrentPage(page)
	}

	return { pageRowsCount, currentPage, handlePageCountChange, handlePageChange }
}

export default useAppPagination
