import { Typography } from "@mui/material"
import { useTranslation } from "react-i18next"
import { loginBanner } from "../../assets/images"
import { Svgs } from "../../assets/svgs"
import "./AuthLayout.scss"

interface IAuthLayout {
	children: any
}

const AuthLayout = ({ children }: IAuthLayout) => {
	const { t } = useTranslation("translation", { keyPrefix: "authLayout" })
	return (
		<div className="auth-layout">
			<div className="leftside-section">
				<img alt="banner" className="auth-layout-banner" src={loginBanner} />
				<div className="overlay-effect" />
				<div className="dotted-bg-rect">
					<Svgs.DottedBgRect />
				</div>
				<div className="content-container">
					<div className="logo-wrapper">
						<Svgs.BRGLogo />
					</div>
					<div className="content-wrapper">
						<div className="brand-name-wrapper">
							<Typography className="brand-name" variant="h2">
								{t("app_name_pull")}
							</Typography>
							<div className="brand-name-filled">
								<Typography>{t("app_name_pro")}</Typography>
							</div>
						</div>
						<div>
							<Typography className="description">
								{t("app_description")}
							</Typography>
						</div>
					</div>
				</div>
			</div>
			<div className="rightside-section">
				<div className="bg-ellipsis" />
				<div className="dotted-bg-square">
					<Svgs.DottedBgSquare />
				</div>
				<div className="auth-form-container">{children}</div>
			</div>
		</div>
	)
}

export default AuthLayout
