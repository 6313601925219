import { Box, Grid2 } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import AppFormField from '../../components/AppFormField/AppFormField'
import { IAddSubActivitySectionFormProps } from './validation'

interface ISubActivityDetailsProps extends IAddSubActivitySectionFormProps {
	assigneeOptions: any;
}

export default function SubActivityDetails(
  {
		values,
		errors,
		touched,
		handleChange,
		setFieldValue,
		disabled,
		handleBlur,
		assigneeOptions,
	}: ISubActivityDetailsProps
) {
    const { t } = useTranslation("translation", { keyPrefix: "subActivity" })
	const { t: tErrors } = useTranslation("translation", {
		keyPrefix: "subActivity.errors",
	})
  return (
    <Box>
        <Box
				sx={{
					bgcolor: "white",
					border: "2px solid #fff",
					borderRadius: "12px",
					boxShadow: 4,
					padding: 3,
					display: "flex",
					flexDirection: "column",
					gap: "16px",
				}}
			>
				<Grid2 className="title">{t("general_details")}</Grid2>
                <Grid2 display={"flex"} justifyContent={"space-between"} gap={2}>
					<Grid2 width={"100%"}>
						<AppFormField
							label={'Sub Activity Id'}
							disabled
							name={"sub_activity_status"}
							placeholder={'Sub Activity Id'}
							value={'ACT-001'}
						/>
					</Grid2>
					<Grid2 width={"100%"}>
						<AppFormField
							label={t("sub_activity_name")}
							name={"sub_activity_name"}
							placeholder={t("sub_activity_name")}
							disabled={disabled}
							value={values.sub_activity_name}
							onChange={disabled ? undefined : handleChange}
							onBlur={handleBlur}
							touched={touched.sub_activity_name}
							//errorMsg={errors.sub_activity_name ? t(errors.sub_activity_name) : ""}
						/>
					</Grid2>
                    <Grid2 width={"100%"}>
                    <AppFormField
                            label={t("assignee")}
                            name={"assignee"}
                            placeholder={t("assignee")}
                            // disabled={disabled}
							disabled={true}
                            // value={values.assignee}
							value={assigneeOptions}
                            onChange={disabled ? undefined : handleChange}
                            onBlur={handleBlur}
                            touched={touched.assignee}
                           // errorMsg={errors.assignee ? t(errors.assignee) : ""}
                        />
                        </Grid2>
				</Grid2>
                <Grid2>
                    <AppFormField
                        label={t("description")}
                        name={"description"}
                        isRequired
                        placeholder={t("description")}
                        disabled={disabled}
                        value={values.description}
                        onChange={disabled ? undefined : handleChange}
                        onBlur={handleBlur}
                        touched={touched.description}
                        errorMsg={errors.description ? tErrors('description') : ""}
                        />
                </Grid2>
                </Box>
    </Box>
  )
}
