import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { UserProjectDetail, UserProjectSliceState } from "../types/Project"

const initialState: UserProjectSliceState = {
	currentProject: null,
}

const UserProjectSlice = createSlice({
	name: "userProjectData",
	reducerPath: "userProjectData",
	initialState,
	reducers: {
		updateCurrentProject(
			state,
			action: PayloadAction<UserProjectDetail | null>,
		) {
			state.currentProject = action.payload
			return state
		},
		clearUserProjectData() {
			return { ...initialState }
		},
	},
})

// Export actions
export const { updateCurrentProject, clearUserProjectData } =
	UserProjectSlice.actions

// Export the reducer
export default UserProjectSlice
