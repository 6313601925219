import React, { useEffect, useMemo, useState } from "react"
import { Box, Button, Grid2, Modal, TextField, Typography } from "@mui/material"
import { useFormik } from "formik"
import { useTranslation } from "react-i18next"
import "./AddUser.scss"
// import { useAddCompanyMutation } from "../../api/network/companyApiService"
import { toast } from "react-toastify"
import AppButton from "../../../components/AppButton/AppButton"
import AppFormField from "../../../components/AppFormField/AppFormField"
import {
	useAddUserMutation,
	useGetLimitedRolesQuery,
} from "../../../api/network/userApiService"
import { addUserFormInitialValues } from "./validations"
import { addUserValidationSchema } from "./validations"
import { IAddUserdFields } from "./validations"
import AppSwitch from "../../../components/AppSwitch/AppSwitch"
import AppAutocompleteFormField from "../../../components/AppFormField/AppAutocompleteFormField"
import { useGetAllCompaniesQuery } from "../../../api/network/companyApiService"
import { ProjectDetails } from "../../../api/types/Project"
import { useLazyGetAllProjectsQuery } from "../../../api/network/projectApiService"
import { InviteuserPayload } from "../../../api/types/User"
import { useNavigate } from "react-router-dom"
import { routeUrls } from "../../../routes/routeUrls"
import useCompaniesListOption from "../../../hooks/useCompaniesListOption"
import useUserRole from "../../../hooks/useUserRole"
import { UserRole } from "../../../api/types/Auth"

const AddUser: React.FC = () => {
	const navigate = useNavigate()
	const { t } = useTranslation("translation", { keyPrefix: "usersList" })
	const { t: tAdd } = useTranslation("translation", {
		keyPrefix: "usersList.addUser",
	})
	const { t: tErrors } = useTranslation("translation", {
		keyPrefix: "usersList.addUser.errors",
	})
	const [companyProjects, setCompanyProjects] = useState<ProjectDetails[]>([])

	const handleSave = (values: IAddUserdFields) => {
		if (!values.company) {
			toast.error("Please select a company")
			return
		}
		const addUserData: InviteuserPayload = {
			email: values.email,
			name: values.name,
			company: values.company,
			job_title: values.job_title,
			is_company_admin: values.isAdmin,
			message: values.message,
		}
		if (!values.isAdmin) {
			addUserData.project_role_mapping = [
				{
					project: values.project,
					role: values.role,
				},
			]
		}
		// Api call to create user
		addUserApitrigger(addUserData)
			.unwrap()
			.then((response) => {
				toast.success("User added successfully")
				resetForm()
				console.log("response", response)
			})
			.catch((error) => {
				console.log("error", error)
			})
	}

	const { values, errors, touched, handleChange, handleBlur, setFieldValue, handleSubmit, resetForm } =
		useFormik({
			initialValues: addUserFormInitialValues,
			onSubmit: handleSave,
			validationSchema: addUserValidationSchema,
		})

	const appUserRole = useUserRole()

	const { companiesOptions, isFetching } = useCompaniesListOption()

	const [getProjectApiTrigger, { isFetching: isFetchingProject }] = useLazyGetAllProjectsQuery()

	useEffect(() => {
		if (appUserRole === UserRole.CompanyAdmin) {
			companiesOptions[0]?.id &&
				setFieldValue("company", companiesOptions[0]?.id)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [appUserRole])

	useEffect(() => {
		if (values.company !== 0) {
			getProjectApiTrigger({
				company: values.company,
				page: 1,
				page_size: 500,
			})
				.unwrap()
				.then((response) => {
					console.log("response", response)
					setCompanyProjects(response?.results)
				})
				.catch((error) => {
					console.log("error", error)
				})
		}
	}, [values.company])
	console.log("#@", values);
	const { data: roleData = [], isFetching: isFetchngRole } =
		useGetLimitedRolesQuery()

	const roleOptions = useMemo(() => {
		return roleData.map((roleItem) => ({
			id: roleItem.id,
			label: roleItem.role,
		}))
	}, [roleData])

	const companyProjectOptions = useMemo(() => {
		return companyProjects.map((companyItem) => ({
			id: companyItem.id,
			label: companyItem.name,
		}))
	}, [companyProjects])

	const [addUserApitrigger] = useAddUserMutation()

	const onCancelClick = () => navigate(routeUrls.users.list)

	return (
		<div className="add-user-container">
			<form onSubmit={handleSubmit}>
				<Box sx={{ padding: 4 }}>
					<Box flexDirection="row" display="flex" justifyContent="space-between">
						<Typography className="title">Add a User</Typography>
						<Box>
							<AppButton
								btnSize="medium"
								variant="outlined"
								color="error"
								onClick={onCancelClick}
							>
								Cancel
							</AppButton>
							<AppButton
								type="submit"
								btnSize="medium"
								sx={{ marginLeft: 1 }}
							// onClick={() => handleSave(values)}
							>
								{tAdd("send_invite")}
							</AppButton>
						</Box>
					</Box>
					<Grid2
						sx={{
							bgcolor: "white",
							border: "2px solid #fff",
							borderRadius: 8,
							boxShadow: 24,
							padding: 4,
							marginTop: 4,
						}}
						container
						spacing={2}
					>
						<Grid2 size={12}>
							<Typography className="title">
								{tAdd("personal_details")}
							</Typography>
						</Grid2>
						<Grid2 size={6}>
							<AppFormField
								label={t("name")}
								name="name"
								placeholder={tAdd("name_placeholder")}
								isRequired
								value={values.name}
								onChange={handleChange}
								onBlur={handleBlur}
								touched={touched.name}
								errorMsg={errors.name ? tErrors('name_required') : ""}
							/>
						</Grid2>
						<Grid2 size={6}>
							<AppFormField
								label={t("email")}
								name="email"
								placeholder={tAdd("email_placeholder")}
								isRequired
								value={values.email}
								onChange={handleChange}
								onBlur={handleBlur}
								touched={touched.email}
								errorMsg={errors.email ? tErrors('email_required') : ""}
							/>
						</Grid2>
						<Grid2 size={6}>
							<AppFormField
								label={t("job_title")}
								name="job_title"
								placeholder={tAdd("job_title_placeholder")}
								isRequired
								value={values.job_title}
								onChange={handleChange}
								onBlur={handleBlur}
								touched={touched.job_title}
								errorMsg={errors.job_title ? tErrors('job_title_required') : ""}
							/>
						</Grid2>
						<Grid2 size={12}>
							<AppFormField
								label={tAdd("personal_message")}
								isRequired
								name="message"
								placeholder={tAdd("personal_message_placeholder")}
								value={values.message}
								onChange={handleChange}
								onBlur={handleBlur}
								touched={touched.message}
								errorMsg={errors.message ? tErrors('message_error') : ""}
								inputProps={{
									multiline: true,
									rows: 2,
								}}
							/>
						</Grid2>
					</Grid2>
					<Grid2
						sx={{
							bgcolor: "white",
							border: "2px solid #fff",
							borderRadius: 8,
							boxShadow: 24,
							padding: 4,
							marginTop: 4,
						}}
						container
						spacing={2}
					>
						<Grid2 size={12}>
							<Typography className="title">{tAdd("project_details")}</Typography>
						</Grid2>

						<Grid2 size={6}>
							<AppAutocompleteFormField
								label={t("company")}
								isRequired
								disabled={isFetching || appUserRole === UserRole.CompanyAdmin}
								name="company"
								placeholder={tAdd("select_company")}
								value={values.company || ''}
								onChange={setFieldValue}
								onBlur={handleBlur}
								touched={touched.company}
								errorMsg={(errors.company) || (values?.company === 0) ? tErrors('company_required') : ""}
								options={companiesOptions}
							/>
						</Grid2>
						<Grid2 size={6}>
							<AppSwitch
								label={tAdd("is_company_admin")}
								value={values.isAdmin}
								onChange={(e) => setFieldValue("isAdmin", !values.isAdmin)}
								errorMsg={errors.isAdmin ? errors.isAdmin : ""}
							/>
						</Grid2>
						{!values.isAdmin && (
							<>
								<Grid2 size={6}>
									<AppAutocompleteFormField
										label={tAdd("project")}
										isRequired
										disabled={isFetching || isFetchingProject}
										name="project"
										placeholder={tAdd("project_placeholder")}
										value={values.project || ''}
										onChange={setFieldValue}
										onBlur={handleBlur}
										touched={touched.project}
										errorMsg={(errors.project) || (values?.project === 0) ? tErrors('project_required') : ""}
										options={companyProjectOptions}
									/>
								</Grid2>
								<Grid2 size={6}>
									<AppAutocompleteFormField
										label={tAdd("role")}
										isRequired
										disabled={isFetchngRole}
										name="role"
										placeholder={tAdd("role_placeholder")}
										value={values.role || ''}
										onChange={setFieldValue}
										onBlur={handleBlur}
										touched={touched.role}
										errorMsg={(errors.role) || (values?.role === 0) ? tErrors('role_required') : ""}
										options={roleOptions}
									/>
								</Grid2>
							</>
						)}
					</Grid2>
				</Box>
			</form>
		</div>
	)
}

export default AddUser
