/* eslint-disable no-mixed-spaces-and-tabs */
import { useEffect, useMemo, useState } from "react"

import Box from "@mui/material/Box"
import Drawer from "@mui/material/Drawer"
import Button from "@mui/material/Button"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import Fade from "@mui/material/Fade"
import "./SwimlaneActivityDetail.scss"
import {
	Avatar,
	AvatarGroup,
	Grid2,
	LinearProgress,
	Menu,
	MenuItem,
	Typography,
} from "@mui/material"
import { activitiesStatusArray } from "../../utils"
import { Svgs } from "../../assets/svgs"
import { formatDateWithMonthName } from "../../utils/dateTime"
import { capitalizeEachWord } from "../../utils/index"
import { getRelationshipDescription } from "./SwimlaneData"
import { useNavigate } from "react-router-dom"
import {
	useGetAllProjectsForUserQuery,
	useGetSessionIdQuery,
	useUpdateActivityMutation,
} from "../../api/network/projectApiService"
import { toast } from "react-toastify"
import { routeUrls } from "../../routes/routeUrls"
import SubActivityModal from "./SubActivityModal"
import StatusConfirmationModal from "../../components/AppConfirmationModal/StatusConfirmationModal"
import { format as dateFnsFormat } from "date-fns"
import { ProjectUserRole } from "../../api/types/Auth"

export default function SwimlaneActivityDetail({
	openDrawer,
	onToggleDrawer,
	activityDetails,
}: {
	openDrawer: boolean
	onToggleDrawer: () => void
	activityDetails: any
}) {
	// const [openDrawer, setOpenDrawer] = React.useState(false)
	const navigate = useNavigate()
	const [statusFilter, setStatusFilter] = useState("")
	const [statusConfirmation, setStatusConfirmation] = useState(false)
	const [showHide, setShowHide] = useState(false)
	const [showHide2, setShowHide2] = useState(false)
	const [openSubActivities, setOpenSubActivities] = useState(false)
	const [tempStatus, setTempStatus] = useState(activityDetails?.statusFilter)
	const [statusUpdate, setStatusUpdate] = useState("")
	// const [open, setOpen] = useState(false)
	const [anchorEl, setAnchorEl] = useState(null)
	const open = Boolean(anchorEl)
	const [updateActivityApiTrigger, { isLoading: isSavingActivity }] =
		useUpdateActivityMutation()
	const { data, error } = useGetSessionIdQuery(activityDetails?.project)
	const { data: projectsData, isFetching } = useGetAllProjectsForUserQuery()
	const handleClick = (event: any) => {
		if (activityDetails?.is_editable) {
			setAnchorEl(event.currentTarget)
		}
	}

	const handleShowHide = () => {
		setShowHide(!showHide)
	}

	const handleShowHide2 = () => {
		setShowHide2(!showHide2)
	}

	const handleClose = () => {
		setAnchorEl(null)
	}

	// const toggleDrawer = () => {
	// 	setOpenDrawer(!openDrawer)
	// }

	const activitiesStatusOptions = useMemo(() => {
		return activitiesStatusArray.map((s) => ({ id: s.status, label: s.status }))
	}, [])

	const changeStatus = () => {
		console.log("Status changed", statusFilter)
		setStatusConfirmation(false)
		setAnchorEl(null)
	}

	const updateActivityStatus = (value: any) => {
		const payload: { id: string; [key: string]: any } = {
			id: activityDetails?.id,
		}
		if (statusFilter == "Planned") {
			payload["id"] = activityDetails?.id
			payload["activity_status"] = "planned"
			payload["session"] = data?.id
			payload["actual_start_date"] = value?.actual_start_date
				? dateFnsFormat(
						new Date(value.actual_start_date ?? ""),
						"yyyy-MM-dd hh:mm:ss",
					)
				: null
			payload["actual_finish_date"] = value?.actual_finish_date
				? dateFnsFormat(
						new Date(value.actual_finish_date ?? ""),
						"yyyy-MM-dd hh:mm:ss",
					)
				: null
		} else if (statusFilter == "In Progress") {
			payload["id"] = activityDetails?.id
			payload["activity_status"] = "in_progress"
			payload["session"] = data?.id
			payload["actual_start_date"] = dateFnsFormat(
				new Date(value.actual_start_date),
				"yyyy-MM-dd HH:mm:ss",
			)
		} else if (statusFilter == "Completed") {
			payload["id"] = activityDetails?.id
			payload["activity_status"] = "completed"
			payload["session"] = data?.id
			payload["actual_finish_date"] = dateFnsFormat(
				new Date(value.actual_finish_date ?? ""),
				"yyyy-MM-dd hh:mm:ss",
			)
		}
		updateActivityApiTrigger(payload)
			.unwrap()
			.then((res) => {
				console.log("res", res)
				setStatusConfirmation(false)
				changeStatus()
				toast.success("Status updated successfully")
				onToggleDrawer()
				// window.location.reload()
			})
			.catch((err) => {
				console.log("err", err)
			})
	}

	const handleCloseStatusChangeModal = () => {
		setStatusConfirmation(false)
		setStatusFilter(tempStatus)
		handleClose()
	}

	const handleStatusFilterChange = (value: string) => {
		setStatusFilter(value)
	}

	const handleSubActivitiesClick = () => {
		onToggleDrawer()
		setOpenSubActivities(true)
	}

	const handleEditActivity = () => {
		if (activityDetails?.is_editable) {
			navigate(routeUrls.activity.activityDetails(activityDetails.id))
		}
	}

	useEffect(() => {
		setTempStatus(activityDetails?.status)
		setStatusFilter(activityDetails?.status)
	}, [])

	return (
		<div>
			{openSubActivities && (
				<SubActivityModal
					activityDetails={activityDetails}
					open={openSubActivities}
					editable={activityDetails?.is_editable}
					onClose={() => {
						setOpenSubActivities(false)
					}}
				/>
			)}
			<>
				{/* <Button onClick={toggleDrawer}>{"Open Drawer"}</Button> */}
				{statusConfirmation ? (
					// <AppConfirmationModal
					// 	open={true}
					// 	onConfirm={updateActivityStatus}
					// 	onClose={() => setStatusConfirmation(false)}
					// 	confirmationMessage="Are you sure you want to change the status?"
					// />
					<StatusConfirmationModal
						open={true}
						onConfirm={updateActivityStatus}
						onClose={handleCloseStatusChangeModal}
						confirmationMessage="Are you sure you want to change the status?"
						status={statusFilter}
						activity_id={activityDetails?.activity_id}
						start_date={activityDetails?.actual_start_date}
						finish_date={activityDetails?.actual_finish_date}
					/>
				) : (
					<>
						<Drawer
							anchor={"right"}
							open={openDrawer}
							onClose={onToggleDrawer}
							className="activities-details-container"
						>
							<Box
								position="fixed"
								marginLeft={"-22px"}
								marginTop={"-2px"}
								style={{ cursor: "pointer" }}
								onClick={onToggleDrawer}
							>
								<Svgs.CloseIcon />
							</Box>

							<Box width={260} m={1} className="content-container">
								<Box className="content-box" height={"80vh"}>
									<Box display={"flex"} justifyContent={"space-between"}>
										<Grid2 className="title">
											{activityDetails?.activity_id}
											<span
												style={{ marginLeft: "5px", cursor: "pointer" }}
												// onClick={() =>
												// 	navigate(
												// 		routeUrls.activity.activityDetails(
												// 			activityDetails.id,
												// 		),
												// 	)
												// }

												onClick={handleEditActivity}
											>
												{activityDetails?.is_editable &&
													projectsData?.results[0]?.role !==
														ProjectUserRole.TeamMember &&
													projectsData?.results[0]?.role !==
														ProjectUserRole.Viewer && <Svgs.EditIcon />}
											</span>
										</Grid2>
										<Grid2>
											<Button
												id="demo-customized-button"
												aria-controls={
													open ? "demo-customized-menu" : undefined
												}
												aria-haspopup="true"
												aria-expanded={open ? "true" : undefined}
												variant="contained"
												disableElevation
												disabled={Boolean(error)}
												style={{
													background: `${
														statusFilter.toLowerCase() === "planned"
															? "#F57F32"
															: statusFilter === "in_progress" ||
																  statusFilter == "In Progress"
																? "#00A79D"
																: "#54AE2A"
													}`,
													color: "#fff",
													marginTop: "-5px",
													marginRight: "-6px",
												}}
												onClick={handleClick}
												endIcon={<KeyboardArrowDownIcon />}
											>
												{statusFilter === "in_progress"
													? "In Progress"
													: statusFilter === "planned"
														? "Planned"
														: statusFilter === "completed"
															? "Completed"
															: " "}
											</Button>
											<Menu
												id="fade-menu"
												MenuListProps={{
													"aria-labelledby": "fade-button",
												}}
												anchorEl={anchorEl}
												open={open}
												onClose={handleClose}
												TransitionComponent={Fade}
											>
												{activitiesStatusOptions?.map((option) => {
													const isCompleted =
														statusFilter === "completed" ||
														statusFilter === "Completed"
													const isDisabled =
														isCompleted &&
														(option.label.toLowerCase() === "in progress" ||
															option.label.toLowerCase() === "planned")

													return (
														<MenuItem
															key={option.id}
															disabled={isDisabled}
															onClick={() => {
																if (!isDisabled) {
																	setStatusConfirmation(true)
																	handleStatusFilterChange(option.label)
																}
															}}
															style={{
																opacity: isDisabled ? 0.6 : 1,
																cursor: isDisabled ? "not-allowed" : "pointer",
															}}
														>
															{option.label}
														</MenuItem>
													)
												})}
											</Menu>
										</Grid2>
									</Box>
									<Box>
										<Grid2 className={"title3"}>
											{activityDetails?.activity_name}
										</Grid2>
										{/* <Grid2 className="content">
											{
												"Installation of electrical wiring on the first floor, including outlets and light switches."
											}
										</Grid2> */}
										<Grid2
											display={"flex"}
											justifyContent={"space-between"}
											mt={1}
										>
											<Grid2 width={220}>
												<LinearProgress
													variant="determinate"
													value={activityDetails?.progress}
													color="success"
												/>
											</Grid2>
											<Typography variant={"caption"} mt={-1}>
												{`${activityDetails?.progress}%`}
											</Typography>
										</Grid2>
										<Box>
											<Grid2 className="title2">{"Plan Details"}</Grid2>
											<Grid2 display={"flex"} justifyContent={"space-between"}>
												<Typography className="content2" variant={"caption"}>
													{"Planned Start Date"}
												</Typography>
												<Typography className="content3" variant={"caption"}>
													{formatDateWithMonthName(
														activityDetails?.planned_start_date as string,
													)}
												</Typography>
											</Grid2>
											<Grid2 display={"flex"} justifyContent={"space-between"}>
												<Typography className="content2" variant={"caption"}>
													{"Actual Start Date"}
												</Typography>
												<Typography
													className="content3"
													color="#54AE2A"
													variant={"caption"}
												>
													{formatDateWithMonthName(
														(activityDetails?.actual_start_date as string) ??
															"",
													)}
												</Typography>
											</Grid2>
											<Grid2 display={"flex"} justifyContent={"space-between"}>
												<Typography className="content2" variant={"caption"}>
													{"Planned Finish Date"}
												</Typography>
												<Typography className="content3" variant={"caption"}>
													{formatDateWithMonthName(
														activityDetails?.planned_finish_date as string,
													)}
												</Typography>
											</Grid2>
											<Grid2 display={"flex"} justifyContent={"space-between"}>
												<Typography className="content2" variant={"caption"}>
													{"Actual Finish Date"}
												</Typography>
												<Typography
													className="content3"
													color="#FDB913"
													variant={"caption"}
												>
													{formatDateWithMonthName(
														activityDetails?.actual_finish_date as string,
													)}
												</Typography>
											</Grid2>
											<Grid2 display={"flex"} justifyContent={"space-between"}>
												<Typography className="content2" variant={"caption"}>
													{"Total Float"}
												</Typography>
												<Typography
													className="content3"
													color="#FDB913"
													variant={"caption"}
												>
													{activityDetails?.total_float}
												</Typography>
											</Grid2>
										</Box>
										<Box>
											<Grid2 className="title2">{"Duration"}</Grid2>
											<Grid2 display={"flex"} justifyContent={"space-between"}>
												<Typography className="content2" variant={"caption"}>
													{"Original Duration"}
												</Typography>
												<Typography className="content3" variant={"caption"}>
													{`${activityDetails?.original_duration} days`}
												</Typography>
											</Grid2>
											{/* <Grid2 display={"flex"} justifyContent={"space-between"}>
												<Typography className="content2" variant={"caption"}>
													{"Remaining Duration"}
												</Typography>
												<Typography className="content3" variant={"caption"}>
													{"10 days"}
												</Typography>
											</Grid2> */}
											<Grid2 display={"flex"} justifyContent={"space-between"}>
												<Typography className="content2" variant={"caption"}>
													{"At Completion Duration"}
												</Typography>
												<Typography
													className="content3"
													color="#E72222"
													variant={"caption"}
												>
													{/* {"Est. 6 days (1 Day Delay)"} */}
													{activityDetails?.at_completion_duration}
												</Typography>
											</Grid2>
										</Box>
										<Box>
											<Grid2 display={"flex"} justifyContent={"space-between"}>
												<Typography className="title2">
													{"Predecessor Activities"}
												</Typography>
												<Typography
													onClick={handleShowHide}
													style={{ cursor: "pointer" }}
												>
													{showHide ? <Svgs.ShowIcon /> : <Svgs.HideIcon />}
												</Typography>
											</Grid2>
											{showHide && (
												<>
													{activityDetails?.predecessor?.map(
														(predecessor: any) => (
															<Box key={predecessor?.id} mb={"5px"}>
																<Grid2
																	display={"flex"}
																	justifyContent={"space-between"}
																>
																	<Typography
																		className="content1"
																		variant={"caption"}
																	>
																		<span className="bold-text gray-color">
																			{predecessor.predecessor_id}
																		</span>
																	</Typography>
																	<Typography
																		className="content1"
																		variant={"caption"}
																	>
																		<span className="bold-text">
																			{`${getRelationshipDescription(predecessor.relationship_type)} 
																			(${predecessor.relationship_type})`}
																		</span>
																	</Typography>
																</Grid2>
																<Grid2 className="content-container">
																	<Typography
																		variant={"caption"}
																		width={200}
																		className="predecessor-name gray-color"
																	>
																		{capitalizeEachWord(
																			predecessor.predecessor_name,
																		)}
																	</Typography>
																	<Typography
																		variant={"caption"}
																		className="style-one"
																	>
																		<span className="right-margin gray-color">
																			Lag
																		</span>
																		<span className="bold-text">{`${predecessor.lag_days} days`}</span>
																	</Typography>
																</Grid2>
																<Grid2 className="content-container">
																	<Typography
																		variant={"caption"}
																		width={200}
																		style={{ flexGrow: 1 }}
																	>
																		<span className="gray-color">
																			Start Date:{" "}
																		</span>
																		<span className="bold-text">
																			{formatDateWithMonthName(
																				predecessor.planned_start_date as string,
																			)}
																		</span>
																	</Typography>
																	<Typography
																		variant={"caption"}
																		width={200}
																		className="style-one"
																	>
																		<span className="gray-color">
																			End Date:{" "}
																		</span>
																		<span className="bold-text">
																			{formatDateWithMonthName(
																				predecessor.planned_finish_date as string,
																			)}
																		</span>
																	</Typography>
																</Grid2>
															</Box>
														),
													)}
												</>
											)}
										</Box>
										<Box mt={1}>
											<Grid2 display={"flex"} justifyContent={"space-between"}>
												<Typography className="title2">
													{"Successor Activities"}
												</Typography>
												<Typography
													onClick={handleShowHide2}
													style={{ cursor: "pointer" }}
												>
													{showHide2 ? <Svgs.ShowIcon /> : <Svgs.HideIcon />}
												</Typography>
											</Grid2>
											{showHide2 && (
												<Box>
													{activityDetails?.successor?.map((successor: any) => (
														<Box key={successor?.id} mb={"5px"}>
															<Grid2
																display={"flex"}
																justifyContent={"space-between"}
															>
																<Typography
																	className="content1"
																	variant={"caption"}
																>
																	<span className="bold-text gray-color">
																		{successor.successor_id}
																	</span>
																</Typography>
																<Typography
																	className="content1"
																	variant={"caption"}
																>
																	<span className="bold-text">
																		{`${getRelationshipDescription(successor.relationship_type)} 
																			(${successor.relationship_type})`}
																	</span>
																</Typography>
															</Grid2>
															<Grid2 className="content-container">
																<Typography
																	variant={"caption"}
																	width={200}
																	className="predecessor-name gray-color"
																>
																	{capitalizeEachWord(successor.successor_name)}
																</Typography>
																<Typography
																	variant={"caption"}
																	className="style-one"
																>
																	<span className="right-margin gray-color">
																		Lag
																	</span>
																	<span className="bold-text">{`${successor.lag_days} days`}</span>
																</Typography>
															</Grid2>
															<Grid2 className="content-container">
																<Typography
																	variant={"caption"}
																	width={200}
																	style={{ flexGrow: 1 }}
																>
																	<span className="gray-color">
																		Start Date:{" "}
																	</span>
																	<span className="bold-text">
																		{formatDateWithMonthName(
																			successor.planned_start_date as string,
																		)}
																	</span>
																</Typography>
																<Typography
																	variant={"caption"}
																	width={200}
																	className="style-one"
																>
																	<span className="gray-color">End Date: </span>
																	<span className="bold-text">
																		{formatDateWithMonthName(
																			successor.planned_finish_date as string,
																		)}
																	</span>
																</Typography>
															</Grid2>
														</Box>
													))}
												</Box>
											)}
										</Box>
									</Box>
								</Box>
							</Box>
							<Box mt={1}>
								<Typography
									textAlign={"center"}
									fontWeight={"700"}
									style={{ cursor: "pointer" }}
									onClick={handleSubActivitiesClick}
								>
									{"View Sub Activities"}
								</Typography>
							</Box>
							<Box
								display={"flex"}
								justifyContent={"space-between"}
								p={1}
								style={{ background: "#F5DCAE", borderRadius: "5px" }}
								mt={1}
								className="footer"
							>
								<Grid2 mt={1}>
									<span className="bold-text">{"Electricians "}</span>
									{"(Electrical)"}
								</Grid2>
								<Grid2>
									<AvatarGroup variant="square" max={4}>
										{activityDetails?.assignee?.map((assignee: any) => (
											<Avatar
												key={assignee?.id}
												style={{
													color: "#FFFFFF",
													background: "#22BCE7",
													borderRadius: "10px",
												}}
												alt={assignee?.name}
												src="/static/images/avatar/1.jpg"
											/>
										))}
										{/* <Avatar
											style={{
												color: "#FFFFFF",
												background: "#22BCE7",
												borderRadius: "10px",
											}}
											alt="Travis Howard"
											src="https://cdn.pix
									<Avatar
											style={{
												color: "#FFFFFF",
												background: "#22BCE7",
												borderRadius: "10px",
											}}
											alt="Remy Sharp"
											src="/static/images/avatar/1.jpg"
										/>}
										{/* <Avatar
											style={{
												color: "#FFFFFF",
												background: "#22BCE7",
												borderRadius: "10px",
											}}
											alt="Travis Howard"
											src="https://cdn.pixabay.com/photo/2022/09/27/19/46/ai-generated-7483596_960_720.jpg"
										/> */}
									</AvatarGroup>
								</Grid2>
							</Box>
						</Drawer>
					</>
				)}
			</>
		</div>
	)
}
