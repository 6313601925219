import { Outlet } from "react-router-dom"
import AppSidebar from "../AppSidebar/AppSidebar"
import "./PageLayout.scss"

const PageLayout = () => {
	return (
		<div className="page-layout">
			<AppSidebar />
			<Outlet />
		</div>
	)
}

export default PageLayout
