import { Box, Grid2, Typography } from "@mui/material"
import { Item } from "./SwimlaneData"
import "./ActivityCard.scss"
import { Svgs } from "../../assets/svgs"
import { useState } from "react"
import SwimlaneActivityDetail from "./SwimlaneActivityDetail"

const ActivityCard = ({
	activity_id,
	activity_name,
	activityDetails,
	status,
	id,
	type,
}: Item) => {
	const [isDrawerOpen, setIsDrawerOpen] = useState(false)
	const toggleDrawer = () => {
		setIsDrawerOpen((prev) => !prev)
	}

	return (
		<>
			{
				<SwimlaneActivityDetail
					openDrawer={isDrawerOpen}
					onToggleDrawer={toggleDrawer}
					activityDetails={activityDetails}
				/>
			}
			<div className={`card-container ${status}`}>
				<Grid2 display={"flex"} gap={1} className="card">
					<Typography fontSize={12} className="card-title">
						{activity_name}
					</Typography>
					<Typography onClick={toggleDrawer}>
						<Svgs.EyeOutlineBlue />
					</Typography>
					<Typography className="float-text">
						{activityDetails?.total_float < 0
							? activityDetails.total_float
							: null}
					</Typography>
				</Grid2>
				{type && (
					<Typography variant="body2" className={`card-type ${type}`}>
						{type}
					</Typography>
				)}
			</div>
		</>
	)
}

export default ActivityCard
