import { RouterProvider } from "react-router-dom"
import { ToastContainer } from "react-toastify"
import { ThemeProvider } from "@mui/material"
import router from "./routes/router"
import { AppTheme } from "./theme"
import "react-toastify/dist/ReactToastify.css"
import { Provider } from "react-redux"
import { store } from "./api/store/store"

function App() {
	return (
		<ThemeProvider theme={AppTheme}>
			<Provider store={store}>
				<RouterProvider router={router} />
				<ToastContainer
					theme="light"
					className={"app-toast"}
					autoClose={4000}
					position="bottom-right"
				/>
			</Provider>
		</ThemeProvider>
	)
}

export default App
