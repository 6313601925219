import {
	AddCompanyResponse,
	Company,
	EditCompanyPayload,
	GetCompaniesListPayload,
} from "../types/Company"
import { ListingResponse } from "../types/Listing"
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { API_ENDPOINTS, API_SERVER_URL } from "./endpoints"
import { API_METHODS } from "../../constants/constants"
import { handleAuthError } from "./errorHandler"
import { getUserData } from "../../utils/localstorage"

enum CompanyApiTags {
	EDIT_COMPANY = "EDIT_COMPANY",
}

export const CompanyAPIs = createApi({
	reducerPath: "companyAPI",
	tagTypes: [CompanyApiTags.EDIT_COMPANY],
	baseQuery: fetchBaseQuery({
		baseUrl: API_SERVER_URL,
		prepareHeaders: async (headers) => {
			// headers.set("Content-Type", "application/json") // Commented the line to fix image upload issue in add and edit company api
			const userData = await getUserData()
			headers.set("Authorization", `Token ${userData?.token}`)
			return headers
		},
	}),
	endpoints: (builder) => ({
		addCompany: builder.mutation<AddCompanyResponse, FormData>({
			query: (params: FormData) => {
				return {
					url: API_ENDPOINTS.COMPANY.ADD_COMPANY,
					method: API_METHODS.POST,
					body: params,
				}
			},
			transformErrorResponse(baseQueryReturnValue) {
				handleAuthError(baseQueryReturnValue)
				return baseQueryReturnValue
			},
			transformResponse: async (data: AddCompanyResponse) => {
				return data
			},
		}),
		getAllCompanies: builder.query<
			ListingResponse<Company[]>,
			GetCompaniesListPayload
		>({
			query: (params?: GetCompaniesListPayload) => {
				return {
					url: API_ENDPOINTS.COMPANY.COMPANIES,
					method: API_METHODS.GET,
					params: params,
				}
			},
			providesTags: [CompanyApiTags.EDIT_COMPANY],
			transformErrorResponse(baseQueryReturnValue) {
				handleAuthError(baseQueryReturnValue)
				return baseQueryReturnValue
			},
			transformResponse: async (data: ListingResponse<Company[]>) => {
				return data
			},
		}),
		getCompanyDetails: builder.query<Company, number>({
			query: (id: number) => {
				return {
					url: `${API_ENDPOINTS.COMPANY.COMPANIES}${id}/`,
					method: API_METHODS.GET,
				}
			},
			providesTags: [CompanyApiTags.EDIT_COMPANY],
			transformErrorResponse(baseQueryReturnValue) {
				handleAuthError(baseQueryReturnValue)
				return baseQueryReturnValue
			},
			transformResponse: async (data: Company) => {
				return data
			},
		}),
		getCompanyUsers: builder.query<any, { id: string }>({
			query: ({ id }) => {
				return {
					url: `${API_ENDPOINTS.COMPANY.GET_COMPANY_USERS}${id}/`,
					method: API_METHODS.GET,
				}
			},
			transformErrorResponse(baseQueryReturnValue) {
				handleAuthError(baseQueryReturnValue)
				return baseQueryReturnValue
			},
			transformResponse: async (data: Company) => {
				return data
			},
		}),
		// Edit company
		editCompany: builder.mutation<Company, EditCompanyPayload>({
			query: (params: EditCompanyPayload) => {
				return {
					url: `${API_ENDPOINTS.COMPANY.COMPANIES}${params.id}/`,
					method: API_METHODS.PATCH,
					body: params.formData,
				}
			},
			invalidatesTags: [CompanyApiTags.EDIT_COMPANY],
			transformErrorResponse(baseQueryReturnValue) {
				handleAuthError(baseQueryReturnValue)
				return baseQueryReturnValue
			},
			transformResponse: async (data: any) => {
				return data
			},
		}),
		changeCompanyStatus: builder.mutation<any, { id: string }>({
			query: ({ id }) => {
				return {
					url: API_ENDPOINTS.COMPANY.CHANGE_STATUS(id),
					method: API_METHODS.PATCH,
				}
			},
			transformErrorResponse(baseQueryReturnValue) {
				handleAuthError(baseQueryReturnValue)
				return baseQueryReturnValue
			},
			transformResponse: async (data: any) => {
				return data
			},
		}),
	}),
})

export const {
	useAddCompanyMutation,
	useGetAllCompaniesQuery,
	useGetCompanyDetailsQuery,
	useGetCompanyUsersQuery,
	useEditCompanyMutation,
	useChangeCompanyStatusMutation,
} = CompanyAPIs
