import React, { useMemo, useState } from "react"
import { Box, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"
import AppButton from "../../components/AppButton/AppButton"
import "./Dashboard.scss"
import { Svgs } from "../../assets/svgs"
import useUserRole from "../../hooks/useUserRole"
import { UserRole } from "../../api/types/Auth"
import UserDashboard from "./UserDashboard"
import DashboardWelcomeContent from "./DashboardWelcomeContent"
import ImportXerModal from "./ImportXerModal"

const Dashboard: React.FC = () => {
	const { t } = useTranslation("translation", { keyPrefix: "dashboard" })
	const [showImportPopup, setShowImportPopup] = useState(false)

	const appUserRole = useUserRole()

	const openImportPopup = () => {
		if (appUserRole === UserRole.SuperAdmin) {
			setShowImportPopup(true)
		}
	}

	const closeImportPopup = () => {
		setShowImportPopup(false)
	}

	const importExportButtons = useMemo(() => {
		return (
			(appUserRole === UserRole.SuperAdmin ||
				appUserRole === UserRole.CompanyAdmin) && (
				<Box display="flex" gap="24px">
					<AppButton
						btnSize="medium"
						onClick={openImportPopup}
						disabled={appUserRole !== UserRole.SuperAdmin}
						endIcon={<Svgs.DownloadIcon />}
					>
						{t("import_p6")}
					</AppButton>
					<AppButton
					btnSize="medium"
					disabled={appUserRole !== UserRole.SuperAdmin}
					endIcon={<Svgs.UploadIcon
					/>}>
						{t("export_p6")}
					</AppButton>
				</Box>
			)
		)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [appUserRole])

	const dashboardContent = useMemo(() => {
		if (
			appUserRole === UserRole.SuperAdmin ||
			appUserRole === UserRole.CompanyAdmin
		) {
			return <DashboardWelcomeContent />
		} else {
			return <UserDashboard />
		}
	}, [appUserRole])

	return (
		<div className="dashboard-container">
			<div className="header">
				<Typography className="title">{t("dashboard")}</Typography>
				{importExportButtons}
			</div>
			{dashboardContent}
			<ImportXerModal open={showImportPopup} onClose={closeImportPopup} />
		</div>
	)
}

export default Dashboard
