import React, { useEffect, useState } from "react"
import AuthLayout from "../../components/AuthLayout/AuthLayout"
import {
	Box,
	Button,
	IconButton,
	InputAdornment,
	Typography,
} from "@mui/material"
import AppFormField from "../../components/AppFormField/AppFormField"
import { Svgs } from "../../assets/svgs"
import AppButton from "../../components/AppButton/AppButton"
import { useTranslation } from "react-i18next"
import { useFormik } from "formik"
import {
	createPasswordFormInitialValues,
	createPasswordValidationSchema,
	ICreatePasswordFields,
} from "./validation"
import { toast } from "react-toastify"
import "./CreatePassword.scss"
import { useNavigate, useParams, useSearchParams } from "react-router-dom"
import {
	useSignupMutation,
	useVerifySignupCodeMutation,
} from "../../api/network/authApiSercvice"
import ConfirmationPopUp from "../ResetPassword/ConfirmationPopUp"
import ResendInvitation from "../ResendInvite/ResendInvite"

const CreatePassword: React.FC = () => {
	const [searchParams, setSearchParams] = useSearchParams()
	const navigate = useNavigate()
	const [showPassword, setShowPassword] = useState(false)
	const [showConfirmPassword, setShowConfirmPassword] = useState(false)
	const [isCodeVerified, setIsCodeVerified] = useState(false)
	const [confirmPassword, setConfirmPassword] = useState(false)
	const [showResetInvitePage, setShowResetInvitePage] = useState(false)
	const { t } = useTranslation("translation", { keyPrefix: "createPassword" })

	const name = searchParams.get("name")
	const email = searchParams.get("email")
	const code = searchParams.get("code")

	const [codeVerifierApiTrigger] = useVerifySignupCodeMutation()
	const [signupApiTrigger] = useSignupMutation()

	useEffect(() => {
		if (email && code) {
			codeVerifierApiTrigger({ email, token: code })
				.unwrap()
				.then((res) => {
					if (res?.success) {
						setIsCodeVerified(true)
						toast.success("Code verified successfully")
					}
				})
				.catch((err) => {
					setIsCodeVerified(false)
					setShowResetInvitePage(true)
					// toast.error("Code verification failed")
				})
		}
	}, [email, code, codeVerifierApiTrigger])

	const handleSignup = (values: ICreatePasswordFields) => {
		if (!email) {
			return
		}
		signupApiTrigger({ email: email, password: values.password })
			.unwrap()
			.then((res) => {
				if (res) {
					toast.success("Signup successful")
					// navigate(routeUrls.login + /*?email=*/ `?email=${email}`)
					setConfirmPassword(true)
				}
			})
			.catch((err) => {
				toast.error("Signup failed")
			})
	}

	const { values, errors, touched, handleChange, handleBlur, handleSubmit } =
		useFormik({
			initialValues: createPasswordFormInitialValues,
			onSubmit: handleSignup,
			validationSchema: createPasswordValidationSchema,
		})

	const toggleShowPassword = () => {
		setShowPassword((val) => !val)
	}

	const toggleShowConfirmPassword = () => {
		setShowConfirmPassword((val) => !val)
	}

	return (
		<>
			{
				showResetInvitePage ? (
					<ResendInvitation
						title={'Link Has Expired'}
						description={'The link you are trying to use has expired.'}
						description2={'Please click the Request for Invitation button below to reinitiate the process.'}
						backButtonText={'Request for Invitation'}
						email={email}
					/>
				)
					:
					confirmPassword ? (
						<ConfirmationPopUp
							title={t("password_setup_title")}
							description={t("password_setup_description")}
							description2={t("password_setup_login_instruction")}
							backButtonText={t("proceed_to_login")}
							email={email}
							status="success"
						/>
					) : (
						<AuthLayout>

							<Box className="password-form-container">
								<form onSubmit={handleSubmit}>
									<Typography className="title">{`${t("heading")} ${name}!`}</Typography>
									<Typography className="heading1">{t("heading1")}</Typography>
									<Typography className="heading2">{t("heading2")}</Typography>
									<div className="fields-container">
										<AppFormField
											label={t("password")}
											isRequired
											highlightLabelIfDataIsValid
											name="password"
											type={showPassword ? "text" : "password"}
											placeholder={t("enter_password")}
											value={values.password}
											onChange={handleChange}
											onBlur={handleBlur}
											touched={touched.password}
											errorMsg={errors.password ? t(errors.password) : ""}
											inputProps={{
												slotProps: {
													input: {
														endAdornment: (
															<InputAdornment position="end">
																<IconButton
																	aria-label="toggle password visibility"
																	onClick={toggleShowPassword}
																>
																	{showPassword ? (
																		<Svgs.EyeOutlineBlue />
																	) : (
																		<Svgs.EyeOutline />
																	)}
																</IconButton>
															</InputAdornment>
														),
													},
												},
											}}
										/>
										<AppFormField
											label={t("confirm_password")}
											isRequired
											highlightLabelIfDataIsValid
											name="confirmpassword"
											type={showConfirmPassword ? "text" : "password"}
											placeholder={t("enter_confirm_password")}
											value={values.confirmpassword}
											onChange={handleChange}
											onBlur={handleBlur}
											touched={touched.confirmpassword}
											errorMsg={errors.confirmpassword ? t(errors.confirmpassword) : ""}
											inputProps={{
												slotProps: {
													input: {
														endAdornment: (
															<InputAdornment position="end">
																<IconButton
																	aria-label="toggle password visibility"
																	onClick={toggleShowConfirmPassword}
																>
																	{showConfirmPassword ? (
																		<Svgs.EyeOutlineBlue />
																	) : (
																		<Svgs.EyeOutline />
																	)}
																</IconButton>
															</InputAdornment>
														),
													},
												},
											}}
										/>
										<AppButton type="submit" btnSize="large" disabled={!isCodeVerified}>
											{t("confirm_password")}
										</AppButton>
										{/* <Button
							className="cancel-button"
							onClick={() => {
								console.log("Back button clicked")
							}}
						>
							{t("cancel")}
						</Button> */}
									</div>
								</form>
							</Box>
						</AuthLayout>
					)}
		</>
	)
}

export default CreatePassword
