import { ReactComponent as BRGLogo } from "./brg_logo.svg"
import { ReactComponent as BRGLogoLarge } from "./brg_logo_large.svg"
import { ReactComponent as DottedBgRect } from "./dotted-bg-rect.svg"
import { ReactComponent as DottedBgSquare } from "./dotted-bg-square.svg"
import { ReactComponent as EyeOutlineSvg } from "./eye-outline.svg"
import { ReactComponent as Deactivate } from "./deactivate.svg"
import { ReactComponent as Sort } from "./sort.svg"
import { ReactComponent as CheckCircle } from "./checkCircle.svg"
import { ReactComponent as WarningIcon } from "./warning_icon.svg"
import ShowPassword from "./show-password.svg"
import { ReactComponent as SidebarLogo } from "./sidebar_logo.svg"
import { ReactComponent as ProjectStatusLogoSvg } from "./project_status_icons.svg"
import { ReactComponent as UploadIcon } from "./upload.svg"
import { ReactComponent as DownloadIcon } from "./download.svg"
import { AppColors } from "../../theme"
import { ReactComponent as ProfileIcon } from "./profile_icon.svg"
import { ReactComponent as PasswordIcon } from "./password_icon.svg"
import { ReactComponent as DashboardSvg } from "./dashboard.svg"
import { ReactComponent as CustomerSvg } from "./customer.svg"
import { ReactComponent as ProjectSvg } from "./project.svg"
import { ReactComponent as LogsSvg } from "./logs.svg"
import { ReactComponent as Logout } from "./logout.svg"
import { ReactComponent as Settings } from "./settings.svg"
import { ReactComponent as FeedBack_Icon } from "./feedback_icon.svg"
import { ReactComponent as View_Icon } from "./view_icon.svg"
import { ReactComponent as BackArrow } from "./back_arrow.svg"
import { ReactComponent as PendingRadioIcon } from "./pending_radio.svg"
import { ReactComponent as Role } from "./role_icon.svg"
import { ReactComponent as RepliedIcon } from "./replied_icon.svg"
import { ReactComponent as ResolvedIcon } from "./resolved_icon.svg"
import { ReactComponent as ActivitySvg } from "./activity_icon.svg"
import { ReactComponent as CloseIcon } from "./close_icon.svg"
import { ReactComponent as EditIcon } from "./edit_icon.svg"
import {ReactComponent as ShowIcon} from './show_arrow.svg'
import {ReactComponent as HideIcon} from './hide_arrow.svg'
import {ReactComponent as ActiveUserIcon} from './active_user_icon.svg'
import {ReactComponent as InactiveUserIcon} from './inactive_user_icon.svg'
import {ReactComponent as ExpandIcon} from './expand.svg'
import {ReactComponent as ConfigSvg} from './config.svg'
import {ReactComponent as EditConfig} from './edit_pencil.svg'
import {ReactComponent as DeleteConfig} from './delete_icon.svg'
import {ReactComponent as RightArrow} from './right_arrow.svg'
import {ReactComponent as ShareIcon} from './share_icon.svg'
const EyeOutlineRed = () => <EyeOutlineSvg stroke={AppColors.error} />
const EyeOutline = () => <EyeOutlineSvg stroke="#D0D0D0" />
const EyeOutlineBlue = () => <EyeOutlineSvg stroke={AppColors.secondary} />

const ProjectStatusLogo = ({
	color = AppColors.brgOrange,
}: {
	color?: string
}) => <ProjectStatusLogoSvg fill={color} />

const Dashboard = ({ color = "white" }: { color?: string }) => (
	<DashboardSvg fill={color} />
)
const Customer = ({ color = "white" }: { color?: string }) => (
	<CustomerSvg fill={color} />
)
const Project = ({ color = "white" }: { color?: string }) => (
	<ProjectSvg fill={color} />
)
const Logs = ({ color = "white" }: { color?: string }) => (
	<LogsSvg fill={color} />
)
const Activity = ({ color = "white" }: { color?: string }) => (
	<ActivitySvg fill={color} />
)

const Config = ({ color = "white" }: { color?: string }) => (
	<ConfigSvg fill={color} />
)

export const Svgs = {
	BRGLogo,
	BRGLogoLarge,
	DottedBgRect,
	DottedBgSquare,
	EyeOutlineRed,
	EyeOutline,
	EyeOutlineBlue,
	ShowPassword,
	Deactivate,
	Sort,
	SidebarLogo,
	ProjectStatusLogo,
	CheckCircle,
	WarningIcon,
	ProfileIcon,
	PasswordIcon,
	UploadIcon,
	DownloadIcon,
	Dashboard,
	Customer,
	Project,
	Logs,
	Logout,
	Settings,
	FeedBack_Icon,
	View_Icon,
	BackArrow,
	PendingRadioIcon,
	Role,
	RepliedIcon,
	ResolvedIcon,
	Activity,
	CloseIcon,
	EditIcon,
	ShowIcon,
	HideIcon,
	ActiveUserIcon,
	InactiveUserIcon,
	ExpandIcon,
	Config,
	EditConfig,
	DeleteConfig,
	RightArrow,
	ShareIcon,
}
