import { useTranslation } from "react-i18next"
import AuthLayout from "../../components/AuthLayout/AuthLayout"
import "./ResetPassword.scss"
import { IconButton, InputAdornment, Typography } from "@mui/material"
import AppFormField from "../../components/AppFormField/AppFormField"
import { useFormik } from "formik"
import {
	initialResetPasswordValues,
	IResetPasswordFields,
	resetPasswordValidationSchema,
} from "./validation"
import { useEffect, useState } from "react"
import { Svgs } from "../../assets/svgs"
import AppButton from "../../components/AppButton/AppButton"
import { useNavigate } from "react-router-dom"
import { routeUrls } from "../../routes/routeUrls"
import {
	useLinkExpiredMutation,
	useResetPasswordMutation,
} from "../../api/network/authApiSercvice"
import { use } from "i18next"
import ConfirmationPopUp from "./ConfirmationPopUp"
import { toast } from "react-toastify"

const ResetPasswordPage = () => {
	const [showPassword, setShowPassword] = useState(false)
	const [showConfirmPassword, setShowConfirmPassword] = useState(false)
	const [isLinkExpired, setIsLinkExpired] = useState(false)
	const [passwordReseting, setPasswordReseting] = useState(false)
	const [loading, setLoading] = useState(true)
	const [LinkExpiredApiTrigger] = useLinkExpiredMutation()
	const [ResetPasswordApiTrigger] = useResetPasswordMutation()
	const { t } = useTranslation("translation", {
		keyPrefix: "resetPassword",
	})
	const { t: expLink } = useTranslation("translation", {
		keyPrefix: "resetPassword.errors",
	})

	const navigate = useNavigate()
	const useParams = new URLSearchParams(window.location.search)
	console.log("useParams", useParams.get("code"))
	console.log("useParams", useParams.get("email"))

	const handleResetPasswordSubmit = (values: IResetPasswordFields) => {
		setPasswordReseting(true)
		const payload = {
			new_password: values.password,
			token: useParams.get("code")!,
			email: useParams.get("email")!,
		}
		ResetPasswordApiTrigger(payload)
			.unwrap()
			.then((res) => {
				// setPasswordResetSuccess(true)
				setPasswordReseting(false)
				toast.success("Password has been changed successfully.")
				navigate(routeUrls.login)
			})
			.catch((err) => {
				setPasswordReseting(false)
				console.log("Error while resetting password", err)
			})
	}

	const { values, errors, touched, handleChange, handleBlur, handleSubmit } =
		useFormik({
			initialValues: initialResetPasswordValues,
			onSubmit: handleResetPasswordSubmit,
			validationSchema: resetPasswordValidationSchema,
		})

	const toggleShowPassword = () => setShowPassword((val) => !val)

	const toggleShowConfirmPassword = () => setShowConfirmPassword((val) => !val)

	const handleCancel = () => {
		navigate(routeUrls.login)
	}
	useEffect(() => {
		LinkExpiredApiTrigger({
			token: useParams.get("code")!,
			email: useParams.get("email") ?? "",
		})
			.unwrap()
			.then((res) => {
				setLoading(false)
			})
			.catch((err) => {
				setIsLinkExpired(true)
				setLoading(false)
			})
	}, [])

	return (
		<>
			{!loading &&
				(isLinkExpired ? (
					<ConfirmationPopUp
						title={expLink("link_expired")}
						description={expLink("link_expired_detail")}
						description2={expLink("forgot_pwd_process")}
						backButtonText={expLink("forgot_password")}
					/>
				) : (
					// ) : passwordResetSuccess ? (
					// 	<ConfirmationPopUp
					// 		title={t("reset_successfull")}
					// 		description={t("success_msg")}
					// 		description2={t("login_instruction")}
					// 		backButtonText={t("proceed_to_login")}
					// 		status="success"
					// 	/>
					<AuthLayout>
						<form
							className="reset-password-form-container"
							onSubmit={handleSubmit}
						>
							<div className="form-title-container">
								<Typography className="title">{t("title")}</Typography>
								<Typography className="description">
									{t("description")}
								</Typography>
								<Typography className="instruction">
									{t("instruction")}
								</Typography>
							</div>
							<div className="form-fields-container">
								<AppFormField
									label={t("password")}
									isRequired
									highlightLabelIfDataIsValid
									name="password"
									placeholder={t("enter_password")}
									type={showPassword ? "text" : "password"}
									value={values.password}
									onChange={handleChange}
									onBlur={handleBlur}
									touched={touched.password}
									errorMsg={errors.password ? t(errors.password) : ""}
									inputProps={{
										slotProps: {
											input: {
												endAdornment: (
													<InputAdornment position="end">
														<IconButton
															aria-label="toggle password visibility"
															onClick={toggleShowPassword}
														>
															{showPassword ? (
																<Svgs.EyeOutlineBlue />
															) : (
																<Svgs.EyeOutline />
															)}
														</IconButton>
													</InputAdornment>
												),
											},
										},
									}}
								/>
								<AppFormField
									label={t("confirm_password")}
									isRequired
									highlightLabelIfDataIsValid
									name="confirmPassword"
									placeholder={t("re_enter_password")}
									type={showConfirmPassword ? "text" : "password"}
									value={values.confirmPassword}
									onChange={handleChange}
									onBlur={handleBlur}
									touched={touched.confirmPassword}
									errorMsg={
										errors.confirmPassword ? t(errors.confirmPassword) : ""
									}
									inputProps={{
										slotProps: {
											input: {
												endAdornment: (
													<InputAdornment position="end">
														<IconButton
															aria-label="toggle password visibility"
															onClick={toggleShowConfirmPassword}
														>
															{showConfirmPassword ? (
																<Svgs.EyeOutlineBlue />
															) : (
																<Svgs.EyeOutline />
															)}
														</IconButton>
													</InputAdornment>
												),
											},
										},
									}}
								/>
							</div>
							<div className="form-button-container">
								<AppButton
									type="submit"
									disabled={passwordReseting}
									btnSize="large"
								>
									{t("confirm_new_pwd")}
								</AppButton>
								<AppButton
									btnSize="large"
									variant="outlined"
									onClick={handleCancel}
									style={{
										border: "none",
									}}
									color="error"
								>
									{t("cancel")}
								</AppButton>
							</div>
						</form>
					</AuthLayout>
				))}
		</>
	)
}

export default ResetPasswordPage
