import "./ErrorBoundary.scss"

const ErrorBoundary = () => {
	return (
		<div className="error-boundary">
			<h1>Oops! Something went wrong.</h1>
			<p>Please try again or go back to the homepage.</p>
			<button onClick={() => (window.location.href = "/")}>Go Home</button>
		</div>
	)
}

export default ErrorBoundary
