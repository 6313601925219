import Button, { ButtonProps } from "@mui/material/Button"
import "./AppButton.scss"

interface IAppButtonProps extends ButtonProps {
	btnSize?: "small" | "medium" | "large"
}

const AppButton = ({
	children,
	variant = "contained",
	className,
	btnSize = "small",
	...props
}: IAppButtonProps) => {
	return (
		<Button
			variant={variant}
			className={`app-button ${btnSize} ${className}`}
			{...props}
		>
			{children}
		</Button>
	)
}

export default AppButton
