import Typography, { TypographyProps } from "@mui/material/Typography"

const TableCellText = (props: TypographyProps = {}) => {
	return (
		<Typography fontSize="inherit" lineHeight="inherit" {...props}>
			{props.children}
		</Typography>
	)
}

export default TableCellText
