import localForage from "localforage"
import { localStorageKeys } from "../constants/constants"
import { LoginResponseSuccess } from "../api/types/Auth"

const localDB = localForage.createInstance({
	driver: localForage.INDEXEDDB,
})

const _setItem = (key: string, value: any) => {
	return localDB.setItem(key, value)
}

const _getItem = <T>(key: string): Promise<T | null> => {
	return localDB.getItem<T | null>(key)
}

export const saveUserData = (data: LoginResponseSuccess) => {
	return _setItem(localStorageKeys.userData, data)
}

export const removeUserData = () => {
	return localDB.removeItem(localStorageKeys.userData)
}

export const getUserData = () => {
	return _getItem<LoginResponseSuccess>(localStorageKeys.userData)
}

export const checkIfUserLoggedIn = async () => {
	try {
		const data = await getUserData()
		if (data?.token) {
			return Promise.resolve({ isLoggedIn: true, user: data })
		}
		return Promise.resolve({ isLoggedIn: false, user: null })
	} catch (err) {
		return Promise.resolve({ isLoggedIn: false, user: null })
	}
}

export const localStorage = {
	saveUserData,
	getUserData,
	removeUserData,
	checkIfUserLoggedIn,
}
