import { Box, Grid2, LinearProgress, Typography } from "@mui/material"
import { Drawer } from "../../components/AppSidebar/AppSidebar"
import SwimlaneCalender from "./SwimlaneCalender"
import SwimlaneLists from "./SwimlaneLists"
import { Svgs } from "../../assets/svgs"
import AppButton from "../../components/AppButton/AppButton"
import { useEffect, useState } from "react"
import BeginSession from "../Session/BeginSession"
import { formatDateWithMonthName } from "../../utils/dateTime"
import { useNavigate, useParams } from "react-router-dom"
import {
	useEndSessionMutation,
	useGetAllProjectsForUserQuery,
	useGetProjectDetailsQuery,
	useGetSessionIdQuery
} from "../../api/network/projectApiService"
import { AddOutlined } from "@mui/icons-material"
import { routeUrls } from "../../routes/routeUrls"
import AppConfirmationModal from "../../components/AppConfirmationModal/AppConfirmationModal"
import { toast } from "react-toastify"
import { getProjectStatusColor } from "../../utils"
import useUserRole from "../../hooks/useUserRole"
import { ProjectUserRole, UserRole } from "../../api/types/Auth"
import { useSelector } from "react-redux"
import { RootReduxState } from "../../api/store/store"

const Swimlane = () => {
	const [openBeginSession, setOpenBeginSession] = useState(false)
	// const [prjId, setPrjId] = useState<any>();
	const [endSessionConfirmation, setEndSessionConfirmation] = useState(false)
	const [editable, setEditable] = useState(false)
	const [hide, setHide] = useState(false)
	const [endSessionTrigger] = useEndSessionMutation()
	const param = useParams()
	const appUserRole = useUserRole()
	const { currentProject } = useSelector(
		(state: RootReduxState) => state.userProjectData,
	)
	const {
		data: projectData,
		isFetching,
		isError,
		error,
		refetch,
		isLoading,
	} = useGetProjectDetailsQuery(param?.id?.toString() || "1")

	const { data: sessionDetails } = useGetSessionIdQuery(projectData?.id);

	const { data: projectsData } = useGetAllProjectsForUserQuery()
	console.log("Project Data : ", projectData);
	const navigate = useNavigate()
	const handleConfirmEndSession = () => {
		setEndSessionConfirmation(false)
		endSessionTrigger(projectData?.active_session)
			.unwrap()
			.then((res) => {
				toast.success("Session Ended Successfully")
				refetch()
			})
			.catch((err) => {
				toast.error("Failed to end session")
			})
	}
	const handleBeginSession = () => {
		setOpenBeginSession(true)
	}
	const handleCloseBeginSession = () => {
		setOpenBeginSession(false)
		refetch()
	}

	const handleEndSession = () => {
		setEndSessionConfirmation(true)
	}

	const navigateToAddActivity = () => {
		navigate(routeUrls.activity.addActivity, {
			state: { project: projectData },
		})
	}

	const handleBackClick = () => {
		console.log("Back Clicked")
		window.history.back()
	}

	useEffect(() => {
		refetch()
	}, [param?.id])

	useEffect(() => {
		if (projectData?.active_session) {
			if (
				appUserRole === UserRole.SuperAdmin ||
				appUserRole === UserRole.CompanyAdmin ||
				projectsData?.results[0]?.role === ProjectUserRole.SuperIntendent
			) {
				setEditable(true)
			}
		}
	}, [appUserRole, projectData, projectsData])

	useEffect(() => {
		if (
			projectsData?.results[0]?.role === ProjectUserRole.TeamMember ||
			projectsData?.results[0]?.role === ProjectUserRole.Viewer
		) {
			setHide(true)
		}
	}, [projectsData])

	return (
		<>
			{endSessionConfirmation && (
				<AppConfirmationModal
					open={true}
					confirmationMessage={"Are you sure you want to end the session?"}
					onConfirm={handleConfirmEndSession}
					onClose={() => setEndSessionConfirmation(false)}
					closeButtonText={"Close"}
					confirmButtonText={"Ok"}
				/>
			)}
			{openBeginSession && (
				<BeginSession
					project={param?.id || 1}
					open={openBeginSession}
					onClose={handleCloseBeginSession}
				/>
			)}
			<Box display={"flex"}>
				<Box>
					<Drawer variant="permanent" open={true}>
						<Box
							display="flex"
							flexDirection="column"
							padding="14px 12px 12px 12px"
						>
							<Box
								display="flex"
								flex={1}
								justifyContent="space-between"
								alignItems={"center"}
							>
								<Svgs.SidebarLogo />
							</Box>
							<Box display={"flex"} gap={2} mt={2}>
								<Typography>
									<Svgs.BackArrow
										// onClick={() => {
										// 	navigate(
										// 		routeUrls.projects.viewAndEdit(String(param?.id || 0)),
										// 	)
										// }}
										onClick={handleBackClick}
									/>
								</Typography>
								<Typography
									style={{
										color: "#fff",
										fontSize: "17px",
										fontWeight: "700",
										marginTop: "8px",
									}}
								>
									{projectData?.name}
								</Typography>
							</Box>
							<Box
								style={{
									color: "#fff",
									fontSize: "14px",
									fontWeight: "700",
								}}
							>
								<Grid2 mb={2}>
									{`Progress`}
									<span style={{ color: "#54AE2A" }}>{` (${80}%)`}</span>{" "}
								</Grid2>
								<LinearProgress
									variant="determinate"
									value={80}
									color="success"
								/>
							</Box>
							<Box ml={-1.5} mt={1}>
								<AppButton
									// onClick={handleProjectStatusMenuOpen}
									variant="text"
									// endIcon={<ExpandMoreOutlined />}
									btnSize="medium"
									sx={{
										color: getProjectStatusColor(projectData?.status || ""),
									}}
									startIcon={
										<Svgs.ProjectStatusLogo
											color={getProjectStatusColor(projectData?.status || "")}
										/>
									}
								>
									{projectData?.status}
								</AppButton>
							</Box>
						</Box>
						<SwimlaneLists
							swimlaneWbsListShowStatus={projectData?.wbs_code_swimlane_enabled}
							swimlaneActivityListShowStatus={
								projectData?.activity_code_swimlane_enabled
							}
							projectId={param?.id || 1}
						/>
					</Drawer>
				</Box>
				<Box flex={1} padding={2}>
					<Box padding={2}>
						<Box>
							<Grid2 display={"flex"} justifyContent={"space-between"} gap={1}>
								<Typography fontWeight={700} fontSize={24}>
									{sessionDetails?.session_name ? sessionDetails?.session_name : ''}
								</Typography>
								{!projectData?.active_session ? (
									<AppButton
										btnSize="medium"
										variant="contained"
										color="success"
										style={{ color: "white" }}
										disabled={hide}
										endIcon={<Svgs.RightArrow />}
										onClick={handleBeginSession}
									>
										{"Begin Session"}
									</AppButton>
								) : (
									<Grid2 display={"flex"} justifyContent={"flex-end"} gap={1}>
										<AppButton
											btnSize="medium"
											endIcon={<AddOutlined />}
											disabled={hide}
											onClick={navigateToAddActivity}
										>
											{"Add Activity"}
										</AppButton>
										<AppButton
											btnSize="medium"
											variant="contained"
											// color="success"
											style={{
												color: "white",
												backgroundColor: hide ? "" : "#F57F32",
											}}
											disabled={hide}
											// endIcon={<Svgs.RightArrow />}
											onClick={handleEndSession}
										>
											{"Complete Session"}
										</AppButton>
									</Grid2>
								)}
							</Grid2>
							<Typography fontWeight={500} fontSize={14}>
								{sessionDetails === undefined || sessionDetails === null ? (
									"Loading..."
								) : sessionDetails.is_active ? (
									`${sessionDetails.session_id}` +
									(sessionDetails.created_at
										? ` ${formatDateWithMonthName(sessionDetails.created_at as string)}`
										: "") +
									(sessionDetails.end_date
										? ` - ${formatDateWithMonthName(sessionDetails.end_date as string)}`
										: "")
								) : (
									"No Active Session"
								)}
							</Typography>
						</Box>
						{/* {projectData?.active_session && (
							<Grid2 display={"flex"} justifyContent={"end"}>
								<Grid2 display={"flex"} gap={4}>
									<AppButton
										btnSize="medium"
										endIcon={<AddOutlined />}
										onClick={navigateToAddActivity}
									>
										{"Add Activity"}
									</AppButton>
									{/* <AppButton
										btnSize="medium"
										type="submit"
										style={{ color: "white", backgroundColor: "#22BCE7" }}
										endIcon={<Svgs.ShareIcon />}
										// disabled={isSavingActivity}
									>
										{"Share to external"}
									</AppButton> 
								</Grid2>
							</Grid2>
						)} */}
					</Box>
					<Box mt={4}>
						<SwimlaneCalender
							swimlaneWbsListShowStatus={projectData?.wbs_code_swimlane_enabled}
							swimlaneActivityListShowStatus={
								projectData?.activity_code_swimlane_enabled
							}
							session={projectData?.active_session}
							projectId={projectData?.id}
							editable={editable}
						/>
					</Box>
				</Box>
			</Box>
		</>
	)
}

export default Swimlane
