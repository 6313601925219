import { Grid2, List, ListItem, Typography } from "@mui/material"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import ExpandLessIcon from "@mui/icons-material/ExpandLess"
import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { updateSwimlaneNav } from "../../api/slices/commonSlice"
import { SwimlaneNavState } from "../../api/types/Project"
import { useSelector } from "react-redux"
import { RootReduxState } from "../../api/store/store"

export default function SwimlaneItem({
	item,
	selectedWbsCode,
	setSelectedWbsCode,
}: any) {
	const [expanded, setExpanded] = useState(false)
	const dispatch = useDispatch()
	const toggleExpand = () => {
		setExpanded(!expanded)
	}

	const handleSelectWbsSwimlane = (selctedData: SwimlaneNavState) => () => {
		dispatch(
			updateSwimlaneNav({
				selected_wbs: item?.id,
				navType: "wbs",
				projectId: item?.projectId,
				selected_activity_code: item?.selected_activity_code,
			}),
		)
	}

	const handleClick = () => {
		setSelectedWbsCode(item.wbs_code)
		console.log("selectedWbsCode", selectedWbsCode, item.wbs_code)
		handleSelectWbsSwimlane(item)()
	}
	const isSelected = selectedWbsCode === item.wbs_code

	return (
		<>
			<Grid2
				display="flex"
				justifyContent="space-between"
				gap={2}
				onClick={handleClick}
				sx={{
					backgroundColor: isSelected ? "#333" : "transparent", // Highlight selected item
					cursor: "pointer",
				}}
			>
				<Grid2 width="100%" display="flex" alignItems="center" gap={2}>
					<ListItem
						component="div"
						onClick={toggleExpand}
						sx={{ pl: Number(item.level) * 2 }}
					>
						<List
							sx={{
								width: "100%",
								textOverflow: "ellipsis",
								whiteSpace: "nowrap",
								overflow: "hidden",
							}}
						>
							<Typography
								color="#fff"
								fontSize={16}
								sx={{
									width: "100%",
									textOverflow: "ellipsis",
									whiteSpace: "nowrap",
									overflow: "hidden",
								}}
							>
								{item.wbs_name}
							</Typography>
						</List>
						{item.children?.length > 0 &&
							(expanded ? (
								<ExpandLessIcon sx={{ color: "#fff" }} />
							) : (
								<ExpandMoreIcon sx={{ color: "#fff" }} />
							))}
					</ListItem>
				</Grid2>
			</Grid2>
			{expanded &&
				item.children.map((child: any) => (
					<SwimlaneItem
						key={child.wbs_code}
						item={child}
						selectedWbsCode={selectedWbsCode}
						setSelectedWbsCode={setSelectedWbsCode}
					/>
				))}
		</>
	)
}
