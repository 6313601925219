import { useEffect, useMemo } from "react"
import { useFormik } from "formik"
import { Modal, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"
import { toast } from "react-toastify"
import AppButton from "../../../components/AppButton/AppButton"
import AppAutocompleteFormField from "../../../components/AppFormField/AppAutocompleteFormField"
import {
	AddTeamMemberInitialValue,
	AddTeamMemberValidationSchema,
	ITeamMember,
} from "./validation"
import {
	useAddMemberToProjectMutation,
	useGetAvailableUsersToAddForProjectQuery,
} from "../../../api/network/projectApiService"
import { useGetLimitedRolesQuery } from "../../../api/network/rolesApiService"
import "./Project.scss"

interface IAddTeamMemberModalProps {
	projectId: number
	open: boolean
	onClose?: () => void
}

const AddTeamMemberModal = ({
	projectId,
	open,
	onClose,
}: IAddTeamMemberModalProps) => {
	const { t } = useTranslation("translation", {
		keyPrefix: "viewAndEditProject",
	})

	// fetch limited roles
	const { data: teamMemberRolesData = [], isFetching: isRolesFetching } =
		useGetLimitedRolesQuery(null)

	// fetch users for project
	const { data: availableUsersData, isFetching: isUsersFetching } =
		useGetAvailableUsersToAddForProjectQuery(projectId.toString())

	const [addTeamMemberTrigger, { isLoading: isAddingTeamMember }] =
		useAddMemberToProjectMutation()

	const usersOptions = useMemo(() => {
		if (!availableUsersData?.results?.length) return []
		return availableUsersData.results.map((user: any) => ({
			id: user.id || 0,
			label: user.name || "",
		}))
	}, [availableUsersData])

	const memberRoles = useMemo(() => {
		return teamMemberRolesData.map((role) => ({
			id: role.id,
			label: role.role,
		}))
	}, [teamMemberRolesData])

	const handleAddMember = (values: ITeamMember) => {
		addTeamMemberTrigger({
			id: projectId.toString(),
			user: values.user.toString(),
			role: values.role.toString(),
		})
			.unwrap()
			.then(() => {
				toast.success(t("team_member_added"))
				onClose?.()
			})
			.catch((err) => {
				console.log("Error while adding team member", err)
				toast.error(t("team_member_adding_error"))
			})
	}

	const {
		values,
		errors,
		touched,
		setFieldValue,
		handleBlur,
		handleSubmit,
		resetForm,
	} = useFormik({
		initialValues: AddTeamMemberInitialValue,
		onSubmit: handleAddMember,
		validationSchema: AddTeamMemberValidationSchema,
	})

	useEffect(() => {
		resetForm()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [open])

	return (
		<Modal open={open} onClose={onClose}>
			<form onSubmit={handleSubmit} className="add-member-modal">
				<div className="modal-heading">
					<Typography className="title">{t("add_team_member")}</Typography>
					<Typography className="subtitle">
						{t("select_team_member_and_role")}
					</Typography>
				</div>
				<div className="modal-fields">
					<AppAutocompleteFormField
						label={t("team_member")}
						name="user"
						placeholder={t("select_team_member")}
						disabled={isUsersFetching}
						options={usersOptions}
						value={values.user}
						onChange={setFieldValue}
						onBlur={handleBlur}
						touched={touched.role}
						errorMsg={errors.user ? t(errors.user) : ""}
					/>
					<AppAutocompleteFormField
						label={t("role")}
						name="role"
						placeholder={t("select_role")}
						disabled={isRolesFetching}
						value={values.role}
						options={memberRoles}
						onChange={setFieldValue}
						onBlur={handleBlur}
						touched={touched.role}
						errorMsg={errors.role ? t(errors.role) : ""}
					/>
				</div>
				<div className="modal-action-btns">
					<AppButton
						disabled={isAddingTeamMember}
						btnSize="medium"
						type="submit"
					>
						{t("add_to_project")}
					</AppButton>
					<AppButton
						btnSize="medium"
						variant="text"
						color="error"
						onClick={onClose}
					>
						{t("cancel")}
					</AppButton>
				</div>
			</form>
		</Modal>
	)
}

export default AddTeamMemberModal
