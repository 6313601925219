import { useMemo } from "react"
import useAuthData from "./useAuthData"
import { parseUserRole } from "../utils/roleAccess"

const useUserRole = () => {
	const authData = useAuthData()
	const userRole = useMemo(() => {
		return parseUserRole(authData)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [authData?.user.user_role])

	return userRole
}

export default useUserRole
