import { UserRole } from "../api/types/Auth"

export const routeUrls = {
	root: "/",
	login: "/login",
	signup: "/signup",
	profileSetup: "/profile-setup",
	forgotPassword: "/forgot-password",
	resetPassword: "/reset-password",
	dashboard: "/dashboard",
	users: {
		list: "/users",
		add: "/add-user",
		profile: (id = ":id") => `/users/profile/${id}`,
	},
	projects: {
		list: "/project",
		create: "/project/create",
		viewAndEdit: (id = ":id") => `/project/${id}`,
		swimlane: (id = ":id", sessionId = ":sessionId") =>
			`/project/${id}/${sessionId}/swimlane`,
		session: (id = ":id") => `/project/${id}/session`,
		sessionDetails: (id = ":id") => `/session/${id}`,
	},
	logs: "/logs",
	activity_detail: "/activity-detail",
	dashboardChild: {
		company: "/company",
		addCompany: "/add-company",
	},
	myCompany: "/my-company",
	swimlane: "/swimlane",
	swimlane_list: "/swimlane-list",
	profile: "/profile",
	feedback_list: "/feedback-list",
	feedback_detail: "/feedback-detail",
	config: {
		config: "/config",
	},
	activity: {
		list: "/activity",
		addActivity: "/add-activity",
		activityDetails: (id = ":id") => `/activity/${id}`,
	},
	subActivity: {
		addSubActivity: "/add-sub-activity",
		list: "/sub-activity",
		viewSubActivityDetails: (id = ":id") => `/sub-activity/${id}`,
	},
}

export const routeUserPermission = {
	[routeUrls.dashboard]: [UserRole.SuperAdmin, UserRole.CompanyAdmin],
	[routeUrls.users.list]: [
		UserRole.SuperAdmin,
		UserRole.CompanyAdmin,
		UserRole.User,
	],
	[routeUrls.users.add]: [UserRole.SuperAdmin, UserRole.CompanyAdmin],
	[routeUrls.users.profile()]: [UserRole.SuperAdmin, UserRole.CompanyAdmin],
	[routeUrls.dashboardChild.company]: [UserRole.SuperAdmin],
	[routeUrls.dashboardChild.company + "/:companyId"]: [UserRole.SuperAdmin],
	[routeUrls.dashboardChild.addCompany]: [UserRole.SuperAdmin],
	[routeUrls.projects.list]: [UserRole.SuperAdmin, UserRole.CompanyAdmin],
	[routeUrls.projects.create]: [UserRole.SuperAdmin, UserRole.CompanyAdmin],
	[routeUrls.projects.viewAndEdit()]: [
		UserRole.SuperAdmin,
		UserRole.CompanyAdmin,
	],
	[routeUrls.logs]: [UserRole.SuperAdmin, UserRole.CompanyAdmin],
	// [routeUrls.profile]: [UserRole.SuperAdmin, UserRole.CompanyAdmin],
	[routeUrls.activity.list]: [UserRole.SuperAdmin, UserRole.CompanyAdmin],
	[routeUrls.subActivity.list]: [UserRole.SuperAdmin, UserRole.CompanyAdmin],
}
