import exp from "constants"
import { ErrorMessage, useFormik } from "formik"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import AuthLayout from "../../components/AuthLayout/AuthLayout"
import { Link, Typography } from "@mui/material"
import "./ForgotPassword.scss"
import App from "../../App"
import AppFormField from "../../components/AppFormField/AppFormField"
import AppButton from "../../components/AppButton/AppButton"
import { routeUrls } from "../../routes/routeUrls"
import {
	forgotFormInitialValues,
	forgotFormValidationSchema,
	IForgotEmailFields,
} from "./validation"
import {
	useForgotPasswordMutation,
	useLoginMutation,
} from "../../api/network/authApiSercvice"
import { useSearchParams } from "react-router-dom"
import { Email } from "@mui/icons-material"
import EmailConfirmation from "./EmailConfirmation"

const ForgotPassword = () => {
	const [searchParams, setSearchParams] = useSearchParams()
	const [emailSent, setEmailSent] = useState(false)
	const [ForgotPasswordApiTrigger] = useForgotPasswordMutation()
	const { t } = useTranslation("translation", { keyPrefix: "forgotPassword" })
	const { t: confirm } = useTranslation("translation", {
		keyPrefix: "forgotPassword.emailConfirmation",
	})
	const handleResetPassword = async (values: IForgotEmailFields) => {
		const { email } = values
		ForgotPasswordApiTrigger({
			email: email,
		})
			.unwrap()
			.then((res) => {
				setEmailSent(true)
			})
			.catch((err) => {
				console.log("Error wihle request forgot password link", err)
			})
	}
	const {
		values,
		errors,
		touched,
		handleChange,
		handleBlur,
		handleSubmit,
		setFieldValue,
	} = useFormik({
		initialValues: forgotFormInitialValues,
		onSubmit: handleResetPassword,
		validationSchema: forgotFormValidationSchema,
	})
	useEffect(() => {
		if (searchParams.get("email")) {
			const email = searchParams.get("email")
			setFieldValue("email", email)
		}
	}, [searchParams])
	return (
		<>
			{!emailSent ? (
				<AuthLayout>
					<div className="forgot-form-container">
						<form onSubmit={handleSubmit}>
							<Typography className="title">{t("title")}</Typography>
							<Typography className="description">
								{t("description")}
							</Typography>
							<div className="fields-container">
								<AppFormField
									name="email"
									label={t("email")}
									value={values.email}
									onChange={handleChange}
									onBlur={handleBlur}
									type="email"
									placeholder={t("enter_email")}
									isRequired
									touched={touched.email}
									errorMsg={errors.email ? t(errors.email) : ""}
								/>
								<AppButton type="submit" btnSize="large">
									{t("button_text")}
								</AppButton>
								<Link
									color="primary"
									href={routeUrls.login}
									variant="body2"
									sx={{
										fontSize: "16px",
										lineHeight: "17.09px",
										fontWeight: "700",
										textAlign: "center",
									}}
									underline="none"
								>
									{t("back_button")}
								</Link>
							</div>
						</form>
					</div>
				</AuthLayout>
			) : (
				<EmailConfirmation
					title={confirm("title")}
					description={confirm("description")}
					backButtonText={confirm("back_button")}
				/>
			)}
		</>
	)
}

export default ForgotPassword
