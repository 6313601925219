import { Autocomplete, TextField } from "@mui/material"
import { AutocompleteOption } from "../AppFormField/type"
import { useMemo } from "react"

interface IAppAutocompleteFilterProps {
	label: string
	placeholder: string
	options: AutocompleteOption[]
	disabled?: boolean
	value: any
	onChange?: (value: any) => any
}

const AppAutocompleteFilter = ({
	options,
	label,
	placeholder,
	value,
	onChange,
	disabled = false,
}: IAppAutocompleteFilterProps) => {
	const handleChange = (_e: any, value: AutocompleteOption) => {
		onChange?.(value?.id)
	}

	const selectedValue: any = useMemo(() => {
		if (!value) return
		else {
			return options?.find((o) => o.id === value)
		}
	}, [value, options])

	return (
		<Autocomplete
			blurOnSelect
			disabled={disabled}
			value={selectedValue}
			onChange={handleChange}
			sx={{
				width: 200,
				"& .MuiOutlinedInput-root": {
					paddingLeft: "3px",
					paddingTop: 0,
					paddingBottom: 0,
					paddingRight: "30px",
				},
			}}
			disablePortal
			options={options}
			renderInput={(params) => (
				<TextField
					{...params}
					label={label}
					placeholder={placeholder}
					color="secondary"
					slotProps={{
						htmlInput: {
							...(params.inputProps || {}),
							style: {
								padding: "6px 15px",
								fontSize: "14px",
								lineHeight: "20px",
							},
						},
						inputLabel: {
							shrink: true,
						},
					}}
				/>
			)}
		/>
	)
}

export default AppAutocompleteFilter
