import DetailNavBar from "../../components/DetailNavBar/DetailNavBar"
import DisplayTable from "../../components/DisplayTable/DisplayTable"
import "./ActivitiesList.scss"
import { useTranslation } from "react-i18next"
import ViewDetailButton from "../../components/DisplayTable/ViewDetailButton"
import { useMemo, useState } from "react"
import TableCellText from "../../components/DisplayTable/TableCellText"
import {
	activityStatusArray,
	getActivityStatusColor,
	getTotalPages,
} from "../../utils"
import useAppPagination from "../../hooks/useAppPagination"
import AppDropdownFilter from "../../components/AppDropdownFilter/AppDropdownFilter"
import SearchInput from "../../components/AppSearchBox/AppSearchBox"
import { useDebounce } from "../../constants/constants"
import { formatDateWithMonthName } from "../../utils/dateTime"
import { useGetAllActivitiesQuery } from "../../api/network/projectApiService"
import { ActivityDetail } from "../../api/types/Project"
import AppButton from "../../components/AppButton/AppButton"
import { AddOutlined } from "@mui/icons-material"
import { routeUrls } from "../../routes/routeUrls"
import { useLocation, useNavigate } from "react-router-dom"

const sampleData = [
	{
		activity_id: 1,
		activity_type: "Type 1",
		planned_start_date: "2021-09-01",
		start_date: "2021-09-01",
		planned_end_date: "2021-09-01",
		end_date: "2021-09-01",
		status: "Planned",
	},
	{
		activity_id: 2,
		activity_type: "Type 2",
		planned_start_date: "2021-09-01",
		start_date: "2021-09-01",
		planned_end_date: "2021-09-01",
		end_date: "2021-09-01",
		status: "In Progress",
	},
	{
		activity_id: 3,
		activity_type: "Type 3",
		planned_start_date: "2021-09-01",
		start_date: "2021-09-01",
		planned_end_date: "2021-09-01",
		end_date: "2021-09-01",
		status: "Completed",
	},
]

const tableHeaders = [
	{ name: "Activity ID", sortable: false },
	{ name: "Activity Type", sortable: false },
	{ name: "Activity Name", sortable: false },
	{ name: "Planned Start Date", sortable: false },
	{ name: "Start Date", sortable: false },
	{ name: "Planned Finish Date", sortable: false },
	{ name: "Finish Date", sortable: false },
	{ name: "Status", sortable: false },
	{ name: "", sortable: false },
]

const ActivitiesList = () => {
	const { t } = useTranslation("translation", { keyPrefix: "activity" })
	const { t: tUsrList } = useTranslation("translation", {
		keyPrefix: "usersList",
	})

	const navigate = useNavigate()
	const location = useLocation()
	console.log("location", location?.state?.projectId)
	const [statusFilter, setStatusFilter] = useState<string>()
	const [searchTerm, setSearchTerm] = useState("")

	const {
		pageRowsCount,
		currentPage,
		handlePageChange,
		handlePageCountChange,
	} = useAppPagination()

	const {
		data: { results: activitiesData = [], count: totalCount } = {},
		isFetching: isFetchingActivities,
	} = useGetAllActivitiesQuery({
		page: currentPage,
		page_size: pageRowsCount,
		project: location?.state?.project?.id || location?.state?.projectId,
		search: searchTerm,
		status: statusFilter,
	},
		{ skip: !location?.state?.project?.id && !location?.state?.projectId },
	)

	const onActivityDetailClick = (id: number) => () => {
		navigate(routeUrls.activity.activityDetails(String(id || 0)))
	}

	const tableColumns = useMemo(
		() => [
			"activity_id",
			"activity_type",
			"activity_name",
			(data: ActivityDetail) =>
				formatDateWithMonthName(data.planned_start_date),
			(data: ActivityDetail) =>
				data.actual_start_date &&
				formatDateWithMonthName(data.actual_start_date),
			(data: ActivityDetail) =>
				formatDateWithMonthName(data.planned_finish_date),
			(data: ActivityDetail) =>
				data.actual_finish_date &&
				formatDateWithMonthName(data.actual_finish_date),
			(data: ActivityDetail) => (
				<TableCellText
					sx={{
						color: getActivityStatusColor(data.activity_status),
					}}
				>
					{data.activity_status}
				</TableCellText>
			),
			(data: ActivityDetail) => {
				return <ViewDetailButton onClick={onActivityDetailClick(data.id)} />
			},
		],
		[],
	)

	const statusOptions = useMemo(() => {
		return activityStatusArray.map((s) => ({
			id: s.status_code,
			label: s.status,
		}))
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const debouncedHandleSearchChange = useDebounce(
		(value: string) => setSearchTerm(value),
		500,
	)

	const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		console.log("Search Term", event.target.value)
		debouncedHandleSearchChange(event.target.value)
	}

	const handleStatusFilterChange = (status: any) => {
		setStatusFilter(status ? status.id : status)
	}

	const navigateToAddActivity = () => {
		navigate(routeUrls.activity.addActivity, { state: { projectId: location?.state?.project?.id } })
	}

	const handleRowClick = (data: any) => {
		console.log("RowData", data)
		navigate(routeUrls.activity.activityDetails(data.id), { state: { activityData: data, session: location?.state?.project?.active_session || location?.state?.session } })
	}

	return (
		<div className="activities-list-container">
			<DetailNavBar
				title={t("activities")}
				showBackButton={false}
			// rightView={
			// 	<AppButton
			// 		btnSize="medium"
			// 		endIcon={<AddOutlined />}
			// 		onClick={navigateToAddActivity}
			// 	>
			// 		{t("add_activity")}
			// 	</AppButton>
			// }
			/>
			<div className="search-and-filters-container">
				<SearchInput
					value={searchTerm}
					onChange={handleSearchChange}
					placeholder={tUsrList("search_here")}
					fullWidth={false}
					height="36px"
				/>
				<AppDropdownFilter
					placeholder={tUsrList("status")}
					value={statusFilter}
					menuItems={statusOptions}
					onChange={handleStatusFilterChange}
				/>
			</div>
			<DisplayTable
				tableHeaders={tableHeaders}
				tableColumns={tableColumns}
				results={activitiesData || []}
				isFetching={isFetchingActivities}
				pageRowsCount={pageRowsCount}
				onPageCountChange={handlePageCountChange}
				totalPages={getTotalPages(totalCount, pageRowsCount)}
				currentPage={currentPage}
				onPageChange={handlePageChange}
				totalPageCount={totalCount}
			/>
		</div>
	)
}

export default ActivitiesList
