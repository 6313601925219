import {
	Checkbox,
	FormControlLabel,
	FormGroup,
	Modal,
	Typography,
} from "@mui/material"
import React, { useState } from "react"
import AppButton from "../../components/AppButton/AppButton"
import { useUpdateProjectMutation, useUpdateSwimlaneSettingsMutation } from "../../api/network/projectApiService";
import { toast } from "react-toastify";

export default function SwimlaneSettings(props: any) {
	const { open, onClose,projectData } = props
    console.log("Project Data#", projectData);
    const [updateProjectApiTrigger]=useUpdateProjectMutation();
    const [updateSwimlaneSettingsTrigger]=useUpdateSwimlaneSettingsMutation();
	// State for checkboxes
	const [settings, setSettings] = useState({
		wbs: projectData?.wbs_code_swimlane_enabled, // Default checked
		activityCode: projectData?.activity_code_swimlane_enabled, // Default unchecked
	})

	// Handle checkbox change
	const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const { name, checked } = event.target
		setSettings((prevSettings) => ({
			...prevSettings,
			[name]: checked,
		}))
	}

	// Handle form submission
	const handleSwimlaneSettings = () => {
		console.log("Swimlane Settings:", settings)
        updateSwimlaneSettingsTrigger({
            id: projectData?.id,
            wbs_code_swimlane_enabled: settings.wbs,
            activity_code_swimlane_enabled: settings.activityCode,
        }).unwrap().then((res)=>{
            console.log("Response",res);
            toast.success("Swimlane settings updated successfully");
            onClose();
        }).catch((err)=>{
            console.log("Error",err);
            toast.error("Failed to update swimlane settings");
        })
	}

	return (
		<Modal open={open} onClose={onClose}>
			<div className="add-member-modal">
				<div className="modal-heading">
					<Typography className="title">{"Swimlane Settings"}</Typography>
					<Typography className="subtitle">{"Swim lane by"}</Typography>
				</div>
				<FormGroup>
					<FormControlLabel
						control={
							<Checkbox
								name="wbs"
								checked={settings.wbs}
								onChange={handleCheckboxChange}
							/>
						}
						label="WBS"
					/>
					<FormControlLabel
						control={
							<Checkbox
								name="activityCode"
								checked={settings.activityCode}
								onChange={handleCheckboxChange}
							/>
						}
						label="Activity Code"
					/>
				</FormGroup>
					<AppButton
						btnSize="medium"
						type="button"
						onClick={handleSwimlaneSettings}
					>
						{"Save"}
					</AppButton>
					<AppButton
						btnSize="medium"
						variant="text"
						color="error"
						onClick={onClose}
						style={{
							fontWeight: "700",
						}}
					>
						{"Cancel"}
					</AppButton>
			</div>
		</Modal>
	)
}
