import { CalendarDaysTypeKeys } from "../../pages/Activity/validation"
import { BasicListingPayload } from "./Listing"
import { TUserResponse } from "./User"

export type TClientsData = {
	client: string
}

export type ClientsResponseSuccess = TClientsData[]

export type ClientsResponse = ClientsResponseSuccess

export type CreateProjectPayload = {
	name: string
	company: number
	description: string
	start_date: string
	end_date: string
	client: string
	budget: string
}

export type UpdateProjectPayload = {
	id: number
	name?: string
	description?: string
	start_date?: string
	end_date?: string
	budget?: string
	status?: string
	wbs_code_swimlane_enabled?: boolean
	activity_code_swimlane_enabled?: boolean
}

export type TProjectMember = {
	user_name: string | null
	user_email: string
	role_name: string
	user_id: number
	role_id: number
}

export type ProjectDetails = {
	id: number
	company_name: string
	project_id: string
	project_members: TProjectMember[]
	name: string
	description: string
	start_date: string
	end_date: string
	client: string
	budget: string
	status: string
	is_active: boolean
	company: number
	active_session: any
	wbs_code_swimlane_enabled: any
	activity_code_swimlane_enabled: any
	calendar?: Calendar[]
	p6_project_id?: string
}

export type Calendar = {
	calendar_id: number
	working_days: string[]
	calendar_name: string
	calendar_type: string
}

export type TAddTeamMemberProjectPayload = {
	id: string
	user: string
	role: string
}

export type GetProjectListPayload = BasicListingPayload & {
	status?: string
	company?: number
}

export type UserProjectDetail = {
	project_name: string
	role: string
	project_id: number
	role_id: number
	active_session: number
}

export type UserProjectSliceState = {
	currentProject: UserProjectDetail | null
}

export type GetAllWbsPayload = {
	project?: number
	page_size?: number
}

export type WBSDetail = {
	id: number
	wbs_code: string
	wbs_name: string
	parent: string
	created_at: string
	project: number
}

export enum ActivityRelationType {
	FS = "FS",
	SS = "SS",
	FF = "FF",
	SF = "SF",
}

export type PredecessorDetail = {
	id: number
	predecessor_id: string
	predecessor_name: string
	relationship_type: ActivityRelationType
	lag_days?: number
}

export type SuccessorData = {
	id: number
	successor_id: string
	successor_name: string
	relationship_type: ActivityRelationType
	lag_days?: number
}

export type SuccessorsDetails = {
	successor: string
	relationship_type: ActivityRelationType
	lag_days?: number
}

export type SubActivityPredecessorDetail = {
	predecessor: number
	relationship_type: ActivityRelationType
	lag_days?: number
}

export type SubActivitySuccessorDetails = {
	successor: any
	relationship_type: ActivityRelationType
	lag_days?: number
}

export type PredecessorDetailCreate = {
	predecessor: number
	relationship_type: ActivityRelationType
	lag_days?: number
}

export type CreateActivityPayload = {
	successors?: {
		successor: number
		relationship_type: ActivityRelationType
		lag_days: number
	}[]
	project: number
	activity_name: string
	activity_type: string
	wbs_code: string
	wbs_name: string
	planned_start_date: string
	planned_finish_date: string
	actual_start_date?: string
	actual_finish_date?: string
	original_duration: number
	at_completion_duration?: number
	assignee?: number[]
	calendar: {
		working_days: string[]
	}
	predecessors?: PredecessorDetailCreate[]
	notes?: string
	trade_responsible?: any
	session: number
}

export type GetAllActivitiesPayload = BasicListingPayload & {
	project?: number
	status?: string
}

export type ActivityDetail = {
	id: number
	activity_id: string
	is_editable: boolean
	p6_activity_id?: any
	activity_status: string
	progress: number
	activity_type: string
	total_float?: number
	wbs_code: string
	wbs_name: string
	activity_name: string
	planned_start_date: string
	planned_finish_date: string
	actual_start_date?: string
	actual_finish_date?: string
	original_duration: number
	at_completion_duration?: number
	trade_responsible?: any
	notes?: string
	resources?: any
	costs?: number
	calendar: {
		working_days: CalendarDaysTypeKeys[]
	}
	last_updated_by_p6: boolean
	project: number
	assignee: TUserResponse[]
	predecessors: PredecessorDetail[]
	successors: SuccessorData[]
	session: any
}

export type SessionDetails = {
	id: number
	session_id: string
	session_name: string
	created_at: string
	ended_at: string
	is_active: boolean
	description: string
	project: number
	updated_at: string
}

export type SubActivityDetails = {
	id: number
	sub_activity_id: string
	sub_activity_status: string
	sub_activity_name: string
	planned_start_date: string
	planned_end_date: string
	actual_start_date?: string
	actual_end_date?: string
	actual_duration: number
	planned_duration: number
	trade: string
	assignee: TUserResponse[]
	notes: string
	dependencies: PredecessorDetail[]
}

export type GetProjectMembersPayload = BasicListingPayload & {
	id: number
}

export type UpdateActivityPayload = {
	id: string
	project?: number
	activity_name?: string
	activity_status?: string
	activity_type?: string
	wbs_code?: string
	wbs_name?: string
	planned_start_date?: string
	planned_finish_date?: string
	actual_start_date?: string
	actual_finish_date?: string
	original_duration?: number
	at_completion_duration?: number
	trade_responsible?: string
	assignee?: number[]
	calendar?: {
		working_days: string[]
	}
	predecessors?: PredecessorDetailCreate[]
	successors?: any
	notes?: string
	resources?: string
	costs?: string
	session?: any
}

export type CreateSubActivityPayload = {
	activity: number
	sub_activity_name: []
	description: string
	planned_start_date: string
	planned_end_date: string
	actual_start_date?: string
	actual_end_date?: string
	actual_duration: number
	planned_duration: number
	trade: string
	assignee: number[]
	notes: string
	dependencies: number[]
}

export type SwimlaneNavState = {
	projectId: number | null
	selected_wbs: string | null
	selected_activity_code: string | null
	navType: "wbs" | "activity" | "both"
}
