import { Box, Grid2, Menu, MenuItem, Typography } from "@mui/material"
import React, { useEffect, useMemo, useState } from "react"
import SearchInput from "../../../components/AppSearchBox/AppSearchBox"
import AppButton from "../../../components/AppButton/AppButton"
import AppNavBar from "../../../components/AppNavBar/AppNavBar"
import { ExpandMoreOutlined } from "@mui/icons-material"
import "./FeedbackList.scss"
import DisplayTable from "../../../components/DisplayTable/DisplayTable"
import { useTranslation } from "react-i18next"
import { getTotalPages } from "../../../utils"
import { useDebounce } from "../../../constants/constants"
import ViewDetailButton from "../../../components/DisplayTable/ViewDetailButton"
import TableCellText from "../../../components/DisplayTable/TableCellText"
import { FeedbackStatus } from "../../../utils"
import { useGetAllFeedbacksQuery } from "../../../api/network/projectApiService"
import useAppPagination from "../../../hooks/useAppPagination"
import { useNavigate } from "react-router-dom"
import { routeUrls } from "../../../routes/routeUrls"
import { formatDateWithMonthName } from "../../../utils/dateTime"
import AppDropdownFilter from "../../../components/AppDropdownFilter/AppDropdownFilter"
import useCompaniesListOption from "../../../hooks/useCompaniesListOption"
export default function FeedbackList() {
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
	const [searchTerm, setSearchTerm] = useState("")
	const [companyFilter, setCompanyFilter] = useState<number>()
	const [statusFilter, setStatusFilter] = useState<string>()
	const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("")
	const [feedbackList, setFeedbackList] = useState<any>([]);
	const [showAllFeedbacks, setShowAllFeedbacks] = useState<boolean>(true)
	const open = Boolean(anchorEl)
	const { t } = useTranslation("translation", { keyPrefix: "feedback" })
	const navigate = useNavigate()

	const { isFetching: isCompaniesFetching, companiesOptions } =
		useCompaniesListOption()

	const {
		pageRowsCount,
		currentPage,
		handlePageChange,
		handlePageCountChange,
	} = useAppPagination()

	const [queryArgs, setQueryArgs] = useState({
		page_size: pageRowsCount,
		page: currentPage
	})

	const { data, isFetching, refetch } = useGetAllFeedbacksQuery({
		page_size: pageRowsCount,
		page: currentPage,
		search: searchTerm,
		company: companyFilter,
		status: statusFilter?.toLowerCase(),
	})

	const results = data?.results ?? []

	const feedbackStatusOptions = useMemo(() => {
		return [
			{ id: "1", label: 'Resolved' },
			{ id: "2", label: 'Pending' },
			{ id: "3", label: 'Replied' },
		]
	}, [])

	const handleRowClick = (data: any) => {
		console.log("RowData", data)
		navigate(routeUrls.feedback_detail, { state: { userData: data } })
	}

	const tableHeaders = useMemo(
		() => [
			{ name: "User Name", sortable: false },
			{ name: "Company", sortable: false },
			{ name: "Email", sortable: false },
			{ name: "Type", sortable: false },
			{ name: "Submission Date", sortable: false },
			{ name: "Status", sortable: false },
		],
		[],
	)
	const columns = useMemo(
		() => [
			"user_name",
			"company_name",
			"user_email",
			"feedback_type",
			(data: any) =>
				formatDateWithMonthName(data.created_at),
			(data: any) => (
				<TableCellText
					sx={{
						color: FeedbackStatus.find(
							(statusObj) => statusObj.status === data.status,
						)?.color,
					}}
				>
					{data.status}
				</TableCellText>
			),
			() => <ViewDetailButton />,
		],
		[],
	)

	const handleClickLabel = (label: string) => {
		if (label === "All") {
			setFeedbackList(results);
			setShowAllFeedbacks(true);
			setQueryArgs({
				...queryArgs,
				page_size: 10,
				page: 1,
			})
		}
		else if (["Suggestion", "Feedback", "Issue"].includes(label)) {
			const filteredList = results.filter(
				(feedback: any) => feedback.feedback_type === label
			);
			setFeedbackList(filteredList);
			setShowAllFeedbacks(false);
		}
	};


	const debouncedHandleSearchChange = useDebounce(
		(value: string) => setSearchTerm(value),
		500,
	)

	const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		console.log("Search Term", event.target.value)
		debouncedHandleSearchChange(event.target.value)
	}

	const handleCompanyFilterChange = (company: any) => {
		setCompanyFilter(company ? company.id : company)
	}

	const handleFeedbackStatusFilterChange = (status: any) => {
		setStatusFilter(status ? status.label : status)
	}

	useEffect(() => {
		setDebouncedSearchTerm(searchTerm)
	}, [searchTerm])

	useEffect(() => {
		refetch()
	}, [statusFilter, refetch])

	// Filtering results based on debounced search term
	// const filteredResults = filterData(data?.results, debouncedSearchTerm)

	return (
		<Box width={"100%"} className={"feedback-list-container"}>
			<Box display={"flex"} marginTop={2} marginLeft={2} marginRight={2}>
				<Grid2>
					<Typography variant={"h4"} marginBottom={2}>
						{t("title")}
					</Typography>
					<Box display={"flex"} justifyContent={"space-between"} gap={3}>
						<Grid2 width={"50%"}>
							<SearchInput
								value={searchTerm}
								onChange={handleSearchChange}
								placeholder="Search by user name"
							/>
						</Grid2>
						<div style={{ marginTop: '8px' }}>
							<AppDropdownFilter
								placeholder={"Company"}
								value={companyFilter}
								menuItems={companiesOptions}
								onChange={handleCompanyFilterChange}
								customStyle={{ width: 'max-content' }}
							/>
						</div>
						<div style={{ marginTop: '8px' }}>
							<AppDropdownFilter
								placeholder={"Status"}
								value={statusFilter}
								menuItems={feedbackStatusOptions}
								onChange={handleFeedbackStatusFilterChange}
							/>
						</div>

						<Box width={"100%"} marginBottom={1}>
							<AppNavBar
								options={["All", "Feedback", "Suggestion", "Issue"]}
								handleClickLabel={handleClickLabel}
								defaultSelected={"All"}
								marginBottom={false}
							/>
						</Box>
					</Box>
					<Grid2>
						<DisplayTable
							isFetching={isFetching}
							tableHeaders={tableHeaders}
							results={showAllFeedbacks ? results : feedbackList}
							tableColumns={columns}
							pageRowsCount={pageRowsCount}
							onPageCountChange={handlePageCountChange}
							totalPages={getTotalPages(data?.count, data?.page_size)}
							currentPage={currentPage}
							onPageChange={handlePageChange}
							onRowClick={handleRowClick}
							totalPageCount={data?.count}
						/>
					</Grid2>
				</Grid2>
			</Box>
		</Box>
	)
}
