export type LoginResponseSuccess = {
	token: string
	user: {
		id: number
		email: string
		name: string
		user_role: TUserRole
		is_active: boolean
		is_account_locked: boolean
		is_profile_setup: boolean
		company_name: string | null
		company_id: number | null
		company_logo: string | null
	}
	attempts_left: number
	is_account_locked: boolean
}

export enum UserRole {
	SuperAdmin = "Super Admin",
	CompanyAdmin = "Company Admin",
	User = "User",
}

export enum ProjectUserRole {
	SuperIntendent = "Project Superintendent",
	TeamMember = "Team Member",
	Viewer = "Viewer",
}

export type TUserRole = {
	id: number
	role: UserRole
}

export type LoginResponseError = {
	error: string
	attempts_left: number
	is_account_locked: boolean
}

export type ForgotPasswordResponseSuccess = {
	success: string
}

export type ForgotPasswordResponseError = {
	error: string
}

export type LinkExpiredResponseSuccess = {
	success: string
}

export type LinkExpiredResponseError = {
	error: string
}

export type ResetPasswordResponseSuccess = {
	success: string
}
export type ResetPasswordResponseError = {
	error: string
}

export type ResetProfileSetupResponseSuccess = {
	success: string
}
export type ResetProfileSetupResponseError = {
	error: string
}

export type ProfileDataResponseSuccess = {
	name: string
	email: string
	phone_number: string
	company: string
	office_phone_number?: string
	onsite_emergency_contact: string
	offsite_emergency_contact_number?: string
	job_title: string
	country_code_phone_number: string
	country_code_office_phone_number: string
	country_code_onsite_emergency_contact: string
	country_code_offsite_emergency_contact_number: string
	user: 39
	is_active: boolean
}

export type ProfileDataResponseError = {
	error: string
}

export type ResendInvitationResponseSuccess = {
	success: string
}

export type ResendInvitationResponseError = {
	error: string
}

export type LoginResponse = LoginResponseSuccess | LoginResponseError
export type ForgotPasswordResponse =
	| ForgotPasswordResponseSuccess
	| ForgotPasswordResponseError
export type LinkExpiredResponse =
	| LinkExpiredResponseSuccess
	| LinkExpiredResponseError
export type ResetPasswordResponse =
	| ResetPasswordResponseSuccess
	| ResetPasswordResponseError

export type ProfileSetUpResponse =
	| ResetProfileSetupResponseSuccess
	| ResetProfileSetupResponseError
export type ProfileResponse =
	| ProfileDataResponseSuccess
	| ProfileDataResponseError

export type ResendInvitationResponse =
	| ResendInvitationResponseSuccess
	| ResendInvitationResponseError

export type LoginPayload = {
	email: string
	password: string
}

export type VerifySignupCodePayload = {
	email: string
	token: string
}

export type SignupPayload = {
	email: string
	password: string
}
export type ForgotPasswordPayload = {
	email: string
}

export type LinkExpiredPayload = {
	email: string
	token: string
}

export type ResetPasswordPayload = {
	email: string
	token: string
	new_password: string
}

export type ResendInvitationPayload = {
	email: string
}

export type ProfilePayload = {
	name: string
	email: string
	phone_number: string
	// jobTitle: string
	company: string
	office_phone_number?: string
	onsite_emergency_contact: string
	offsite_emergency_contact_number?: string
}
