import { Box, Grid2, Typography } from "@mui/material"
import "./Setting.scss"
import { useEffect, useState } from "react"
import { Svgs } from "../../assets/svgs"
import Feedback from "../Feedback/Feedback"
import UserProfile from "../UserProfile/UserProfile"
import { localStorage } from "../../utils/localstorage"

const Setting = () => {
	const [optionHandle, setOptionHandle] = useState("profile")
	const [userRole,setUserRole] = useState("")

	const fetchUserData = async () => {
		try {
			const userData = await localStorage.getUserData()
			console.log("userData", userData)
			if (userData) {
				setUserRole(userData.user.user_role.role)
				console.log("userData", userData.user.user_role.role)
			} else {
				console.log("No user data found.")
			}
		} catch (error) {
			console.error("Error fetching user data:", error)
		}
	}

	useEffect(() => {
		fetchUserData()
	},[])

	const handleOption = (option: string) => {
		setOptionHandle(option)
	}
	return (
		<Box className="profile-user-container" display={"flex"}>
			<Box paddingLeft={2}>
				<Box>
					<Typography className="title">Setting</Typography>
					<Box display={"flex"} justifyContent={"space-between"}>
						<Grid2
							width={"auto"}
							height={"50%"}
							minWidth={"260px"}
							maxWidth={"260px"}
							sx={{
								bgcolor: "white",
								border: "2px solid #fff",
								borderRadius: 4,
								boxShadow: 4,
								padding: 4,
								marginTop: 4,
							}}
							// gap={-5}
							marginRight={'-40px'}
						>
							<Grid2 width={"100%"}>
								<Grid2
									display={"flex"}
									whiteSpace={"nowrap"}
									gap={1}
									style={{
										background: optionHandle == "profile" ? "#22BCE71F" : "",
										cursor: "pointer",
										marginBottom: 5,
									}}
									onClick={() => handleOption("profile")}
								>
									<Svgs.ProfileIcon />
									<Typography>Profile</Typography>
								</Grid2>

								{/* <Grid2
									display={"flex"}
									whiteSpace={"nowrap"}
									gap={1}
									style={{
										background:
											optionHandle == "change_password" ? "#22BCE71F" : "",
										cursor: "pointer",
										marginBottom: 2,
									}}
									onClick={() => handleOption("change_password")}
								>
									<Svgs.PasswordIcon />
									<Typography>Change Password</Typography>
								</Grid2> */}
								{userRole !='Super Admin' &&
									<Grid2
									display={"flex"}
									whiteSpace={"nowrap"}
									gap={1}
									style={{
										background: optionHandle == "feedback" ? "#22BCE71F" : "",
										cursor: "pointer",
										whiteSpace: "nowrap",
									}}
									onClick={() => handleOption("feedback")}
								>
									<Svgs.FeedBack_Icon style={{ marginTop: "5px" }} />
									<Typography>Feedback/Suggestion</Typography>
								</Grid2>}
							</Grid2>
						</Grid2>
						{optionHandle === "feedback" && <Feedback />}
						{optionHandle === "profile" && <UserProfile />}
						{/* {optionHandle === "change_password" && (
							<Box>
								<Typography>Change Password</Typography>
							</Box>
						)} */}
					</Box>
				</Box>
			</Box>
		</Box>
	)
}

export default Setting
