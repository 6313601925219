import { configureStore } from "@reduxjs/toolkit"
import { setupListeners } from "@reduxjs/toolkit/query"
import { AuthAPIs } from "../network/authApiSercvice"
import { CompanyAPIs } from "../network/companyApiService"
import { UserAPIs } from "../network/userApiService"
import { ProjectAPIs } from "../network/projectApiService"
import { RolesAPIs } from "../network/rolesApiService"
import authSlice from "../slices/AuthSlice"
import UserProjectSlice from "../slices/UserProjectSlice"
import { XerAPIs } from "../network/xerApiService"
import { CommonSlice } from "../slices/commonSlice"

// ...

export const store = configureStore({
	reducer: {
		[AuthAPIs.reducerPath]: AuthAPIs.reducer,
		[CompanyAPIs.reducerPath]: CompanyAPIs.reducer,
		[UserAPIs.reducerPath]: UserAPIs.reducer,
		[ProjectAPIs.reducerPath]: ProjectAPIs.reducer,
		[RolesAPIs.reducerPath]: RolesAPIs.reducer,
		[XerAPIs.reducerPath]: XerAPIs.reducer,
		[authSlice.reducerPath]: authSlice.reducer,
		[UserProjectSlice.reducerPath]: UserProjectSlice.reducer,
		[CommonSlice.reducerPath]: CommonSlice.reducer,
	},


	// Adding the api middleware enables caching, invalidation, polling,
	// and other useful features of `rtk-query`.
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({ serializableCheck: false }).concat(
			AuthAPIs.middleware,
			CompanyAPIs.middleware,
			UserAPIs.middleware,
			ProjectAPIs.middleware,
			RolesAPIs.middleware,
			XerAPIs.middleware,
		),
})

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch)

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootReduxState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
