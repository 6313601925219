import { Typography } from "@mui/material"
import { Svgs } from "../../assets/svgs"
import { useTranslation } from "react-i18next"

const DashboardWelcomeContent = () => {
	const { t } = useTranslation("translation", { keyPrefix: "dashboard" })
	const { t: tAuth } = useTranslation("translation", {
		keyPrefix: "authLayout",
	})

	return (
		<div className="welcome-content-container">
			<div className="welcome-content">
				<Typography
					fontSize="72px"
					fontWeight="400"
					lineHeight="85.9px"
					color="primary"
					mb="12px"
				>
					{t("welcome_to")}
				</Typography>
				<Svgs.BRGLogoLarge />
				<div className="brand-name-wrapper">
					<Typography className="brand-name" variant="h2">
						{tAuth("app_name_pull")}
					</Typography>
					<div className="brand-name-filled">
						<Typography>{tAuth("app_name_pro")}</Typography>
					</div>
				</div>
				<div>
					<Typography className="description">
						{tAuth("app_description")}
					</Typography>
				</div>
			</div>
		</div>
	)
}

export default DashboardWelcomeContent
