import { Grid2, Typography } from "@mui/material"
import AuthLayout from "../../components/AuthLayout/AuthLayout"
import { Svgs } from "../../assets/svgs"
import { routeUrls } from "../../routes/routeUrls"
import AppButton from "../../components/AppButton/AppButton"
import { useNavigate } from "react-router-dom"

const EmailConfirmation = (props: any) => {
	const { title, description, backButtonText } = props
	const nav = useNavigate()
	return (
		<AuthLayout>
			<div className="forgot-form-container">
				<Grid2 display={"flex"} justifyContent={"left"}>
					<div className="logo-wrapper">
						<Svgs.CheckCircle />
					</div>
					<Typography className="title"> {title} </Typography>
				</Grid2>
				<Typography className="description"> {description} </Typography>
				<AppButton btnSize="large" onClick={() => nav(routeUrls.login)}>
					{backButtonText}
				</AppButton>
			</div>
		</AuthLayout>
	)
}
export default EmailConfirmation
