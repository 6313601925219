import { Box, Grid2, Typography } from "@mui/material"
import React, { FC } from "react"
import { useTranslation } from "react-i18next"
import "./AppFileField.scss"
import AppButton from "../AppButton/AppButton"

interface AppFileFieldProps {
	id: string
	label: string
	isRequired?: boolean
	onChange?: (file: File) => void
	value?: File
	fileName?: string
	error?: string
	allowedFields?: string[]
	disabled?: boolean
	showErrorValue?: boolean
	customInputClass?: string
}

export const AppFileField: FC<AppFileFieldProps> = ({
	id,
	label,
	isRequired = false,
	onChange,
	fileName,
	error,
	disabled = false,
	showErrorValue = true,
}) => {
	const { t } = useTranslation("translation", { keyPrefix: "file_upload" })
	const fieldHasError = !!error
	const fileInputRef = React.useRef<HTMLInputElement>(null)

	const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (e.target.files) {
			if (onChange) {
				onChange(e.target.files[0])
			}
		}
	}

	return (
		<div>
			<Grid2 container spacing={2} alignItems="flex-end">
				<Grid2 size={8}>
					<Box>
						<Typography className={`field-label`}>
							{label}
							{isRequired && " *"}
						</Typography>
						<Typography color="#D0D0D0" sx={{ fontSize: "14px" }}>
							{fileName ? fileName : t("no_file")}
						</Typography>
					</Box>
				</Grid2>
				<Grid2 size={4}>
					<div className="input-wrapper">
						<input
							ref={fileInputRef}
							className="input"
							id={id}
							name={id}
							type="file"
							accept=".jpg,.jpeg,.png,"
							onChange={handleFileChange}
						/>
						<AppButton
							variant="outlined"
							sx={{ alignSelf: "flex-end",whiteSpace: "nowrap" }}
							disabled={disabled}
							onClick={() => fileInputRef.current?.click()}
						>
							{t("choose_file")}
						</AppButton>
					</div>
				</Grid2>
			</Grid2>
			{fieldHasError && showErrorValue && (
				<Typography className="error" sx={{ fontSize: "12px" }}>
					{error}
				</Typography>
			)}
		</div>
	)
}
