import * as Yup from "yup"
export interface IFeedback {
	type: string | number
	message: string | number
}
export const AddFeedbackInitialValue: IFeedback = {
	type: "",
	message: "",
}

export const AddFeedbackValidationSchema = Yup.object().shape({
    type: Yup.string().required("errors.type_required"),
    message: Yup.string().required("errors.message_required"),
})