import { Box, Grid2, Typography } from "@mui/material"
import { Svgs } from "../../../assets/svgs"
import "./FeedbackDetails.scss"
import AppButton from "../../../components/AppButton/AppButton"
import AppFormField from "../../../components/AppFormField/AppFormField"
import { useTranslation } from "react-i18next"
import { useLocation, useNavigate } from "react-router-dom"
import { routeUrls } from "../../../routes/routeUrls"
import FeedbackReply from "../ReplyFeedback/FeedbackReply"
import { getDateFromTimestamp } from "../../../utils/dateTime"
import { useResolvedFeedbackMutation } from "../../../api/network/projectApiService"
import { toast } from "react-toastify"


export default function FeedbackDetails() {
	const { t } = useTranslation("translation", { keyPrefix: "feedback" })
	const nav=useNavigate()
	const location=useLocation()
	const {userData}=location.state || {}
	const [markedResolvedFeedbackTrigger]=useResolvedFeedbackMutation()
	const handleMarkedResolveFeedback=()=>{
		markedResolvedFeedbackTrigger({id:userData?.id})
		.unwrap()
		.then((response)=>{
			toast.success("Feedback resolved successfully")
			nav(-1);
		})
		.catch((error)=>{
			toast.error(error)
		})
	}
	const handleBack = () => {
		nav(routeUrls.feedback_list);
	}

	return (
		<Box margin={2} className="feedback-details-container" width={'100%'}>
			<Box>
			<Box display={"flex"} justifyContent={"space-between"}>
				<Grid2 gap={2} display={"flex"} justifyContent={"space-between"}>
					<Typography style={{cursor:'pointer'}} onClick={handleBack}>
						<Svgs.BackArrow />
					</Typography>
					<Typography className="title"> Details </Typography>
				</Grid2>
				{/* {
					userData?.status !== 'Resolved' && */}
					{/* <Grid2 gap={2} display={"flex"} justifyContent={"space-between"}> */}
					{userData?.status === 'Pending' &&
						<Grid2 gap={2} display={"flex"} justifyContent={"space-between"} mt={1}>
						<Typography className="title2" style={{ color: "#F57F32" }}>	
						<Svgs.PendingRadioIcon style={{ marginBottom: "-5px" }} />
						{userData?.status}
					</Typography>
					<AppButton
						color="success"
						sx={{ color: "white" }}
						onClick={handleMarkedResolveFeedback}
					>
						Marked as Resolved
					</AppButton>
					</Grid2>
					}
						{
						userData?.status === 'Resolved' &&
						<Grid2 gap={2} display={"flex"} justifyContent={"space-between"} mt={1}>
						<Typography className="title2" style={{ color: "#54AE2A" }}>	
						<Svgs.ResolvedIcon style={{ marginBottom: "-5px" }} />
						{userData?.status}
					</Typography>
					</Grid2>
					}
						{
						userData?.status === 'Replied' &&
						<Grid2 gap={2} display={"flex"} justifyContent={"space-between"} mt={1}>
						<Typography className="title2" style={{ color: "#00A79D" }}>	
						<Svgs.RepliedIcon style={{ marginBottom: "-5px" }} />
						{userData?.status}
					</Typography>
					<AppButton
						color="success"
						sx={{ color: "white" }}
						onClick={handleMarkedResolveFeedback}
					>
						Marked as Resolved
					</AppButton>
					</Grid2>
					}
				
				{/* </Grid2> */}
				{/* } */}
			</Box>
			<Box display={"flex"} justifyContent={'center'} gap={16} className="title2">
				<Box
					sx={{
						bgcolor: "white",
						border: "2px solid #fff",
						borderRadius: 8,
						boxShadow: 4,
						padding: 3,
						marginTop: 4,
					}}
					width={"90%"}

				>
					<Grid2 display={"flex"} gap={3}>
						<Grid2 width={'100%'}>
							{t("user_name")}
							<AppFormField
								label={""}
								name={"name"}
								placeholder={"User Name"}
								value={userData?.user_name}
								disabled={true}
							/>
						</Grid2>
						<Grid2 width={'100%'}>
							{t("company")}
							<AppFormField
								label={""}
								name={"company"}
								placeholder={"Company Name"}
								value={userData?.company_name}
								disabled={true}
							/>
						</Grid2>
					</Grid2>
					<Grid2>
						{t("email")}
						<AppFormField
							label={""}
							name={"email"}
							placeholder={"Email"}
							value={userData?.user_email}
							disabled={true}
						/>
					</Grid2>
					<Grid2 display={"flex"} gap={3}>
						<Grid2 width={'100%'}>
							{t("type")}
							<AppFormField
								label={""}
								name={userData?.type}
								placeholder={"Feedback Type"}
								value={"feedback_type"}
								disabled={true}
							/>
						</Grid2>

						<Grid2 width={'100%'}>
							{t("submission_date")}
							<AppFormField
								label={""}
								name={"date"}
								placeholder={"Submission Date"}
								value={getDateFromTimestamp(userData?.created_at)}
								disabled={true}
							/>
						</Grid2>
					</Grid2>
					<Grid2>
						{t("message")}
						<AppFormField
							label={""}
							name={"message"}
							placeholder={"Description"}
							disabled={true}
							value={userData?.description}
							inputProps={{
								multiline: true,
								rows: 3,
							}}
						/>
					</Grid2>
				</Box>
				{
					(userData?.status === 'Pending') &&	<Box
					width={"40%"}
					height={"100%"}
					sx={{
						bgcolor: "white",
						border: "2px solid #fff",
						borderRadius: 8,
						boxShadow: 4,
						padding: 3,
						marginTop: 4,
					}}
				>
					<FeedbackReply id={userData?.id}/>
				</Box>}
			</Box>
			</Box>
		</Box>
	)
}
