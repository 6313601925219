import { Grid2, Typography } from "@mui/material"
import AuthLayout from "../../components/AuthLayout/AuthLayout"
import { Svgs } from "../../assets/svgs"
import { routeUrls } from "../../routes/routeUrls"
import AppButton from "../../components/AppButton/AppButton"
import { useNavigate } from "react-router-dom"

const ConfirmationPopUp = (props: any) => {
	const { title, description, description2, backButtonText, status,email } = props
	const nav = useNavigate()
	return (
		<AuthLayout>
			<div className="forgot-form-container">
				<Grid2 display={"flex"} justifyContent={"left"}>
					<div
						className="logo-wrapper"
						style={{ marginTop: "5px", marginRight: "5px" }}
					>
						{status === "success" ? <Svgs.CheckCircle /> : <Svgs.WarningIcon />}
					</div>
					<Typography className="title" style={{fontSize:'22px',fontFamily:'DIN bold'}}> {title} </Typography>
				</Grid2>
				<Typography className="description"> {description} </Typography>
				<Typography className="description"> {description2} </Typography>

				<AppButton
					btnSize="large"
					onClick={
						status === "success"
							? () => nav(routeUrls.login, { state: { email: email } })
							: () => nav(routeUrls.forgotPassword)
					}
				>
					{backButtonText}
				</AppButton>
			</div>
		</AuthLayout>
	)
}
export default ConfirmationPopUp
