import { Box, Grid2 } from "@mui/material";
import DetailNavBar from "../../components/DetailNavBar/DetailNavBar";
import AppButton from "../../components/AppButton/AppButton";
import { Svgs } from "../../assets/svgs";
import AppFormField from "../../components/AppFormField/AppFormField";
import { useGetAllConfigurationsQuery, useUpdateConfigurationMutation } from "../../api/network/projectApiService";
import { useState } from "react";
import { toast } from "react-toastify";
import './Config.scss'
import { text } from "stream/consumers";

export default function Config() {
  const { data, isFetching,refetch } = useGetAllConfigurationsQuery({});
  const [updateConfigTrigger] = useUpdateConfigurationMutation();
  const [editConfig, setEditConfig] = useState<string | null>(null);
  const [updatedConfig, setUpdatedConfig] = useState<any>({});

  const handleConfigEdit = (id: string) => {
    setEditConfig(id);
    const configToEdit = data?.find((config: any) => config.id === id);
    setUpdatedConfig(configToEdit || {});
  };

  const handleSaveConfig = async (id: string) => {
    try {
      const response = await updateConfigTrigger({
        id,
        ...updatedConfig,
      }).unwrap();
      console.log("Updated Config Response", response);
      setEditConfig(null); // Exit edit mode
      refetch();
    } catch (error:any) {
        console.log("#",error?.data?.value[0])
        toast.error(error?.data?.value[0] || "Failed to update configuration");
      console.error("Error updating configuration:", error);
    }
  };

  const handleInputChange = (field: string, value: string) => {
    setUpdatedConfig((prev: any) => ({
      ...prev,
      [field]: value,
    }));
  };

  return (
    <Box width={"100%"} marginLeft={2}>
      <DetailNavBar title="Configuration" showBackButton={false} />
      <Box   sx={{
        bgcolor: "white",
        border: "2px solid #fff",
        borderRadius: 8,
        boxShadow: 4,
        padding: 3,
        margin: 2,
        width: "90%",
    }}>
      <Box display={"flex"} gap={1} ml={1} className="config-container">
        <Grid2 width={"100%"} className={'title'}>{"Configuration Name"}</Grid2>
        <Grid2 width={"10%"} className={'title'}>{"Value"}</Grid2>
        <Grid2 ml={4} width={"20%"} className={'title'}>
          {"Action"}
        </Grid2>
      </Box>
      {data?.map((config: any) => (
        <Box key={config?.id}>
          <Grid2 display={"flex"} gap={1} ml={1}>
            <Grid2 width={"100%"}>
              <AppFormField
                label=""
                name="configName"
                placeholder="Enter Configuration Name"
                value={editConfig === config?.id ? updatedConfig?.readable_name : config?.readable_name}
                onChange={(e) => handleInputChange("readable_name", e.target.value)}
                // disabled={editConfig !== config?.id}
                disabled={true}
              />
            </Grid2>
            <Grid2 width={"10%"}>
              <AppFormField
                label=""
                name="configValue"
                placeholder="Value"
                value={editConfig === config?.id ? updatedConfig?.value : config?.value}
                onChange={(e) => handleInputChange("value", e.target.value)}
                disabled={editConfig !== config?.id}
              />
            </Grid2>
            <Grid2 ml={4} mt={2} width={"20%"}>
              {editConfig === config?.id ? (
                <AppButton
                  btnSize="small"
                  style={{ cursor: "pointer",background:'#54AE2A',marginLeft: "-10px" }}
                  onClick={() => handleSaveConfig(config?.id)}
                  disabled={isFetching}
                >
                  {"Save"}
                </AppButton>
              ) : (
                <Grid2 display={"flex"} gap={2}>
                  <Grid2
                    style={{ cursor: "pointer", marginLeft: "10px" }}
                    onClick={() => handleConfigEdit(config?.id)}
                  >
                    <Svgs.EditConfig />
                  </Grid2>
                  <Grid2
                    style={{ cursor: "pointer" }}
                    onClick={() => console.log("Delete Config", config?.id)}
                  >
                    {/* <Svgs.DeleteConfig /> */}
                  </Grid2>
                </Grid2>
              )}
            </Grid2>
          </Grid2>
        </Box>
      ))}
      </Box>
    </Box>
  );
}
