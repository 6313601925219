import React, {
	ReactElement,
	useLayoutEffect,
	useState,
} from "react"
import { Navigate, Outlet } from "react-router-dom"
import { localStorage } from "../utils/localstorage"
import { routeUrls } from "./routeUrls"
import { useDispatch } from "react-redux"
import { updateAuthData } from "../api/slices/AuthSlice"
import { hasAccessToPage } from "../utils/roleAccess"

const ProtectedRoute = () => {
	const [isChecking, setIsChecking] = useState(true)
	const [isAuthenticated, setIsAuthenticated] = useState(false)

	const dispatch = useDispatch()

	useLayoutEffect(() => {
		localStorage.checkIfUserLoggedIn().then((data) => {
			const _isLoggedIn = data.isLoggedIn
			if (_isLoggedIn && data.user) {
				dispatch(updateAuthData(data.user))
			}
			setIsAuthenticated(_isLoggedIn)
			setIsChecking(false)
		})
	}, [])

	if (isChecking) return null

	if (!isAuthenticated) {
		return <Navigate to={routeUrls.login} replace />
	}

	return <Outlet />
}

export const AuthorizePage: React.FC<{
	path: string
	fallbackPath?: string
	element: ReactElement
}> = ({
	path,
	fallbackPath = routeUrls.dashboard,
	element,
}): ReactElement => {
	const [isChecking, setIsChecking] = useState(true)
	const [isAuthorized, setIsAuthorized] = useState(false)

	useLayoutEffect(() => {
		localStorage.checkIfUserLoggedIn().then((data) => {
			const hasAccess = hasAccessToPage(path, data.user)
			setIsAuthorized(hasAccess)
			setIsChecking(false)
		})
	}, [path])

	if (isChecking) return <></>

	if (!isAuthorized) return <Navigate to={fallbackPath} />

	return element
}

export default ProtectedRoute
