import React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { Grid2 } from "@mui/material";

interface OptionType {
  id: string | number;
  label: string;
}

interface CommonAutocompleteProps {
  options: OptionType[];
  label: string;
  onChange: (id: any, value: OptionType | null) => void;
  renderOption?: (props: any, option: OptionType) => React.ReactNode;
}

const CommonAutocomplete = ({
  options,
  label,
  onChange,
  renderOption,
}: CommonAutocompleteProps) => {
  // Custom filter to prioritize search results and handle empty options
  const filterOptions = (options: OptionType[], { inputValue }: any) => {
    const normalizedInput = inputValue.toLowerCase();

    // Filter and remove duplicates based on the label
    const filteredOptions = options.filter((option) =>
      option.label.toLowerCase().includes(normalizedInput)
    );

    return filteredOptions;
  };

  return (
    <Grid2 width={"30%"}>
      <Autocomplete
        disablePortal
        options={options}
        filterOptions={filterOptions} // Apply custom filter logic
        getOptionLabel={(option) => option.label} // Use the label for display
        onChange={(event, value) => {
          const selectedId = value ? value.id : null; // Get the ID from the selected option
          onChange(selectedId, value);
        }}
        renderOption={(props, option) => (
          <li {...props} key={option.id || `custom-${option.label}`}>
            {option.label}
          </li>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            sx={{
              "& .MuiInputBase-root": {
                padding: "3px",
              },
              padding: "5px",
            }}
            label={label}
          />
        )}
        noOptionsText="No options" 
      />
    </Grid2>
  );
};

export default CommonAutocomplete;
