import DetailNavBar from "../../components/DetailNavBar/DetailNavBar"
import DisplayTable from "../../components/DisplayTable/DisplayTable"
import "./ActivitiesList.scss"
import { useTranslation } from "react-i18next"
import ViewDetailButton from "../../components/DisplayTable/ViewDetailButton"
import { useMemo, useState } from "react"
import TableCellText from "../../components/DisplayTable/TableCellText"
import {
	activityStatusArray,
	getActivityStatusColor,
	getTotalPages,
} from "../../utils"
import useAppPagination from "../../hooks/useAppPagination"
import AppDropdownFilter from "../../components/AppDropdownFilter/AppDropdownFilter"
import SearchInput from "../../components/AppSearchBox/AppSearchBox"
import { useDebounce } from "../../constants/constants"
import { formatDateWithMonthName } from "../../utils/dateTime"
import AppButton from "../../components/AppButton/AppButton"
import { Grid2 } from "@mui/material"
import { AddOutlined } from "@mui/icons-material"
import { useNavigate, useParams } from "react-router-dom"
import { routeUrls } from "../../routes/routeUrls"

const sampleData = [
	{
		activity_id: 1,
		activity_type: "Type 1",
		planned_start_date: "2021-09-01",
		start_date: "2021-09-01",
		planned_end_date: "2021-09-01",
		end_date: "2021-09-01",
		status: "Planned",
	},
	{
		activity_id: 2,
		activity_type: "Type 2",
		planned_start_date: "2021-09-01",
		start_date: "2021-09-01",
		planned_end_date: "2021-09-01",
		end_date: "2021-09-01",
		status: "In Progress",
	},
	{
		activity_id: 3,
		activity_type: "Type 3",
		planned_start_date: "2021-09-01",
		start_date: "2021-09-01",
		planned_end_date: "2021-09-01",
		end_date: "2021-09-01",
		status: "Completed",
	},
]

const tableHeaders = [
	{ name: "Activity ID", sortable: false },
	{ name: "Activity Type", sortable: false },
	{ name: "Planned Start Date", sortable: false },
	{ name: "Start Date", sortable: false },
	{ name: "Planned Finish Date", sortable: false },
	{ name: "Finish Date", sortable: false },
	{ name: "Status", sortable: false },
	{ name: "", sortable: false },
]

interface SubActivitiesListProps {
  subActivities: any[]; // Adjust the type according to your data structure
  session: any;
  projectId: any;
  assigneeOptions: any;
}

const SubActivitiesList = ({assigneeOptions,subActivities,session,projectId}:SubActivitiesListProps) => {
	const { t } = useTranslation("translation", { keyPrefix: "subActivity" })
	const { id: id = "" } = useParams()
	const navigate=useNavigate();
	const [statusFilter, setStatusFilter] = useState<string>()
	const [searchTerm, setSearchTerm] = useState("")

	const {
		pageRowsCount,
		currentPage,
		handlePageChange,
		handlePageCountChange,
	} = useAppPagination()

	const handleViewRowClick = (data:any) =>{
		navigate(routeUrls.subActivity.viewSubActivityDetails((data.id)), {state: {assigneeOptions:assigneeOptions, activityId: id, session:session, projectId:projectId}})
	}

	const tableColumns = useMemo(
		() => [
			"activity",
			"activity",
			(data: any) => formatDateWithMonthName(data.planned_start_date),
			(data: any) => formatDateWithMonthName(data.actual_start_date),
			(data: any) => formatDateWithMonthName(data.planned_end_date),
			(data: any) => formatDateWithMonthName(data.actual_end_date),
			(data: any) => (
				<TableCellText
					sx={{
						color: getActivityStatusColor(data.sub_activity_status),
					}}
				>
					{data.sub_activity_status}
				</TableCellText>
			),
			() => <ViewDetailButton />,
		],
		[],
	)

	const statusOptions = useMemo(() => {
		return activityStatusArray.map((s) => ({ id: s.status, label: s.status }))
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const debouncedHandleSearchChange = useDebounce(
		(value: string) => setSearchTerm(value),
		500,
	)

	const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		console.log("Search Term", event.target.value)
		debouncedHandleSearchChange(event.target.value)
	}

	const handleStatusFilterChange = (status: any) => {
		setStatusFilter(status ? status.id : status)
	}

	const navigateToAddSubActivity = () => {
		console.log("Navigate to Add Sub Activity")
		navigate(routeUrls.subActivity.addSubActivity, {state: {assigneeOptions:assigneeOptions, activityId: id,session:session,projectId:projectId}})
	}

	

	return (
		<div className="sub-activities-list-container">
			<DetailNavBar title={t("sub_activities")} showBackButton={false}
			rightView={
				<Grid2>
					<AppButton
					btnSize="large"
					startIcon={<AddOutlined />}
					onClick={navigateToAddSubActivity}
				>
					{t("add_sub_activity")}
				</AppButton>

				</Grid2>
			}
			/>
			<div className="search-and-filters-container">
				{/* <SearchInput
					value={searchTerm}
					onChange={handleSearchChange}
					placeholder={tUsrList("search_here")}
					fullWidth={false}
					height="36px"
				/>
				<AppDropdownFilter
					placeholder={tUsrList("status")}
					value={statusFilter}
					menuItems={statusOptions}
					onChange={handleStatusFilterChange}
				/> */}
			</div>
			<DisplayTable
				tableHeaders={tableHeaders}
				tableColumns={tableColumns}
				results={subActivities}
				isFetching={false}
				pageRowsCount={pageRowsCount}
				onPageCountChange={handlePageCountChange}
				totalPages={getTotalPages(sampleData.length, pageRowsCount)}
				currentPage={currentPage}
				onPageChange={handlePageChange}
				onRowClick={handleViewRowClick}
			/>
		</div>
	)
}

export default SubActivitiesList
