import React, { useCallback, useState } from "react"
import {
	Box,
	Checkbox,
	FormControlLabel,
	FormGroup,
	Grid2,
	Menu,
	MenuItem,
	Typography,
} from "@mui/material"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import SearchInput from "../../components/AppSearchBox/AppSearchBox"
import { useTranslation } from "react-i18next"
import { IAddSubActivitySectionFormProps } from "./validation"
import {useLazyGetAllSubActivitiesListQuery } from "../../api/network/projectApiService"
import {
	SubActivityDetails,
	ActivityRelationType,
	PredecessorDetail,
} from "../../api/types/Project"
import AppInput from "../../components/AppInput/AppInput"

const activityRelationMenuItems = [
	ActivityRelationType.SS,
	ActivityRelationType.SF,
	ActivityRelationType.FS,
	ActivityRelationType.FF,
]

type PredecessorListItem = Omit<
	PredecessorDetail,
	/* "id" */ "relationship_type"
> & {
	relationship_type: ActivityRelationType | ""
}

const SubActivityRelationItem = ({
	item,
	selected,
	disabled,
	onChange,
}: {
	item: PredecessorListItem
	selected: boolean
	disabled?: boolean
	onChange?: (checked: boolean, item: PredecessorListItem) => void
}) => {
	const { t } = useTranslation("translation", { keyPrefix: "activity" })

	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
	const checkedRef = React.useRef(selected)
	const open = Boolean(anchorEl)

	const openMenu = (event: React.MouseEvent<HTMLElement>) => {
		if (disabled) return

		setAnchorEl(event.currentTarget)
	}

	const closeMenu = () => {
		setAnchorEl(null)
	}

	const handleCheckboxChange = (_: any, checked: boolean) => {
		if (disabled) return

		checkedRef.current = checked
		console.log("checked", checked, checkedRef.current)
		onChange?.(checked, item)
	}

	const handleChange = (_value: ActivityRelationType) => () => {
		console.log("value", _value, checkedRef.current)
		// setRelation(_value)
		onChange?.(checkedRef.current, { ...item, relationship_type: _value })
		closeMenu()
	}

	const handleLagChange = (e: any) => {
		onChange?.(checkedRef.current, { ...item, lag_days: e?.target?.value })
	}

	return (
		<Grid2
			display={"flex"}
			justifyContent={"space-between"}
			alignItems={"center"}
		>
			{disabled ? (
				<div>
					<Typography
						sx={{
							fontWeight: "600",
							fontSize: "16px",
							lineHeight: "20px",
							letterSpacing: "-0.15px",
						}}
						variant="body1"
						color="primary"
					>
						{item.predecessor_id}
					</Typography>
					<Typography
						sx={{
							fontSize: "14px",
							lineHeight: "20px",
							letterSpacing: "-0.15px",
						}}
						variant="body2"
						color="textSecondary"
					>
						{item.predecessor_name}
					</Typography>
				</div>
			) : (
				<FormControlLabel
					control={
						<Checkbox
							disabled={disabled}
							checked={selected}
							onChange={handleCheckboxChange}
						/>
					}
					label={
						<div>
							<Typography
								sx={{
									fontWeight: "600",
									fontSize: "16px",
									lineHeight: "20px",
									letterSpacing: "-0.15px",
								}}
								variant="body1"
								color="primary"
							>
								{item.predecessor_id}
							</Typography>
							<Typography
								sx={{
									fontSize: "14px",
									lineHeight: "20px",
									letterSpacing: "-0.15px",
								}}
								variant="body2"
								color="textSecondary"
							>
								{item.predecessor_name}
							</Typography>
						</div>
					}
				/>
			)}
			<Box
				sx={{
					display: "flex",
					alignItems: "center",
					gap: "10px",
				}}
			>
				<Box
					sx={{
						display: "flex",
						alignItems: "center",
						cursor: "pointer",
					}}
					onClick={openMenu}
				>
					<Typography className="relation-text">
						{item?.relationship_type ? item?.relationship_type : t("relation")}
					</Typography>
					<ExpandMoreIcon
						style={{
							marginTop: "2px",
						}}
					/>
				</Box>
				<Menu anchorEl={anchorEl} open={open} onClose={closeMenu}>
					{activityRelationMenuItems.map((m) => (
						<MenuItem key={m} onClick={handleChange(m)}>
							{m}
						</MenuItem>
					))}
				</Menu>
				<AppInput
					sx={{ width: "100px" }}
					label={t("lag_days")}
					type="number"
					value={item?.lag_days || ""}
					onChange={handleLagChange}
					slotProps={{
						inputLabel: {
							shrink: true,
						},
						input: {
							style: {
								height: "35px",
							},
						},
					}}
				/>
			</Box>
		</Grid2>
	)
}

interface IActivityRelationProps extends IAddSubActivitySectionFormProps {
	currentActivityId?: number
}

export default function SubActivityRelation({
	values,
	errors,
	touched,
	setFieldValue,
	disabled,
	currentActivityId,
}: IActivityRelationProps) {
	console.log("values", values);
	const { t } = useTranslation("translation", { keyPrefix: "activity" })

	const [getAllSubActivitiesApiTrigger, { isFetching }] =
		useLazyGetAllSubActivitiesListQuery()

	const [predecessorsList, setPredecessorList] = useState<PredecessorListItem[]>([])

	const searchValueRef = React.useRef("")
	const checkedActivityIdsRef = React.useRef<{ [key: number]: boolean }>({})

	const handlePredecessorSearch = useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			const _search = event.target.value
			searchValueRef.current = _search
			if (_search) {
				getAllSubActivitiesApiTrigger({ search: _search, page: 1, page_size: 50 })
					.unwrap()
					.then((data) => {
						if (searchValueRef.current !== _search) return

						const { results = [] } = data || {}
						// Filter the list without the current activity if Editing
						const filteredResults = results.filter((a: SubActivityDetails) => {
							return (
								a.id !== currentActivityId &&
								!values?.dependencies?.find(
									(b) => b.predecessor_id === a.sub_activity_id
								)
							)
						})
						const _list: PredecessorListItem[] = filteredResults.map(
							(a: SubActivityDetails) => ({
								id: a.id,
								predecessor_id: a.sub_activity_id,
								predecessor_name: a.sub_activity_name,
								relationship_type: "",
								lag_days: 0,
							}),
						)
						setPredecessorList(_list)
					})
					.catch((error) => {
						console.log("error", error)
					})
			} else {
				setPredecessorList([])
			}
		},
		[currentActivityId, getAllSubActivitiesApiTrigger, values?.dependencies],
	)

	const handlePredecessorChange = (
		checked: boolean,
		item: PredecessorListItem,
	) => {
		if (disabled) return

		checkedActivityIdsRef.current[item.id] = checked
		console.log("checked predecessor", checked, item)
		if (checked) {
			const slectedItemIndex = values?.dependencies?.findIndex(
				(a) => a.id === item.id,
			)
			let updatedList = [...(values?.dependencies || [])]
			if (slectedItemIndex > -1) {
				updatedList[slectedItemIndex] = item as PredecessorDetail
			} else {
				updatedList = updatedList.concat(item as PredecessorDetail)
			}
			setFieldValue("dependencies", updatedList)
			setPredecessorList((_pre) => {
				return _pre.filter((a) => a.predecessor_id !== item.predecessor_id)
			})
		} else {
			setFieldValue(
				"dependencies",
				values?.dependencies?.filter(
					(a) => a.predecessor_id !== item.predecessor_id,
				),
			)
		}
	}

	return (
		<Box display={"flex"} justifyContent={"space-between"} gap={2}>
			<Box
				sx={{
					bgcolor: "white",
					border: "2px solid #fff",
					borderRadius: "12px",
					boxShadow: 4,
					padding: 3,
					display: "flex",
					flexDirection: "column",
					gap: "16px",
				}}
				width={"100%"}
			>
				<Grid2 className="title">{t("predecessor_details")}</Grid2>
				{touched.dependencies && errors.dependencies && (
					<Typography variant="caption" color="error">
						{typeof errors.dependencies === "string"
							? t(errors.dependencies)
							: null}
					</Typography>
				)}
				{!disabled && (
					<Grid2>
						<SearchInput
							value={""}
							onChange={handlePredecessorSearch}
							showLoader={isFetching}
						/>
					</Grid2>
				)}
				<Box
					sx={{
						maxHeight: "310px",
						overflowY: "auto",
					}}
				>
					<Grid2>
						<FormGroup>
							<Grid2 display="flex" flexDirection="column" gap={"10px"}>
								{(values?.dependencies || []).map((a) => (
									<SubActivityRelationItem
										key={a.id}
										item={a as PredecessorListItem}
										selected={true}
										onChange={handlePredecessorChange}
										disabled={disabled}
									/>
								))}
								{(predecessorsList || []).map((a) => (
									<SubActivityRelationItem
										key={a.id}
										selected={Boolean(checkedActivityIdsRef.current[a.id])}
										item={a}
										onChange={handlePredecessorChange}
										disabled={disabled}
									/>
								))}
								{(values?.dependencies || []).length === 0 &&
									(predecessorsList || []).length === 0 && (
										<Typography
											variant="body2"
											color="textSecondary"
											sx={{
												textAlign: "center",
											}}
										>
											{t("no_data")}
										</Typography>
									)}
							</Grid2>
						</FormGroup>
					</Grid2>
				</Box>
			</Box>
		</Box>
	)
}

