import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import useAppPagination from "../../hooks/useAppPagination";
import { routeUrls } from "../../routes/routeUrls";
import { formatDateWithMonthName } from "../../utils/dateTime";
import TableCellText from "../../components/DisplayTable/TableCellText";
import { getActivityStatusColor, getTotalPages } from "../../utils";
import ViewDetailButton from "../../components/DisplayTable/ViewDetailButton";
import DisplayTable from "../../components/DisplayTable/DisplayTable";

const sampleData = [
	{
		activity_id: 1,
		activity_type: "Type 1",
		planned_start_date: "2021-09-01",
		start_date: "2021-09-01",
		planned_end_date: "2021-09-01",
		end_date: "2021-09-01",
		status: "Planned",
	},
	{
		activity_id: 2,
		activity_type: "Type 2",
		planned_start_date: "2021-09-01",
		start_date: "2021-09-01",
		planned_end_date: "2021-09-01",
		end_date: "2021-09-01",
		status: "In Progress",
	},
	{
		activity_id: 3,
		activity_type: "Type 3",
		planned_start_date: "2021-09-01",
		start_date: "2021-09-01",
		planned_end_date: "2021-09-01",
		end_date: "2021-09-01",
		status: "Completed",
	},
]

const tableHeaders = [
	{ name: "SubActivity ID", sortable: false },
	{ name: "SubActivity Name", sortable: false },
	{ name: "Planned Start Date", sortable: false },
	{ name: "Start Date", sortable: false },
	{ name: "Planned Finish Date", sortable: false },
	{ name: "Finish Date", sortable: false },
	{ name: "Status", sortable: false },
	{ name: "", sortable: false },
]

export default function SubActivityListModalView(props: any) {
    const {subActivities,activitiesDetails,editable,session}=props;
	const navigate=useNavigate();
	const {
		pageRowsCount,
		currentPage,
		handlePageChange,
		handlePageCountChange,
	} = useAppPagination()

    const handleViewRowClick = (data:any) =>{
		console.log("View Row Click", data);
		if(editable){
		navigate(routeUrls.subActivity.viewSubActivityDetails((data.id)), {state: {assigneeOptions:activitiesDetails?.assignee, activityId: activitiesDetails?.id, session:session, projectId:activitiesDetails?.project,swimlane:true}})
		}
	}


	const tableColumns = useMemo(
		() => [
			"sub_activity_id",
			"sub_activity_name",
			(data: any) => formatDateWithMonthName(data.planned_start_date),
			(data: any) => formatDateWithMonthName(data.actual_start_date),
			(data: any) => formatDateWithMonthName(data.planned_end_date),
			(data: any) => formatDateWithMonthName(data.actual_end_date),
			(data: any) => (
				<TableCellText
					sx={{
						color: getActivityStatusColor(data.sub_activity_status),
					}}
				>
					{data.sub_activity_status}
				</TableCellText>
			),
			() => <ViewDetailButton />,
		],
		[],
	)

  return (
    <div className="sub-activities-list-container">
    <DisplayTable
				tableHeaders={tableHeaders}
				tableColumns={tableColumns}
				results={subActivities}
				isFetching={false}
				pageRowsCount={pageRowsCount}
				onPageCountChange={handlePageCountChange}
				totalPages={getTotalPages(sampleData.length, pageRowsCount)}
				currentPage={currentPage}
				onPageChange={handlePageChange}
				onRowClick={handleViewRowClick}
			/>
    </div>
  )
}
