import { Box, Grid2, List, Typography, useTheme } from "@mui/material"
import {
	useLazyGetSwimlanesListByActivityCodeQuery,
	useLazyGetSwimlanesListByWbsQuery,
} from "../../api/network/projectApiService"
import { useEffect, useState } from "react"
import SwimlaneItem from "./SwimlaneItem"
import AppLoader from "../../components/AppLoader/AppLoader"
import { useDispatch } from "react-redux"
import { updateSwimlaneNav } from "../../api/slices/commonSlice"
import { SwimlaneNavState } from "../../api/types/Project"

export default function SwimlaneLists(props: any) {
	const {
		projectId,
		swimlaneWbsListShowStatus,
		swimlaneActivityListShowStatus,
	} = props
	const [swimlaneActivityList, setSwimlaneActivityList] = useState<any[]>([])
	const [wbsSwimlaneList, setWbsSwimlaneList] = useState<any[]>([])
	const [selectedWbsCode, setSelectedWbsCode] = useState<string | null>(null)
	const [selectedActivityId, setSelectedActivityId] = useState<string | null>(
		null,
	)
	const dispatch = useDispatch()

	const [
		getSwimlanesByActivity,
		{ data: activityData, isFetching: isFetchingActivity },
	] = useLazyGetSwimlanesListByActivityCodeQuery()
	const [getSwimlanesByWBS, { data: wbsData, isFetching: isFetchingWBS }] =
		useLazyGetSwimlanesListByWbsQuery()

	const theme = useTheme()
	const handleSelectWbsSwimlane = (selctedData: any) => () => {
		// console.log("selctedData", selctedData);
		dispatch(
			updateSwimlaneNav({
				selected_wbs: selctedData?.id,
				navType: "wbs",
				projectId: selctedData?.projectId,
				selected_activity_code: selctedData?.selected_activity_code,
			}),
		)
	}
	useEffect(() => {
		if (activityData?.results) {
			setSwimlaneActivityList(activityData.results)
		}
	}, [activityData])

	useEffect(() => {
		if (wbsData?.results) {
			setWbsSwimlaneList(wbsData.results)
			if (!selectedWbsCode && wbsData.results.length > 0) {
				setSelectedWbsCode(wbsData.results[0]?.wbs_code)
				handleSelectWbsSwimlane(wbsData.results[0])()
			}
		}
	}, [wbsData])

	const handleSwimlaneListByWBS = () => {
		getSwimlanesByWBS({
			page: 1,
			page_size: 200,
			project: projectId,
		})
	}

	const handleSwimlaneListByActivity = () => {
		getSwimlanesByActivity({
			page: 1,
			page_size: 200,
			project: projectId,
		})
	}

	useEffect(() => {
		if (swimlaneWbsListShowStatus) {
			handleSwimlaneListByWBS()
		}
		if (swimlaneActivityListShowStatus) {
			handleSwimlaneListByActivity()
		}
	}, [swimlaneWbsListShowStatus, swimlaneActivityListShowStatus])

	const handleSelectActivitySwimlane = (selctedData: any) => () => {
		dispatch(
			updateSwimlaneNav({
				selected_wbs: selctedData?.id,
				navType: "activity",
				projectId: projectId,
				selected_activity_code: selctedData?.id,
			}),
		)
	}

	const handleActivityClick = (item: any) => {
		handleSelectActivitySwimlane(item)()
		setSelectedActivityId(item.actv_code_id)
	}

	if (isFetchingActivity || isFetchingWBS) {
		return <AppLoader open />
	}

	return (
		<Box
			sx={{
				bgcolor: theme.palette.primary.main,
				display: "flex",
				flexDirection: "column",
			}}
		>
			{swimlaneActivityListShowStatus &&
				!swimlaneWbsListShowStatus &&
				swimlaneActivityList?.map((swimlane) => (
					<Grid2
						key={swimlane?.id}
						display={"flex"}
						maxHeight={"60px"}
						justifyContent={"space-between"}
						gap={2}
						pl={1}
						// onClick={() => setSelectedActivityId(swimlane.actv_code_id)}
						onClick={() => handleActivityClick(swimlane)}
						sx={{
							backgroundColor:
								selectedActivityId === swimlane.actv_code_id
									? "#333"
									: "transparent",
							cursor: "pointer",
							padding: 1,
							borderRadius: "4px",
						}}
					>
						<Grid2 width={"100%"}>
							<List key={swimlane?.actv_code_id}>
								<Typography
									color={
										selectedActivityId === swimlane.actv_code_id
											? "#fff"
											: "#fff"
									}
								>
									{swimlane.actv_code_name}
								</Typography>
							</List>
						</Grid2>
					</Grid2>
				))}

			{swimlaneWbsListShowStatus &&
				!swimlaneActivityListShowStatus &&
				wbsSwimlaneList.map((swimlane) => (
					<SwimlaneItem
						key={swimlane?.id}
						item={swimlane}
						selectedWbsCode={selectedWbsCode}
						setSelectedWbsCode={setSelectedWbsCode}
					/>
				))}

			{swimlaneWbsListShowStatus &&
				swimlaneActivityListShowStatus &&
				wbsSwimlaneList.map((swimlane) => (
					<SwimlaneItem
						key={swimlane?.id}
						item={swimlane}
						selectedWbsCode={selectedWbsCode}
						setSelectedWbsCode={setSelectedWbsCode}
					/>
				))}
		</Box>
	)
}
