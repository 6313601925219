import { useNavigate, useParams } from "react-router-dom"
import UserProfile from "./UserProfile"
import DetailNavBar from "../../components/DetailNavBar/DetailNavBar"
import { useSelector } from "react-redux"
import { publicUserProfileSelector } from "../../api/network/userApiService"
import { routeUrls } from "../../routes/routeUrls"
import { useEffect } from "react"
import { toast } from "react-toastify"
import { useTranslation } from "react-i18next"
import { Grid2 } from "@mui/material"
import { Svgs } from "../../assets/svgs"

const PublicUserProfile = () => {
	const { id: userId = "" }: any = useParams()

	const { data, isError, error } = useSelector(
		publicUserProfileSelector(userId),
	)

	const { t } = useTranslation("translation", {
		keyPrefix: "profilePage",
	})

	const navigate = useNavigate()

	useEffect(() => {
		const _error = error as any
		if (isError && _error?.status === 404) {
			toast.error(_error?.data?.error || t("errors.not_found"))
			navigate(routeUrls.users.list)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isError, error])

	const handleBackClick = () => {
		navigate(routeUrls.users.list)
	}

	return (
		<div className="public-user-profile-container">
			<Grid2 display={'flex'} gap={1}>
			<DetailNavBar title={data?.name || ""}  onBackPress={handleBackClick} />
			{
				data?.is_active ?
				<Grid2 pt={'34px'}>
				{/* <Svgs.ActiveUserIcon/> */}
				<div style={{color:'#54AE2A',fontSize:'16px',fontWeight:700}}>Active</div>
			</Grid2>
			:
			<Grid2 pt={'34px'}>
				{/* <Svgs.InactiveUserIcon/> */}
				<div style={{color:"#e72222",fontSize:'16px',fontWeight:700}}>Inactive</div>
			</Grid2>
			}	
		
			</Grid2>
			<Grid2 display={'flex'} justifyContent={'end'} mt={-6}>

			<UserProfile isPublicUserProfile={true} userId={userId} />
			</Grid2>
		</div>
	)
}

export default PublicUserProfile
