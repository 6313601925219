import { Box, Grid2, Switch, Typography } from "@mui/material"
import "./AppSwitch.scss"
import { IAppFormSwitchProps } from "./type"
import { CustomSwitch } from "./CustomSwitch"

const AppSwitch = ({
	label,
	isRequired = false,
	highlightLabelIfDataIsValid = false,
	value,
	onChange,
	touched,
	errorMsg,
}: IAppFormSwitchProps) => {
	return (
		<div className="switch-wrapper">
			<Grid2
				container
				alignItems="center"
				sx={{
					border: 1,
					borderColor: "#E5E7EB",
					borderRadius: "8px",
				}}
				height={45}
				marginTop={3}
			>
				<Grid2 size={10} paddingLeft={2}>
					<Typography
						className={`field-label ${
							highlightLabelIfDataIsValid &&
							(touched ? (errorMsg ? "error" : value ? "secondary" : "") : "")
						}`}
					>
						{label}
						{isRequired && " *"}
					</Typography>
				</Grid2>
				<Grid2 size={2} alignItems="flex-end">
					<CustomSwitch onChange={onChange} />
				</Grid2>
			</Grid2>
		</div>
	)
}

export default AppSwitch
