import * as Yup from "yup"
import { EMAIL_REGEX } from "../../../constants/constants"

export interface IAddUserdFields {
	email: string
	name: string
	company: number
	job_title: string
	isAdmin: boolean
	message: string
	role: number
	project: number
}

export const addUserFormInitialValues: IAddUserdFields = {
	email: "",
	name: "",
	company: 0,
	job_title: "",
	isAdmin: false,
	message: "",
	role: 0,
	project: 0,
}

export const addUserValidationSchema = Yup.object().shape({
	name: Yup.string().required("errors.company_name_required"),
	email: Yup.string()
		.matches(EMAIL_REGEX, "errors.invalid_email")
		.required("errors.email_required"),
	company: Yup.string().required("errors.company_address_required"),
	message: Yup.string().required("errors.logo_required"),
	project: Yup.string().required("Project is required"),
	role: Yup.string().required("Role is required"),
	job_title: Yup.string().required("Job title is required"),
})
