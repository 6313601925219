import { Modal, Typography } from "@mui/material"
import AppFormField from "../../components/AppFormField/AppFormField"
import AppButton from "../../components/AppButton/AppButton"
import { useFormik } from "formik"
import {
	beginSessionValidationSchema,
	initialBeginSessionValue,
} from "./Validation"
import { useBeginSessionMutation } from "../../api/network/projectApiService"
import { useParams } from "react-router-dom"
import { toast } from "react-toastify"

export default function BeginSession(props: any) {
	const { open, onClose,project } = props
	const [beginSessionTrigger] = useBeginSessionMutation()

	const handleBeginSession = async (values: any) => {
		try {
            const payload={
                session_name:values.session_name,
                description:values.description,
                project:project
            }
			const response = await beginSessionTrigger(payload)
			toast.success("Session started successfully!")
			console.log(response)
			onClose()
		} catch (error) {
			console.log(error)
            toast.error("Error in starting session")
		}
	}

	const {
		values,
		errors,
		touched,
		handleChange,
		setFieldValue,
		handleBlur,
		handleSubmit,
		// resetForm,
	} = useFormik({
		initialValues: {
			...initialBeginSessionValue,
		},
		onSubmit: handleBeginSession,
		validationSchema: beginSessionValidationSchema,
	})

	return (
		<Modal open={open} onClose={onClose}>
			<form onSubmit={handleSubmit} className="add-member-modal">
				<div className="modal-heading">
					<Typography className="title">{"Begin Session"}</Typography>
					<Typography className="subtitle">
						{"Please enter session name and description"}
					</Typography>
				</div>
				<div className="modal-fields">
					<AppFormField
						label={"Session Name"}
						name="session_name"
						placeholder={"Enter session name"}
						value={values.session_name}
						onChange={handleChange}
						onBlur={handleBlur}
						touched={touched.session_name}
						errorMsg={errors.session_name}
					/>
					<AppFormField
						label={"Description"}
						name="description"
						placeholder={"Enter description"}
						value={values.description}
						onChange={handleChange}
						onBlur={handleBlur}
						touched={touched.description}
						errorMsg={errors.description}
					/>
				</div>
				<div className="modal-action-btns">
					<AppButton
						// disabled={isAddingTeamMember}
						btnSize="medium"
						type="submit"
						style={{
							fontWeight: "700",
						}}
					>
						{"Start Session"}
					</AppButton>
					<AppButton
						btnSize="medium"
						variant="text"
						color="error"
						onClick={onClose}
						style={{
							fontWeight: "700",
						}}
					>
						{"Cancel"}
					</AppButton>
				</div>
			</form>
		</Modal>
	)
}
