import * as Yup from "yup"

export interface ICreatePasswordFields {
	password: string
	confirmpassword: string
}

export const createPasswordFormInitialValues: ICreatePasswordFields = {
	password: "",
	confirmpassword: "",
}

export const createPasswordValidationSchema = Yup.object().shape({
	password: Yup.string()
		.required("errors.password_required")
		.test("complexity", "errors.invalid_password", (value) => {
			if (!value) return false // Don't validate if value is empty (handled by required)
			const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\W_]).{14,}$/
			return regex.test(value)
		}),
	confirmpassword: Yup.string()
		.required("errors.confirm_password_required")
		.oneOf([Yup.ref("password")], "errors.password_mismatch")
		.test("complexity", "errors.invalid_password", (value) => {
			if (!value) return false // Don't validate if value is empty (handled by required)
			const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\W_]).{14,}$/
			return regex.test(value)
		}),
})
