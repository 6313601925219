import { Box, Grid2, LinearProgress, Menu, MenuItem } from "@mui/material";
import DetailNavBar from "../../components/DetailNavBar/DetailNavBar";
import AppButton from "../../components/AppButton/AppButton";
import { AddOutlined, ExpandMoreOutlined } from "@mui/icons-material";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {useGetAllActivitiesQuery, useLazyGetSubActivityQuery, useUpdateSubActivityMutation } from "../../api/network/projectApiService";
import Activity from "./Activity";
import SubActivityDetails from "./SubActivityDetails";
import SubActivityDuration from "./SubActivityDuration";
import SubActivityTrade from "./SubActivityTrade";
import SubActivityRelation from "./SubActivityRelation";
import SubActivityOtherDetails from "./SubActivityOtherDetails";
import { useFormik } from "formik";
import { AddSubActivityInitialValue, AddSubActivityValidationSchema } from "./validation";
import { useEffect, useMemo, useState } from "react";
import { ActivityDetail, SubActivityDetails as SubActivityData } from "../../api/types/Project";
import { format as dateFnsFormat, set } from "date-fns"
import SubActivityRelationSuccessors from "./SubActivityRelationSuccessors";
import AppLoader from "../../components/AppLoader/AppLoader";
import { useTranslation } from "react-i18next";
import { routeUrls } from "../../routes/routeUrls";
import { toast } from "react-toastify";
import { activityStatusArray, getActivityStatusColor, getActivityStatusLabel } from "../../utils";
import { Svgs } from "../../assets/svgs";
import AppConfirmationModal from "../../components/AppConfirmationModal/AppConfirmationModal";

export default function SubActivityViewEdit() {
    const [subActivityData, setSubActivityData] = useState<SubActivityData | null>()
    const [editSubActivity, setEditSubActivity] = useState(false)
    const [assigneeName,setAssigneeName]=useState('');
    const [changedStatus, setChangedStatus] = useState('')
    const [progress, setProgress] = useState(20)
    const [isEditing, setIsEditing] = useState(false)
    const { t } = useTranslation("translation", { keyPrefix: "activity" })
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
	const activityStatusMenuOpen = Boolean(anchorEl)
    const {id:id = ""}= useParams()
    const location = useLocation()
    const navigate=useNavigate()
    const [updateSubActivityApiTrigger, { isLoading: isSavingSubActivity }] =
		useUpdateSubActivityMutation()
    const [getSubActivityByIdApiTrigger, { isFetching: isFetchingSubActivity }]=useLazyGetSubActivityQuery();
    const handleUpdateSubActivity=(_values: any)=>{
        const { dependencies,dependencies_successors,session, ...restValue } = _values; // Destructure to remove dependencies
        const subActivityPayload={
            ...restValue,
            id: id,
            sub_activity_status:'planned',
            planned_start_date: new Date(_values.planned_start_date).toISOString(),
            planned_end_date: new Date(_values.planned_end_date).toISOString(),
            actual_start_date: new Date(_values.actual_start_date).toISOString(),
            actual_end_date: new Date(_values.actual_end_date).toISOString(),
            predecessors: _values.dependencies.map((p:any) => ({
              predecessor: p.id,
              relationship_type: p.relationship_type,
              lag_days: p.lag_days,
          })),
          successors: _values.dependencies_successors.map((p:any) => ({
              successor: p.id,
              relationship_type: p.relationship_type,
              lag_days: p.lag_days,
          })),
            assignee: [...(location?.state?.assigneeOptions ?? []).map((a:any)=>(
              a?.id
          ))],
            session: location?.state?.session || 1,
        }
        updateSubActivityApiTrigger(subActivityPayload)
        .unwrap()
        .then((response)=>{
          toast.success('Sub Activity Updated Successfully');
          if(location?.state?.swimlane){
            navigate(
              routeUrls.projects.swimlane(
                location?.state?.projectId,
                location?.state?.session,
              ),
            )
          }
          else{
          navigate(routeUrls.activity.activityDetails(location?.state?.activityId),{state:{session:location?.state?.session,projectId:location?.state?.projectId}});
          }
        })
        .catch((error)=>{
            console.log('error',error)
            toast.error('Failed to update Sub Activity');
        })
    }

    useEffect(() => {
      location?.state?.assigneeOptions?.map((a:any)=>{
          setAssigneeName((prev:any)=>
          prev+`${a?.name},`
          )
      })},[]    
  )

    const {
		values,
		errors,
		touched,
		handleChange,
		setFieldValue,
		handleBlur,
		handleSubmit,
		resetForm,
        setValues
	} = useFormik({
		initialValues:{
            // id: Number(id || 0),
			// activity_id: "",
			// activity_status: "",
            ...AddSubActivityInitialValue,
        },
		onSubmit: handleUpdateSubActivity,
		validationSchema: AddSubActivityValidationSchema,
	})
const fetchSubActivity=()=>{
    getSubActivityByIdApiTrigger(id || "1")
    .unwrap()
    .then((response)=>{
        setSubActivityData(response)
        setProgress(response.progress);
       setValues({
             activity: response?.activity || location?.state?.activityId,
             planned_start_date: dateFnsFormat(new Date(response.planned_start_date), "yyyy-MM-dd"),
             planned_end_date: dateFnsFormat(new Date(response.planned_end_date), "yyyy-MM-dd"),
             actual_start_date: dateFnsFormat(new Date(response.actual_start_date), "yyyy-MM-dd"),
             actual_end_date: dateFnsFormat(new Date(response.actual_end_date), "yyyy-MM-dd"),
             actual_duration: response.actual_duration,
             planned_duration: response.planned_duration,
             trade: response.trade,
             assignee: location?.state?.assigneeOptions || [],
             notes: response.notes,
             dependencies: response?.predecessors,
             sub_activity_status: response.sub_activity_status,
             sub_activity_name: response.sub_activity_name,
             description: response.description,
             predecessors: response.predecessors,
             successors: response.successors,
             dependencies_successors: response?.successors
         })
    })
}


const handleSubActivityStatusMenuChange = (status: string) => () => {
  const { dependencies,dependencies_successors, ...restValue } = values;
  console.log("@",location?.state?.session)
  const subActivityPayload={
      ...restValue,
      id: id,
      sub_activity_status: status,
      planned_start_date: new Date(values.planned_start_date).toISOString(),
      planned_end_date: new Date(values.planned_end_date).toISOString(),
      actual_start_date: new Date(values.actual_start_date).toISOString(),
      actual_end_date: new Date(values.actual_end_date).toISOString(),
      predecessors: values.dependencies.map((p:any) => ({
        predecessor: p.id,
        relationship_type: p.relationship_type,
        lag_days: p.lag_days,
    })),
    successors: values.dependencies_successors.map((p:any) => ({
        successor: p.id,
        relationship_type: p.relationship_type,
        lag_days: p.lag_days,
    })),
      assignee: [...(location?.state?.assigneeOptions ?? []).map((a:any)=>(
        a?.id
    ))],
    session: location?.state?.session || 1,
  }
updateSubActivityApiTrigger(subActivityPayload)
.unwrap()
.then((response) => {
  toast.success("Sub Activity Status Updated Successfully")
  setEditSubActivity(true)
  fetchSubActivity()
})
.catch((error) => {
  console.log("error", error)
})
handleActivityStatusMenuClose()
}

const handleActivityStatusMenuOpen = (
  event: React.MouseEvent<HTMLButtonElement>,
) => {
  setAnchorEl(event.currentTarget)
}
const handleActivityStatusMenuClose = () => {
  setAnchorEl(null)
}

const activityStatusList = useMemo(() => {
  return activityStatusArray.filter(
    (status) => status.status_code !== values.sub_activity_status,
  )
}, [values.sub_activity_status])

useEffect(()=>{
    fetchSubActivity()
}
,[])

const handleEdit = () => {
  setIsEditing(true)
}

const cancelEdit = () => {
  setIsEditing(false)
}

const handleBack=()=>{
  if(location?.state?.swimlane){
    navigate(
			routeUrls.projects.swimlane(
				location?.state?.projectId,
				location?.state?.session,
			),
		)
  }
  else{
    navigate(routeUrls.activity.activityDetails(location?.state?.activityId),{state:{session:location?.state?.session,projectId:location?.state?.projectId}})
  }
}

const renderActivityStatusDropdown = () => {
  const _color = getActivityStatusColor(values?.sub_activity_status)
  return (
    <>
      <AppButton
        onClick={handleActivityStatusMenuOpen}
        variant="text"
        endIcon={<ExpandMoreOutlined />}
        sx={{
          color: _color,
        }}
        startIcon={<Svgs.ProjectStatusLogo color={_color} />}
      >
        {getActivityStatusLabel(values.sub_activity_status)}
      </AppButton>
      <Menu
        anchorEl={anchorEl}
        open={activityStatusMenuOpen}
        onClose={handleActivityStatusMenuClose}
      >
        {activityStatusList.map((m) => (
          <MenuItem
            key={m.status_code}
            // onClick={() => handleEditConfirmation(m.status_code)}
            onClick={handleSubActivityStatusMenuChange(m.status_code)}
          >
            {m.status}
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}

const headerButtons = useMemo(() => {
  return (
    <>
      <AppButton
        sx={{
          display: isEditing ? "block" : "none",
        }}
        btnSize="medium"
        color="error"
        variant="outlined"
        disabled={isSavingSubActivity}
        onClick={cancelEdit}
      >
        {t("cancel")}
      </AppButton>
      <AppButton
        sx={{
          display: isEditing ? "block" : "none",
          color: "white",
        }}
        btnSize="medium"
        disabled={isSavingSubActivity}
        type="submit"
        color="success"
      >
        {t("save")}
      </AppButton>
      <AppButton
        sx={{
          display: isEditing ? "none" : "block",
        }}
        btnSize="medium"
        disabled={isSavingSubActivity}
        onClick={handleEdit}
      >
        {t("edit_activity")}
      </AppButton>
    </>
  )
  // eslint-disable-next-line react-hooks/exhaustive-deps
}, [isEditing])

if (isFetchingSubActivity ) {
  return <AppLoader open />
}

  return (
    <Box className="sub-activity-form-container">
      {
        editSubActivity && 
        <AppConfirmationModal
        open={true}
        confirmationMessage={"To Update the Dates for the Main Activity, please visit the Activity Edit Page."}
        onConfirm={()=> setEditSubActivity(false)}
        onClose={()=>setEditSubActivity(false)}
        closeButtonText={"Close"}
        confirmButtonText= {'Ok'}
        />
      }
      <form onSubmit={handleSubmit}>
     <DetailNavBar
					title={isEditing? 'Sub Activity Edit': 'Sub Activity View'}
					showBackButton={true}
          onBackPress={handleBack}
          rightView={
						<Grid2 display={"flex"} gap={2}>
              {renderActivityStatusDropdown()}
							{headerButtons}
						</Grid2>
					}
				/>
       <Grid2 width={220} ml={10} mt={-5.5}>
       <Grid2 mb={'5px'}>{`Progress`}<span style={{color:'#54AE2A'}}>{`(${progress}%)`}</span> </Grid2>
												<LinearProgress
													variant="determinate"
													value={progress}
													color="success"
												/>
					</Grid2>
                <Activity
                  values={values || location?.state?.activityId} 
                  errors={errors} 
                  activities={[]}
                  touched={touched}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  setFieldValue={setFieldValue}
                  // disabled={!isEditing}
                  disabled={true}
                />
                <SubActivityDetails
                  values={values} 
                  errors={errors} 
                  activities={[]}
                  assigneeOptions={assigneeName}
                  touched={touched}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  setFieldValue={setFieldValue}
                  disabled={!isEditing}
                  
                />
                <SubActivityDuration
                 values={values} 
                 errors={errors} 
                 touched={touched}
                 activities={[]}
                 handleChange={handleChange}
                 handleBlur={handleBlur}
                 setFieldValue={setFieldValue}
                 disabled={!isEditing}
                 
                />
                <SubActivityTrade 
                 values={values} 
                 errors={errors} 
                 projectId={location?.state?.projectId}
                 touched={touched}
                 activities={[]}
                 handleChange={handleChange}
                 handleBlur={handleBlur}
                 setFieldValue={setFieldValue}
                 disabled={!isEditing}                 
                />
             <Grid2 display={'flex'} gap={2} >
                    <Grid2 width={'100%'}>
                <SubActivityRelation
                values={values || {}}
                activities={[]}
                errors={errors}
                touched={touched}
                handleChange={handleChange}
                handleBlur={handleBlur}
                setFieldValue={setFieldValue}
                currentActivityId={Number(id)}
                disabled={!isEditing}
                
                />
                </Grid2>
                <Grid2 width={'100%'}>
                <SubActivityRelationSuccessors
                values={values || {}}
                activities={[]}
                errors={errors}
                touched={touched}
                handleChange={handleChange}
                handleBlur={handleBlur}
                setFieldValue={setFieldValue}
                currentActivityId={Number(id)}
                disabled={!isEditing}
                />
                </Grid2>
                </Grid2>
                <SubActivityOtherDetails
                values={values} 
                errors={errors} 
                activities={[]}
                touched={touched}
                handleChange={handleChange}
                handleBlur={handleBlur}
                setFieldValue={setFieldValue}
                disabled={!isEditing}   
                 />
                 </form>
    </Box>
  )
}
