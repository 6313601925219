import { Box } from "@mui/material";
import { useGetSessionLogsQuery } from "../../api/network/projectApiService";
import { useNavigate, useParams } from "react-router-dom";
import DetailNavBar from "../../components/DetailNavBar/DetailNavBar";
import DisplayTable from "../../components/DisplayTable/DisplayTable";
import { useMemo, useState } from "react";
import { SessionDetails } from "../../api/types/Project";
import ViewDetailButton from "../../components/DisplayTable/ViewDetailButton";
import { formatDateWithMonthName } from "../../utils/dateTime";
import useAppPagination from "../../hooks/useAppPagination";
import { getTotalPages } from "../../utils";
import SearchInput from "../../components/AppSearchBox/AppSearchBox";
import { useDebounce } from "../../constants/constants";

const tableHeaders = [
  { name: "Session ID", sortable: false },
  { name: "Session Name", sortable: false },
  { name: "Started Date", sortable: false },
  { name: "Close Date", sortable: false },
]

export default function SessionLogs() {
  const param=useParams()
  const navigate=useNavigate()
  const [searchTerm, setSearchTerm] = useState("");
  const {
		pageRowsCount,
		currentPage,
		handlePageChange,
		handlePageCountChange,
	} = useAppPagination();
  const { data, isFetching } = useGetSessionLogsQuery({
    page: currentPage,
    page_size: pageRowsCount,
    project: param?.id,
    search: searchTerm,
  });

  const results = data?.results || [];
  const totalCount = data?.count || 0;

  const onSessionDetailsClick = (id: string) => {
	console.log("Session Details Clicked", id)
	navigate(`/session/${id}`)
	}

  const tableColumns = useMemo(
		() => [
			"session_id",
			"session_name",
			(data: SessionDetails) =>
				formatDateWithMonthName(data.created_at),
			(data: SessionDetails) =>
				data.ended_at &&
				formatDateWithMonthName(data.ended_at),
			// (data: SessionDetails) => (
			// 	<TableCellText
			// 		sx={{
			// 			color: getActivityStatusColor(data.activity_status),
			// 		}}
			// 	>
			// 		{data.activity_status}
			// 	</TableCellText>
			// ),
			(data: SessionDetails) => {
				return <ViewDetailButton onClick={() => onSessionDetailsClick(data?.id.toString())} 
         />
			},
		],
		[],
	)

  const debouncedHandleSearchChange = useDebounce(
		(value: string) => setSearchTerm(value),
		500,
	)
  
	const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		console.log("Search Term", event.target.value)
		debouncedHandleSearchChange(event.target.value)
	}

  const handleBackClick = () => {
    window.history.back();
  }

  return (
    <Box className="sub-activities-list-container">
      <DetailNavBar title="Session Logs" onBackPress={handleBackClick} />
      <div className="search-and-filters-container">
				<SearchInput
					value={searchTerm}
					onChange={handleSearchChange}
					placeholder={"search_here"}
					fullWidth={false}
					height="36px"
				/>
			</div>
      <Box >
        <DisplayTable
         isFetching={isFetching} 
         tableHeaders={tableHeaders}
         tableColumns={tableColumns} 
         results={results} 
         pageRowsCount={pageRowsCount}
				onPageCountChange={handlePageCountChange}
				totalPages={getTotalPages(totalCount, pageRowsCount)}
				currentPage={currentPage}
				onPageChange={handlePageChange}
				totalPageCount={totalCount}
        />        
      </Box>
    </Box>
  )
}
