import React, { useState } from "react"
import "./DisplayTable.scss"
import {
	Box,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Select,
	MenuItem,
	Pagination,
	Grid2,
	Typography,
	PaginationItem,
} from "@mui/material"
import { SelectChangeEvent } from "@mui/material"
import { Svgs } from "./../../assets/svgs/index"
import { useTranslation } from "react-i18next"
import AppLoader from "../AppLoader/AppLoader"

export type TableHeader = {
	name: string
	sortable?: boolean
}

interface DisplayTableProps {
	isFetching: boolean
	tableHeaders: TableHeader[]
	tableColumns: any[]
	results: any[] // Define a more specific type if possible
	onRowClick?: (id: string) => void
	pageRowsCount?: number
	onPageCountChange?: (count: number) => void
	totalPages?: number
	currentPage?: number
	totalPageCount?: number
	onPageChange?: (page: number) => void
	handleSortListData?: (sortType: boolean) => void
	// customWidth?: boolean
}

const DisplayTable: React.FC<DisplayTableProps> = ({
	isFetching,
	tableHeaders,
	tableColumns,
	results,
	onRowClick,
	handleSortListData,
	pageRowsCount,
	onPageCountChange,
	totalPages,
	currentPage,
	totalPageCount,
	onPageChange,
	// customWidth,
}) => {
	const [sortAsc, setSortAsc] = useState(true)
	const { t } = useTranslation("translation", { keyPrefix: "common.listTable" })
	const handleSortChange = () => {
		setSortAsc(!sortAsc)
		handleSortListData?.(!sortAsc)
	}

	const handlePageCountChange = (event: SelectChangeEvent<number>) => {
		onPageCountChange?.(event.target.value as number)
	}

	const handlePageChange = (_: React.ChangeEvent<unknown>, page: number) => {
		onPageChange?.(page)
	}

	const startRowCount = (pageRowsCount ?? 0) * ((currentPage ?? 1) - 1) + 1
	const totalRowCount = totalPageCount || results?.length || 0
	const endRowCount = (pageRowsCount ?? 0) * (currentPage ?? 1) || totalRowCount

	if (isFetching) {
		return (
			<div className="list-table-container">
				<AppLoader open />
			</div>
		)
	}

	return (
		<>
			<div className="list-table-container">
				{results.length > 0 ? (
					<TableContainer
						sx={{
							flex: 1,
							// maxHeight: 340,
							// overflowY: "auto",
							// width:customWidth?'100%': "90%",
							// marginBottom: "20px",
						}}
					>
						<Table
							stickyHeader
							sx={{ borderCollapse: "separate", borderSpacing: "0 6px" }}
						>
							<TableHead>
								<TableRow
									className="title"
									sx={{
										"& .MuiTableCell-root": {
											border: "none",
											padding: "8px",
											color: "#444444",
											fontWeight: "600",
											fontSize: "14px",
											backgroundColor: "#F1F5F7",
										},
									}}
								>
									{tableHeaders.map((header) => (
										<TableCell key={header.name} style={{ width: '300px', wordBreak: 'break-word', textAlign: 'center' }}>
											{header.name}
											<span
												style={{
													margin: "0 5px",
													cursor: "pointer",
													position: "absolute",
													top: "calc(50% + 2px)",
													transform: "translateY(-50%)",
												}}
												onClick={header.sortable ? handleSortChange : undefined}
											>
												{header.sortable && <Svgs.Sort />}
											</span>
										</TableCell>
									))}
								</TableRow>
							</TableHead>
							<TableBody
								sx={{
									"& .MuiTableRow-root": {
										borderRadius: "6px",
										backgroundColor: "#F1F5F7",
									},
								}}
							>
								{results.map((row) => {
									const handleRowClick = () => {
										onRowClick?.(row)
									}
									return (
										<TableRow
											key={row.id}
											sx={{
												"& .MuiTableCell-root": {
													border: "none",
													padding: "8px",
													backgroundColor: "white",
												},
											}}
											onClick={handleRowClick}
										>
											{tableColumns.map((column, index) => (
												<TableCell key={index} style={{ width: '300px', wordBreak: 'break-word', textAlign: 'center' }}>
													{typeof column === "string"
														? row[column]
														: column(row)}
												</TableCell>
											))}
										</TableRow>
									)
								})}
							</TableBody>
						</Table>
					</TableContainer>
				) : (
					<Box
						flex={1}
						display="flex"
						flexDirection="column"
						justifyContent="center"
						alignItems="center"
						minHeight="60vh"
					// marginRight={12}
					>
						<Grid2 className="title">{t("no_data")}</Grid2>
					</Box>
				)}
			</div>
			{results.length > 0 && (
				<div className="pagination-container">
					<Box display={"flex"} gap={"10px"} alignItems={"center"}>
						<Typography fontSize={"14px"} lineHeight={"24px"}>
							{`${startRowCount} - ${endRowCount > totalRowCount ? totalRowCount : endRowCount} of ${totalRowCount}`}
						</Typography>
						<Typography fontSize={"14px"} lineHeight={"24px"}>
							{t("number_of_rows")}
						</Typography>
						<Select
							style={{
								height: "36px",
								background: "white",
							}}
							value={pageRowsCount}
							onChange={handlePageCountChange}
						>
							<MenuItem value={10}>10</MenuItem>
							<MenuItem value={25}>25</MenuItem>
							<MenuItem value={50}>50</MenuItem>
						</Select>
					</Box>
					<Pagination
						page={currentPage}
						count={totalPages}
						variant="outlined"
						shape="rounded"
						onChange={handlePageChange}
						renderItem={(item) => (
							<PaginationItem
								slots={{
									previous: () => (
										<Typography
											fontSize="13px"
											fontWeight="500"
											lineHeight="16px"
										>
											{t("prev")}
										</Typography>
									),
									next: () => (
										<Typography
											fontSize="13px"
											fontWeight="500"
											lineHeight="16px"
										>
											{t("next")}
										</Typography>
									),
								}}
								{...item}
							/>
						)}
					/>
				</div>
			)}
		</>
	)
}

export default DisplayTable
