import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { API_ENDPOINTS, API_SERVER_URL } from "./endpoints"
import {
	ForgotPasswordPayload,
	ForgotPasswordResponse,
	LinkExpiredPayload,
	LinkExpiredResponse,
	LoginPayload,
	LoginResponse,
	ResendInvitationPayload,
	ResendInvitationResponse,
	ResetPasswordPayload,
	ResetPasswordResponse,
	SignupPayload,
	VerifySignupCodePayload,
} from "../types/Auth"
import { API_METHODS } from "../../constants/constants"
import { handleAuthError } from "./errorHandler"

export const AuthAPIs = createApi({
	reducerPath: "authAPI",
	baseQuery: fetchBaseQuery({
		baseUrl: API_SERVER_URL,
		prepareHeaders: async (headers) => {
			headers.set("Content-Type", "application/json")
			return headers
		},
	}),
	endpoints: (builder) => ({
		login: builder.mutation<LoginResponse, LoginPayload>({
			query: (params: LoginPayload) => {
				return {
					url: API_ENDPOINTS.LOGIN,
					method: API_METHODS.POST,
					body: params,
				}
			},
			transformErrorResponse(baseQueryReturnValue) {
				handleAuthError(baseQueryReturnValue)
				return baseQueryReturnValue
			},
			transformResponse: async (data: LoginResponse) => {
				return data
			},
		}),
		verifySignupCode: builder.mutation<any, VerifySignupCodePayload>({
			query: (params: VerifySignupCodePayload) => {
				return {
					url: API_ENDPOINTS.VERIFY_SIGNUP_CODE,
					method: API_METHODS.POST,
					body: params,
				}
			},
			transformErrorResponse(baseQueryReturnValue) {
				handleAuthError(baseQueryReturnValue)
				return baseQueryReturnValue
			},
			transformResponse: async (data: any) => {
				return data
			},
		}),
		signup: builder.mutation<any, SignupPayload>({
			query: (params: any) => {
				return {
					url: API_ENDPOINTS.SIGNUP,
					method: API_METHODS.POST,
					body: params,
				}
			},
			transformErrorResponse(baseQueryReturnValue) {
				handleAuthError(baseQueryReturnValue)
				return baseQueryReturnValue
			},
			transformResponse: async (data: any) => {
				return data
			},
		}),
		forgotPassword: builder.mutation<
			ForgotPasswordResponse,
			ForgotPasswordPayload
		>({
			query: (params: ForgotPasswordPayload) => {
				return {
					url: API_ENDPOINTS.FORGOT_PASSWORD,
					method: API_METHODS.POST,
					body: params,
				}
			},
			transformErrorResponse(baseQueryReturnValue) {
				handleAuthError(baseQueryReturnValue)
				return baseQueryReturnValue
			},
			transformResponse: async (data: ForgotPasswordResponse) => {
				return data
			},
		}),
		linkExpired: builder.mutation<LinkExpiredResponse, LinkExpiredPayload>({
			query: (params: LinkExpiredPayload) => {
				return {
					url: API_ENDPOINTS.LINK_EXPIRED,
					method: API_METHODS.POST,
					body: params,
				}
			},
			transformErrorResponse(baseQueryReturnValue) {
				handleAuthError(baseQueryReturnValue)
				return baseQueryReturnValue
			},
			transformResponse: async (data: LinkExpiredResponse) => {
				return data
			},
		}),
		resetPassword: builder.mutation<
			ResetPasswordResponse,
			ResetPasswordPayload
		>({
			query: (params: ResetPasswordPayload) => {
				return {
					url: API_ENDPOINTS.RESET_PASSWORD,
					method: API_METHODS.POST,
					body: params,
				}
			},
			transformErrorResponse(baseQueryReturnValue) {
				handleAuthError(baseQueryReturnValue)
				return baseQueryReturnValue
			},
			transformResponse: async (data: ResetPasswordResponse) => {
				return data
			},
		}),
		resendInvitation: builder.mutation<ResendInvitationResponse, ResendInvitationPayload>({
			query: (params: ResendInvitationPayload) => {
				return {
					url: API_ENDPOINTS.RESEND_INVITATION,
					method: API_METHODS.POST,
					body: params,
				}
			},
			transformErrorResponse(baseQueryReturnValue) {
				handleAuthError(baseQueryReturnValue)
				return baseQueryReturnValue
			},
			transformResponse: async (data: ResendInvitationResponse) => {
				return data
			},
		}),
	}),
})

export const {
	useLoginMutation,
	useVerifySignupCodeMutation,
	useSignupMutation,
	useForgotPasswordMutation,
	useLinkExpiredMutation,
	useResetPasswordMutation,
	useResendInvitationMutation,
} = AuthAPIs
