import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { SwimlaneNavState, UserProjectDetail } from "../types/Project"

const initialState: SwimlaneNavState = {
	projectId: null,
	selected_wbs: null,
	selected_activity_code: null,
	navType: "wbs",
}

export const CommonSlice = createSlice({
	name: "commonSliceData",
	reducerPath: "commonSliceData",
	initialState,
	reducers: {
		updateSwimlaneNav(state, action: PayloadAction<SwimlaneNavState | null>) {
			state.selected_wbs = action.payload?.selected_wbs || null
			state.selected_activity_code =
				action.payload?.selected_activity_code || null
			state.navType = action.payload?.navType || "wbs"
			return state
		},
		resetSwimlaneNav() {
			return { ...initialState }
		},
	},
})

// Export actions
export const { updateSwimlaneNav, resetSwimlaneNav } = CommonSlice.actions
