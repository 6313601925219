import React, { useEffect, useMemo, useState } from "react"
import { Typography } from "@mui/material"
import AppButton from "../../components/AppButton/AppButton"
import { useNavigate } from "react-router-dom"
import { routeUrls } from "../../routes/routeUrls"
import { useTranslation } from "react-i18next"
import { AddOutlined } from "@mui/icons-material"
import { useGetAllCompaniesQuery } from "../../api/network/companyApiService"
import DisplayTable from "../../components/DisplayTable/DisplayTable"
import useAppPagination from "../../hooks/useAppPagination"
import { getTotalPages } from "../../utils"
import ViewDetailButton from "../../components/DisplayTable/ViewDetailButton"
import AppDropdownFilter from "../../components/AppDropdownFilter/AppDropdownFilter"

interface Company {
	id: number
	name: string
	address: string
	email: string
	phone: string
}

const tableHeaders = [
	{ name: "Company ID", sortable: false },
	{ name: "Company Name", sortable: false },
	{ name: "Company Address", sortable: false },
	{ name: "Email", sortable: false },
	{ name: "Phone", sortable: false },
	{ name: "", sortable: false },
]

const Companies: React.FC = () => {
	const navigate = useNavigate()
	const { t } = useTranslation("translation", { keyPrefix: "company" })
	const { t: tAdd } = useTranslation("translation", {
		keyPrefix: "company.addCompany",
	})
	const [statusFilter, setStatusFilter] = useState<string>()

	const {
		pageRowsCount,
		currentPage,
		handlePageChange,
		handlePageCountChange,
	} = useAppPagination()

	const { data, isFetching,refetch } = useGetAllCompaniesQuery(
		{
			page_size: pageRowsCount,
			page: currentPage,
			is_active: statusFilter,
		},
		{
			refetchOnReconnect: true,
		},
	)


	const results = data?.results ?? []

	const companyStatusOptions = useMemo(() => {
		return [
			// { id: t("all"), label: t("all") },
			{ id: "true", label: t("active") },
			{ id: "false", label: t("suspended") },
			// { id: t("removed"), label: t("removed") },
		]
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const tableColumns = useMemo(
		() => [
			"company_id",
			"name",
			"address",
			"email",
			"phone",
			(data: Company) => {
				const navigateToCompanyDetails = () => {
					navigate(routeUrls.dashboardChild.company + `/${data.id}`)
				}
				return <ViewDetailButton onClick={navigateToCompanyDetails} />
			},
		],
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[],
	)

	const onAddCompanyClick = () => {
		navigate(routeUrls.dashboardChild.addCompany)
	}

	const handleCompanyStatusFilterChange = (status: any) => {
		setStatusFilter(status ? status.id : status)
	}

	useEffect(() => {
		refetch()
	}, [statusFilter, refetch])

	return (
		<div className="users-list-section">
			<div className="heading-wrapper">
				<Typography className="title">{t("company")}</Typography>
				<AppButton
					btnSize="medium"
					onClick={onAddCompanyClick}
					endIcon={<AddOutlined />}
				>
					{tAdd("add_company")}
				</AppButton>
			</div>
			<div className="search-and-filters-container">
				<div className="search-and-filters">
					<AppDropdownFilter
						placeholder={t("status")}
						value={statusFilter}
						menuItems={companyStatusOptions}
						onChange={handleCompanyStatusFilterChange}
					/>
				</div>
			</div>
			<DisplayTable
				isFetching={isFetching}
				tableHeaders={tableHeaders}
				tableColumns={tableColumns}
				results={results}
				pageRowsCount={pageRowsCount}
				onPageCountChange={handlePageCountChange}
				totalPages={getTotalPages(data?.count, data?.page_size)}
				currentPage={currentPage}
				onPageChange={handlePageChange}
				totalPageCount={data?.count}
			/>
		</div>
	)
}

export default Companies
