import * as Yup from "yup"

export interface IBeginSessionFields {
    project:number
	session_name: string
	description: string
}

export const initialBeginSessionValue: IBeginSessionFields = {
	project:0,
    session_name: "",
    description: "",
}

export const beginSessionValidationSchema = Yup.object().shape({
    session_name: Yup.string().trim().required("Session name is required"),
    description: Yup.string().trim().required("Session description is required"),
})