import { Modal, Typography } from "@mui/material"
import {
	useGetAllSubActivitiesByActivityQuery,
	useGetSessionIdQuery,
} from "../../api/network/projectApiService"
import SubActivityListModalView from "./SubActivityListModalView"
import AppLoader from "../../components/AppLoader/AppLoader"

export default function SubActivityModal(props: any) {
	const { open, onClose, editable , activityDetails } = props

	const { data: subActivities,isFetching } = useGetAllSubActivitiesByActivityQuery(
		activityDetails?.id,
	)
	const { data,isFetching:isSessionFetching } = useGetSessionIdQuery(activityDetails?.project)
	if (isFetching || isSessionFetching ) {
		return <AppLoader open />
	}
	return (
		<Modal open={open} onClose={onClose}>
			<div className="add-member-modal" style={{ width: "80%", height: "80%" }}>
				<div className="modal-heading">
					<Typography className="title" textAlign={"center"}>
						{"Sub Activities List"}
					</Typography>
				</div>
				{/* <SubActivitiesList assigneeOptions={activityDetails?.assignee} projectId={activityDetails?.project} session={data?.id} subActivities={subActivities?.results || []} /> */}
				<SubActivityListModalView
					subActivities={subActivities?.results || []}
					activitiesDetails={activityDetails}
					session={data?.id}
					editable={editable}
				/>
			</div>
		</Modal>
	)
}
