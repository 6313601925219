import { Grid2, Typography } from "@mui/material"
import { Calendar } from "../../api/types/Project"
import './AppCalendar.scss';

interface ICalendarProps {
    calendar: Calendar
}

const AppCalendar = ({ calendar }: ICalendarProps) => {
    const daysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
    return (
        <Grid2 key={calendar.calendar_id}>
            <Grid2 container spacing={1} sx={{ flexWrap: "wrap", gap: "5px" }}>
                <Typography style={{ fontSize: "14px", color: "#1B2944", fontWeight: 600, marginBottom: "4px" }}>
                    {calendar.calendar_name}
                </Typography>
            </Grid2>
            <Grid2 container spacing={1} className="calendar-data-container">
                {daysOfWeek.map((day, index) => {
                    const isDayPresent = calendar.working_days.includes(day);
                    return (
                        <div
                            className={`calendar-day-box ${isDayPresent ? 'present' : 'absent'}`}
                            key={index}>
                            {day}
                        </div>
                    );
                })}
            </Grid2>
        </Grid2>
    );
}

export default AppCalendar
