import { IconButton } from "@mui/material"
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined"

const ViewDetailButton = ({ onClick }: { onClick?: any }) => {
	return (
		<IconButton
			style={{
				width: "24px",
				height: "24px",
			}}
			color="primary"
			onClick={onClick}
		>
			<VisibilityOutlinedIcon />
		</IconButton>
	)
}

export default ViewDetailButton
