import { Box, CircularProgress, Modal, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"
import AppButton from "../../components/AppButton/AppButton"
import { useUploadXerFileMutation } from "../../api/network/xerApiService"
import AppAutocompleteFormField from "../../components/AppFormField/AppAutocompleteFormField"
import useCompaniesListOption from "../../hooks/useCompaniesListOption"
import { toast } from "react-toastify"
import {
	ImportXerFileFields,
	ImportXerFileInitialValues,
	ImportXerFileValidationSchema,
	ImportXerModalProps,
} from "./validation"
import AppFormField from "../../components/AppFormField/AppFormField"
import { useFormik } from "formik"

const ImportXerModal = ({ open, onClose }: ImportXerModalProps) => {
	const { t } = useTranslation("translation", { keyPrefix: "dashboard" })

	const [uploadFileTrigger, { isLoading }] = useUploadXerFileMutation()

	const { companiesOptions, isFetching: isCompanyFetching } =
		useCompaniesListOption()

	const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (event.target.files) {
			setFieldValue("xer_file", event.target.files[0])
		}
	}

	const handleUpload = (values: ImportXerFileFields) => {
		const { xer_file, company } = values
		const formData = new FormData()
		formData.append("xer_file", xer_file as File)
		formData.append("company", company)
		uploadFileTrigger(formData)
			.unwrap()
			.then(() => {
				toast.success("File upload initiated. You will be notified via email once import is complete.")
				closeModal()
			})
			.catch((err) => {
				console.log("Error while uploading file: ", err)
				toast.error(t("file_upload_failed"))
			})
	}

	const {
		values,
		errors,
		touched,
		handleBlur,
		handleSubmit,
		setFieldValue,
		resetForm,
	} = useFormik({
		initialValues: ImportXerFileInitialValues,
		onSubmit: handleUpload,
		validationSchema: ImportXerFileValidationSchema,
	})

	const closeModal = () => {
		resetForm()
		onClose()
	}

	return (
		<Modal open={open} onClose={closeModal}>
			<form className="import-xer-modal-container" onSubmit={handleSubmit}>
				<Typography className="title">{t("import_p6")}</Typography>
				<Box mt={3}>
					<AppAutocompleteFormField
						label={t("company")}
						isRequired
						disabled={isCompanyFetching}
						name="company"
						placeholder={t("company")}
						value={values.company}
						onChange={setFieldValue}
						onBlur={handleBlur}
						touched={touched.company}
						errorMsg={errors.company ? t(errors.company) : ""}
						options={companiesOptions}
					/>
				</Box>
				<Box mt={3}>
					<AppFormField
						label=""
						type="file"
						value={null} // value for file input should be null
						name="xer_file"
						placeholder=""
						onChange={handleFileSelect}
						touched={touched.xer_file}
						errorMsg={errors.xer_file ? t(errors.xer_file) : ""}
					/>
				</Box>
				<Box
					sx={{
						display: "flex",
						justifyContent: "flex-end",
                  alignItems: "center",
						mt: 3,
                  gap: 2,
					}}
				>
					{isLoading && <CircularProgress size={30} />}
					<AppButton btnSize="medium" type="submit" disabled={isLoading}>
						{t("upload_file")}
					</AppButton>
				</Box>
			</form>
		</Modal>
	)
}

export default ImportXerModal
