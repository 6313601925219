import { Typography } from "@mui/material"
import { IAppFormFieldProps } from "./type"
import AppInput from "../AppInput/AppInput"
import "./AppFormField.scss"

const AppFormField = ({
	label,
	isRequired = false,
	name,
	type = "text",
	highlightLabelIfDataIsValid = false,
	placeholder,
	value,
	onChange,
	onBlur,
	touched,
	errorMsg,
	disabled = false,
	inputProps = {},
}: IAppFormFieldProps) => {
	return (
		<div className="field-wrapper">
			<Typography
				className={`field-label ${
					highlightLabelIfDataIsValid &&
					(touched ? (errorMsg ? "error" : value ? "secondary" : "") : "")
				}`}
			>
				{label}
				{isRequired && " *"}
			</Typography>
			<AppInput
				name={name}
				type={type}
				placeholder={placeholder}
				value={value}
				onChange={onChange}
				onBlur={onBlur}
				error={Boolean(touched && errorMsg)}
				helperText={touched && errorMsg}
				disabled={disabled}
				{...inputProps}
			/>
		</div>
	)
}

export default AppFormField
