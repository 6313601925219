import { Box, Grid2 } from "@mui/material";
import { useTranslation } from "react-i18next";
import AppFormField from "../../components/AppFormField/AppFormField";
import { IAddSubActivitySectionFormProps } from "./validation";

export default function SubActivityDuration(
	{
		values,
		errors,
		touched,
		handleChange,
		setFieldValue,
		disabled,
		handleBlur,
	}: IAddSubActivitySectionFormProps
) {
    const { t } = useTranslation("translation", { keyPrefix: "subActivity" })
	const { t: tErrors } = useTranslation("translation", {
		keyPrefix: "subActivity.errors",
	})
  return (
    <Box
			sx={{
				bgcolor: "white",
				border: "2px solid #fff",
				borderRadius: "16px",
				boxShadow: 4,
				padding: 3,
				display: "flex",
				flexDirection: "column",
				gap: "16px",
			}}
		>
			<Grid2 className="title">{t("date_duration")}</Grid2>
			<Grid2 display={"flex"} justifyContent={"space-between"} gap={2}>
				<Grid2 width={"100%"}>
					<AppFormField
						label={t("planned_start_date")}
						isRequired
						type="date"
						name={"planned_start_date"}
						placeholder={t("planned_start_date")}
						disabled={disabled}
						value={values.planned_start_date}
						onChange={handleChange}
						onBlur={handleBlur}
						touched={touched.planned_start_date}
						errorMsg={
							errors.planned_start_date ? tErrors('planned_start_date') : ""
						}
					/>
				</Grid2>
				<Grid2 width={"100%"}>
					<AppFormField
						label={t("planned_end_date")}
						isRequired
						name={"planned_end_date"}
						type="date"
						placeholder={t("planned_end_date")}
						value={values.planned_end_date}
						disabled={disabled}
						onChange={handleChange}
						onBlur={handleBlur}
						touched={touched.planned_end_date}
						errorMsg={errors.planned_end_date ? tErrors('planned_end_date') : ""}
					/>
				</Grid2>
			</Grid2>
            <Grid2>
                <AppFormField
                    label={t("plan_duration")}
                    isRequired
                    name={"planned_duration"}
                    placeholder={t("plan_duration")}
                    value={values.planned_duration}
                    disabled={disabled}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    touched={touched.planned_duration}
                    errorMsg={
                        errors.planned_duration ? tErrors('planned_duration') : ""
                    }
                />
            </Grid2>
			<Grid2 display={"flex"} justifyContent={"space-between"} gap={2}>
				<Grid2 width={"100%"}>
					<AppFormField
						label={t("actual_start_date")}
						type="date"
                        isRequired
						name={"actual_start_date"}
						placeholder={t("actual_start_date")}
						value={values.actual_start_date}
						disabled={disabled}
						onChange={handleChange}
						onBlur={handleBlur}
						touched={touched.actual_start_date}
						errorMsg={
							errors.actual_start_date ? tErrors('actual_start_date') : ""
						}
					/>
				</Grid2>
				<Grid2 width={"100%"}>
					<AppFormField
						label={t("actual_end_date")}
						name={"actual_end_date"}
                        isRequired
						type="date"
						placeholder={t("actual_end_date")}
						value={values.actual_end_date}
						disabled={disabled}
						onChange={handleChange}
						onBlur={handleBlur}
						touched={touched.actual_end_date}
						errorMsg={errors.actual_end_date ? tErrors('actual_end_date') : ""}
					/>
				</Grid2>
			</Grid2>
			<Grid2 display={"flex"} justifyContent={"space-between"} gap={2}>
				<Grid2 width={"100%"}>
					<AppFormField
						label={t("actual_duration")}
						isRequired
						name={"actual_duration"}
						placeholder={t("actual_duration")}
						value={values.actual_duration}
						disabled={disabled}
						onChange={handleChange}
						onBlur={handleBlur}
						touched={touched.actual_duration}
						errorMsg={
							errors.actual_duration ? tErrors('actual_duration') : ""
						}
					/>
				</Grid2>
			</Grid2>
		</Box>
  )
}
