import React, { useState } from "react"
import "./AppPagination.scss"
import {
	Box,
	MenuItem,
	Pagination,
	PaginationItem,
	Select,
	Typography,
	SelectChangeEvent,
} from "@mui/material"

interface Props {
	totalPages: number
	currentPage: number
	onPageChange: (page: number) => void
	onChangesRowsPerPage: (rows: number) => void
}

const AppPagination: React.FC<Props> = ({
	totalPages,
	currentPage,
	onPageChange,
	onChangesRowsPerPage,
}) => {
	const [rowsPerPage, setRowsPerPage] = useState(10) // Initial rows per page state

	const handlePageChange = (event: React.ChangeEvent<unknown>, page: number) => {
		onPageChange(page)
	}

	const handleChangeRowsPerPage = (event: SelectChangeEvent<number>) => {
		const rows = parseInt(event.target.value as string, 10) // Ensure we get a number
		console.log("Rows per page:", rows)
		setRowsPerPage(rows) // Update local state
		onChangesRowsPerPage(rows) // Call the prop function to notify parent
	}

	return (
		<div className="pagination-container">
			<Box display={"flex"} gap={"10px"} alignItems={"center"}>
				<Typography fontSize={"14px"} lineHeight={"24px"}>
					{"Number of rows"}
				</Typography>
				<Select value={rowsPerPage} onChange={handleChangeRowsPerPage}>
					<MenuItem value={10}>10</MenuItem>
					<MenuItem value={25}>25</MenuItem>
					<MenuItem value={50}>50</MenuItem>
				</Select>
			</Box>
			<Pagination
						page={currentPage}
						count={totalPages}
						variant="outlined"
						shape="rounded"
						onChange={handlePageChange}
						renderItem={(item) => (
							<PaginationItem
								slots={{
									previous: () => (
										<Typography
											fontSize="13px"
											fontWeight="500"
											lineHeight="16px"
										>
											{"prev"}
										</Typography>
									),
									next: () => (
										<Typography
											fontSize="13px"
											fontWeight="500"
											lineHeight="16px"
										>
											{'Next'}
										</Typography>
									),
								}}
								{...item}
							/>
						)}
					/>
		</div>
	)
}

export default AppPagination
